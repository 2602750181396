<template>
  <div>
    <div class="depositTitle">Debit/Credit Card</div>
    <DepositScreen :userInfo="userInfo" depositCredit @setDepositValue="setDepositValue" />
    <div class="yellowBox">
      <i class="far fa-info-circle"></i>
      <div>All deposits into your OwnersBox Account must be from a payment source on which <span>you are the named account holder.</span></div>
    </div>
    <div class="depositMethod">
      <ErrorPanel v-if="providerError" :message="providerError" />

      <div class="infoContainer">
        <div style="display: flex; flex-direction: column;flex: 1;">
          <div class="inputText">First Name</div>
          <input v-model="firstName" placeholder="Enter your first name">
        </div>
        <div>
          <div class="inputText">Last Name</div>
          <input v-model="lastName" placeholder="Enter your last name">
        </div>
      </div>

      <div class="inputText">Card Number</div>
      <div class="inputContainer">
        <div class="cardBrandContainer">
          <i class="cardBrand" :class="cardBrandClass"></i>
        </div>
        <input v-model="cardNumber" class="cardInfo" placeholder="XXXX XXXX XXXX XXXX"
          type="tel"
          v-cleave="{
            creditCard: true,
            onCreditCardTypeChanged: setCardBrand
          }"
        >
      </div>

      <div class="infoContainer">
        <div>
          <div class="inputText">Expiry Date</div>
          <input v-model="cardExpiry" placeholder="MM/YY" v-cleave="{date: true, datePattern: ['m', 'y']}">
        </div>
        <div class="cvvInput">
          <div class="inputText">CVV</div>
          <input v-model="cardCvc" placeholder="Security Code" v-cleave="{blocks: [4]}">
        </div>
      </div>

      <div class="inputText">Zip / Postal Code </div>
      <input v-model="postalCode" placeholder="Zip / Postal Code " v-cleave="{blocks: [10]}"
        class="cardInfo"
      >
    </div>
    <ErrorPanel v-if="submitError" :message="submitError" />

    <button class="ob-btn submitBtn" :disabled="isDisabled" @click="!isDisabled && submitDeposit(false)">
      <i v-if="waitingForResponse" class="fad fa-spinner-third fa-spin"></i>{{ buttonText }}
    </button>

    <Credit3dsVerification v-if="verify3dsIsEnabled"
      :cardNumber="cardNumber" :cardExpiry="cardExpiry" :postalCode="postalCode"
      :firstName="firstName" :lastName="lastName" :depositAmount="depositValue"
      @success="verify3dsSuccess" @error="verify3dsFailed"
    />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import ObPaymentApi from '@/api/ObPaymentApi';
import ObAccountApi from '@/api/ObAccountApi';
import ErrorPanel from '@/components/panels/ErrorPanel.vue';
import DepositScreen from './DepositScreen.vue';
import Credit3dsVerification from './Credit3dsVerification.vue';

export default {
  components: {
    ErrorPanel,
    DepositScreen,
    Credit3dsVerification,
  },

  props: {
    locationInfo: {type: Object, default: null},
    userInfo: {type: Object, default: null},
  },

  data() {
    return {
      cardNumber: '',
      cardExpiry: '',
      cardCvc: '',
      cardBrand: null,
      firstName: '',
      lastName: '',
      postalCode: '',
      selectedAmount: 25.00,
      depositValue: 25,
      customDepositValue: '',
      selectedMethod: null,
      waitingForResponse: false,
      submitError: '',
      creditProvider: '',
      sessionId: '',
      depositProviders: {},
      providerError: '',
      timeoutMs: 1800000,
      depositOptions: null,
      redirectTimeout: null,
      errorMessage: '',
      verify3dsAuthId: null,
    };
  },

  created() {
    this.getDepositProviderInfo();
    this.setPageTimeout();
    this.getDepositOptions();
    EventBus.$on('DEPOSIT_SET_PAGE_TIMEOUT', this.setPageTimeout);
  },

  destroyed() {
    clearTimeout(this.redirectTimeout);
    EventBus.$off('DEPOSIT_SET_PAGE_TIMEOUT', this.setPageTimeout);
  },

  computed: {
    ...mapState(['theme']),

    buttonText() {
      if (this.waitingForResponse) {
        return 'Submitting...';
      } else if (this.depositValue < 10) {
        return 'Minimum deposit is $10';
      } else if (this.depositValue > 1000) {
        return 'Maximum deposit is $1000';
      } else if (this.userInfo && !this.userInfo.hasDeposited) {
        return `Deposit $${this.depositValue} (+${this.bonusValue} Bonus)`;
      } else {
        return `Deposit $${this.depositValue}`;
      }
    },

    bonusValue() {
      return this.depositValue > 500 ? 500 : this.depositValue;
    },

    isDisabled() {
      return this.waitingForResponse || !this.canSubmit;
    },

    cardBrandClass() {
      let icon = '';
      let brand = this.cardBrand;
      brand = brand || 'unknown';
      const cardBrandToClass = {
        'visa': 'fab fa-cc-visa',
        'mastercard': 'fab fa-cc-mastercard',
        'amex': 'fab fa-cc-amex',
        'discover': 'fab fa-cc-discover',
        'unknown': 'fa fa-credit-card',
      };
      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      } else {
        icon = cardBrandToClass.unknown;
      }

      return icon;
    },

    cardName() {
      const cardholder = this.firstName.trim() + ' ' + this.lastName.trim();
      return cardholder.trim();
    },

    isValidInfoCreditCard() {
      return (
        this.depositValue >= 10 && this.depositValue <= 1000 && this.cardNumber?.length > 12 &&
        this.cardExpiry && this.cardCvc !== '' && this.postalCode !== '' &&
        this.cardName !== ''
      );
    },

    canSubmit() {
      return this.isValidInfoCreditCard;
    },

    lastnameMismatch() {
      if (!this.userInfo || !this.userInfo.lastname) {
        return false;
      }
      const accountLastname = this.userInfo.lastname.trim().toLowerCase();
      const cardLastname = this.lastName.trim().toLowerCase();
      return accountLastname != cardLastname;
    },

    verify3dsIsEnabled() {
      return this.depositProviders[this.getProvider()]?.verify3dsEnabled;
    },
  },

  watch: {
    cardNumber() {
      this.setPageTimeout();
      this.submitError = '';
    },

    cardExpiry() {
      this.setPageTimeout();
      this.submitError = '';
    },

    cardCvc() {
      this.setPageTimeout();
      this.submitError = '';
    },

    cardName() {
      this.setPageTimeout();
      this.submitError = '';
    },

    firstName() {
      this.setPageTimeout();
      this.submitError = '';
    },

    lastName() {
      this.setPageTimeout();
      this.submitError = '';
    },

    postalCode() {
      this.setPageTimeout();
      this.submitError = '';
    },
  },

  methods: {
    verify3dsSuccess(authId) {
      this.verify3dsAuthId = authId;
      this.submitDeposit(true);
    },

    verify3dsFailed(errorMessage) {
      this.verify3dsAuthId = null;
      this.submitError = errorMessage;
      this.waitingForResponse = false;
    },

    getDepositProviderInfo() {
      ObPaymentApi.getDepositProviderInfo()
          .then((response) => {
            this.creditProvider = response.provider;
            this.depositProviders = response.depositProviders;
          })
          .catch((_error) => {
            this.providerError = 'Credit card processing is not available at this time.Please contact customer support for assistance.';
          });
    },

    getDepositOptions() {
      ObPaymentApi.getDepositOptions()
          .then((response) => {
            this.depositOptions = response.options.filter((option) => option.value !== 'custom');
          }).catch((_error) => {
            // Do nothing
          });
    },

    setCardBrand(type) {
      this.cardBrand = type;
    },

    submitDeposit(cardVerified) {
      this.waitingForResponse = true;
      const provider = this.getProvider();

      // Check if 3DS verification is required for this payment provider
      if (this.verify3dsIsEnabled && !cardVerified) {
        EventBus.$emit('3DS_VERIFY', provider);
        return;
      }

      // If 3DS is not required, or the card has already been verified, continue with deposit
      if (provider == 'fiserv') {
        this.getFiservSessionAndSubmit();
      } else if (provider == 'base') {
        this.submitBaseCommerceDeposit();
      } else {
        this.submitError = 'Deposit error, card type not supported';
        this.waitingForResponse = false;
      }
    },

    isCardTypeValidForProvider(cardMap) {
      const brand = this.cardBrand;
      return cardMap[brand];
    },

    // This method will validate if the card entered is supported for the current provider
    // If the current provider does not support the card it will return the last provider that does.
    // Currently using only 2 provider, base & fiserv, in the future if more providers are used we can make a task to prioritize the providers.
    // Add Fiserv exclusion, so that any deposit made in location that Fiserv does not support we will use base commerce as the provider
    getProvider() {
      const isFiservSupported = this.locationInfo && this.locationInfo.isFiservSupported != null ? this.locationInfo.isFiservSupported : true;
      if (!isFiservSupported) {
        return 'base';
      }

      let backupProvider = '';
      for (const provider in this.depositProviders) {
        if (this.isCardTypeValidForProvider(this.depositProviders[provider])) {
          if (provider == this.creditProvider) {
            return provider;
          }
          backupProvider = provider;
        }
      }
      return backupProvider;
    },

    // -- Fiserv Processing --
    // Fiserv is done in 3 steps; get a session, tokenize the card info, process the payment
    // Sessions are only valid for 10 minutes, initial design we checked the expiry before processing
    // Now will create the session when user hits the Submit button, so will do all 3 steps upon Submit button click
    // Tokenize card has no return data, if it is successful the backend will have required info from the session id
    getFiservSessionAndSubmit() {
      // Get a new session
      ObPaymentApi.getFiservSession()
          .then((response) => {
          // Update session variable and then submit the tokenization and payment process
            this.sessionId = response.sessionId;
            this.submitFiservTokenization();
          }).catch((error) => {
            this.submitFailure(error);
          });
    },

    submitFiservTokenization() {
      let expMonth = null;
      let expYear = null;
      const tAmount = parseFloat(this.depositValue.toFixed(2));
      const cardNum = this.cardNumber.replace(/\s+/g, '');

      if (this.cardExpiry && this.cardExpiry.split('/').length > 0) {
        expMonth = this.cardExpiry.split('/')[0].trim();
        expYear = this.cardExpiry.split('/')[1].trim();
      } else {
        return;
      }

      const params = {
        Version: '1',
        SessionId: this.sessionId,
        CardNumber: cardNum,
        Expiration: expMonth + expYear,
        CVN: this.cardCvc,
        AVS: {
          Zip: this.postalCode,
          FirstName: this.firstName,
          LastName: this.lastName,
        },
      };

      // Tracking, currently putting in timestamp in place of transaction id
      const tid = String(new Date().getTime());

      ObPaymentApi.tokenizeCard(params)
          .then((response) => {
            // Check the response ErrorCode field, a value not "00" is considered an error
            if (response.ErrorCode != '00') {
              this.submitError = 'Tokenize Card Error, please contact support';
              this.waitingForResponse = false;
            } else {
            // Successful tokenization, now call server deposit or withdrawal api
              this.performFiservDeposit();
            }
          }).catch((error) => {
            this.$Analytics.trackDeposit(tid, tAmount, '', false);
            this.submitFailure(error);
          });
    },

    performFiservDeposit() {
      const tAmount = parseFloat(this.depositValue.toFixed(2));
      const expMonth = this.cardExpiry.split('/')[0].trim();
      const expYear = this.cardExpiry.split('/')[1].trim();
      const params = {
        amount: String(this.depositValue.toFixed(2)),
        sessionId: this.sessionId,
        postalCode: this.postalCode,
        cardExpirationMonth: expMonth,
        cardExpirationYear: expYear,
      };

      // 3DS Verification
      if (this.verify3dsAuthId) {
        params.authenticationId = this.verify3dsAuthId;
      }

      // Tracking, currently putting in timestamp in place of transction id
      const tid = String(new Date().getTime());

      ObPaymentApi.fiservDeposit(params)
          .then((response) => {
            // Successful deposit, refresh balance
            let tCoupon;
            this.waitingForResponse = false;
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('DEPOSIT_SUBMIT_SUCCESS');

            let firstTimeDeposit = false;
            if (response === 'success-first-deposit') {
              tCoupon = 'FIRST_DEPOSIT';
              firstTimeDeposit = true;
            }
            EventBus.$emit('OPEN_DEPOSIT_MODAL', this.depositValue, firstTimeDeposit, null);

            if (this.lastnameMismatch) {
              this.logNameMismatch();
            }

            this.$Analytics.trackDeposit(tid, tAmount, tCoupon, true);
          }).catch((error) => {
            this.$Analytics.trackDeposit(tid, tAmount, '', false);
            this.submitFailure(error);
          });
    },

    // -- Base Commerce Processing --
    submitBaseCommerceDeposit() {
      let expMonth = null;
      let expYear = null;

      if (this.cardExpiry && this.cardExpiry.split('/').length > 0) {
        expMonth = this.cardExpiry.split('/')[0].trim();
        expYear = this.cardExpiry.split('/')[1].trim();
      } else {
        return;
      }

      if (expYear.length == 2) {
        expYear = '20' + expYear;
      }

      const params = {
        amount: String(this.depositValue.toFixed(2)),
        cardExpirationMonth: expMonth,
        cardExpirationYear: expYear,
        cardNumber: this.cardNumber,
        cvv: this.cardCvc,
        postalCode: this.postalCode,
        cardHolderName: this.cardName,
      };

      // 3DS Verification
      if (this.verify3dsAuthId) {
        params.authenticationId = this.verify3dsAuthId;
      }

      // Tracking, currently putting in timestamp in place of transction id
      const tid = String(new Date().getTime());
      const tAmount = parseFloat(this.depositValue.toFixed(2));
      let tCoupon = '';

      ObPaymentApi.deposit(params)
          .then((response) => {
            // Successful deposit, refresh balance
            this.waitingForResponse = false;
            EventBus.$emit('UPDATE_ACCOUNT_INFO');
            EventBus.$emit('DEPOSIT_SUBMIT_SUCCESS');

            let firstTimeDeposit = false;
            if (response === 'success-first-deposit') {
              tCoupon = 'FIRST_DEPOSIT';
              firstTimeDeposit = true;
            }
            EventBus.$emit('OPEN_DEPOSIT_MODAL', this.depositValue, firstTimeDeposit, null);

            if (this.lastnameMismatch) {
              this.logNameMismatch();
            }

            this.$Analytics.trackDeposit(tid, tAmount, tCoupon, true);
          }).catch((error) => {
            this.$Analytics.trackDeposit(tid, tAmount, '', false);
            this.submitFailure(error);
          });
    },

    submitFailure(error) {
      if (error && error.response && error.response.data) {
        this.submitError = error.response.data;
      } else {
        this.submitError = 'Error, please contact support';
      }
      this.waitingForResponse = false;
    },

    logNameMismatch() {
      const errorMessage = 'Deposit Warning: User deposited using first name: ' + this.firstName + ' and last name: ' + this.lastName;
      ObAccountApi.createUserErrorLog(errorMessage)
          .catch(() => {
            // No action
          });
    },

    setPageTimeout() {
      const self = this;
      clearTimeout(this.redirectTimeout);
      // Redirect in 30 minutes
      this.redirectTimeout = setTimeout(function() {
        self.redirectToPlayNow();
      }, this.timeoutMs);
    },

    redirectToPlayNow() {
      this.$router.push('/start-playing');
    },

    setDepositValue(depositValue) {
      this.depositValue = depositValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/Finance/deposit.scss';
</style>