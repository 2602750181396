<template>
  <tr class="ddContainer">
    <td colspan="99">
      <div style="width: 100%;">
        <table style="width: 100%" aria-label="Salary">
          <tr class="statRow" v-for="(row, index) in statRows" :key="index">
            <td class="statLabel">{{ row.label }}</td>
            <td></td>
            <td class="statText">{{ row.text }}</td>
          </tr>
        </table>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    player: Object,
    todaysGames: Object,
    leagueData: Object,
    gameIndex: Number,
  },

  computed: {
    statRows() {
      const scoringStats = this.$ScoringStats.getAllStats(this.leagueData.sport, true);

      const rowList = [];
      rowList.push({label: 'Salary', text: this.player.salaryPG.formatMoney()});
      if (this.player.pctTaken != null) {
        rowList.push({label: '% Owned', text: this.player.pctTaken + '%'});
      }

      // Set the rows for each stat that isn't zero
      for (const stat of scoringStats) {
        const statList = this.getStatString(stat, false);
        if (statList != null) {
          rowList.push(statList);
        }
      }

      return rowList;
    },
  },

  methods: {
    getTodayOrFspGame() {
      if (this.todaysGame()) {
        return this.todaysGame();
      }

      return this.getFspGame();
    },

    getStatString(stat, category) {
      if (this.getStat(stat.path, true) != null && this.getStat(stat.path, true) != 0) {
        // scoreNum + labelName gives "150 Passing Yards" for example
        const labelScoreNum = this.getStat(stat.path, false);
        let labelName = stat.label;
        if (labelScoreNum == 1) {
          labelName = labelName.removePluralWords();
        } else {
          labelName = stat.label;
        }

        return {label: labelScoreNum + ' ' + labelName, text: this.getStat(stat.path, true) + ' PTS'};
      }

      return null;
    },

    // Changes text to lowercase with uppercase first letter
    removeUppercase(inputText) {
      if (inputText == null) {
        return '';
      }
      return inputText.slice(0, 1).toUpperCase() + inputText.slice(1).toLowerCase();
    },

    getFspGame(isDH = false) {
      const entry = this.player;
      if (!entry || !entry.fspGames ) {
        return null;
      }

      let foundGame = null;
      for (const index in entry.fspGames) {
        if (entry.fspGames[index].timestamp != null && foundGame == null) {
          foundGame = entry.fspGames[index];
        }
      }

      return foundGame;
    },

    todaysGame() {
      const gameJSON = this.getFspGame();
      if (!gameJSON) {
        return null;
      }
      return this.todaysGames[gameJSON.id];
    },

    // Safely find a player stat and return in a printable format. Useful for failing gracefully when stats aren't found.
    getStat(statPath, isFpts = false) {
      const pathRoot = isFpts ? 'ObPoints' : 'stats';
      const game = this.getFspGame();

      if (!game || !game[pathRoot] || !statPath) {
        return null;
      }
      const playerRole = statPath.substr(0, statPath.indexOf('.'));
      const statType = statPath.substr(statPath.indexOf('.') + 1);

      if (!game[pathRoot][playerRole]) {
        return 0;
      }
      const stat = game[pathRoot][playerRole][statType];
      if (stat === null || stat === undefined) {
        return 0;
      }
      return stat;
    },
  },
};
</script>

<style lang="scss" scoped>
  .ddContainer {
    border-bottom: 1px solid var(--obcolor-background-page);
  }

  .statRow {
    font-size: 14px;
    border-bottom: 1px solid var(--obcolor-background-2);
    height: 27px;
  }
  .statRow:last-child {
    border-bottom: none;
  }

  .statLabel {
    padding: 5px 0 5px 15px;
    color: var(--obcolor-font-secondary);
  }

  .statText {
    padding: 5px 15px 5px 0;
    text-align: right;
  }
</style>