<template>
  <div class="entryContainer">
    <div v-if="isMyTeam()" class="myTeamIndicator"></div>

    <!-- Team Info -->
    <div class="teamContainer" @click="viewLineup()" :style="isSelected() ? 'background: var(--obcolor-background-2);' : ''">
      <div style="width: 78px; text-align: center;">
        <div v-if="team.rank <= 3" :style="getIconColor(team.rank)"><i class="fas fa-trophy"></i></div>
        <div v-else :style="getIconColor(team.rank)"><i class="fas fa-award"></i></div>
        <div class="teamRank">{{ team.rank.addSuffix() }}</div>
      </div>
      <div style="width: 70px; text-align: center; padding-right: 18px;">
        <UserLogo :id="team.userId" :sport="sport" :size="43"
          :showRank="true" :rankColor="team.rankColor" :rankNum="team.rankNum"
        />
      </div>
      <div style="flex: 1; text-align: left;">
        <div>{{ team.team }}</div>
        <div class="teamLabel">{{ team.name }}</div>
      </div>
      <div style="width: 130px; text-align: center;">
        <div class="teamValue" style="color: var(--obcolor-ob-blue);">{{ team.score }}</div>
        <div class="teamLabel">Score</div>
      </div>
      <div style="width: 130px; text-align: center;">
        <div class="teamValue" :class="league.realMoney ? 'moneyText' : 'blueText'">
          <img v-if="!league.realMoney" style="height: 10px;" :src="require('@/assets/icons/ownersbucks.png')"
            alt="OwnersBucks Icon"
          >
          {{ team.prize.removeCentsIfZero() }}
        </div>
        <div class="teamLabel">Winnings</div>
      </div>
    </div>

    <!-- Featured Players -->
    <div style="display: flex; text-align: center;">
      <div class="featuredPlayer" v-for="player in team.featuredPlayers" :key="player.id"
        @click="openPlayerCard(player.id)"
      >
        <div style="display: flex; align-items: center; justify-content: center;">
          <div style="width: 50px;">
            <PlayerHeadshot :size="'42'" :id="player.id" :sport="sport"
              :team="player.team" :showLogo="true"
            />
          </div>
          <div style="text-align: left; margin-left: 12px; font-size: 14px;">
            <div>{{ player.name.shortName() }}</div>
            <div style="font-size: 12px; margin-top: 2px; margin-bottom: -2px;">
              <span>{{ player.fpts }} FPTS</span>
              <span style="margin-left: 6px;">{{ player.gp }} GP</span>
            </div>
          </div>
        </div>
        <div style="font-size: 12px; margin-top: 5px; color: var(--obcolor-font-secondary);">{{ player.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot.vue';
import UserLogo from '@/components/UserLogo';
import EventBus from '@/event-bus';

export default {

  components: {
    UserLogo,
    PlayerHeadshot,
  },

  props: {
    league: Object,
    sport: String,
    team: Object,
    myUserId: String,
    teamId: String,
    oppTeamId: String,
  },

  methods: {
    getIconColor(rank) {
      switch (rank) {
        case 1: return 'color: orange;';
        case 2: return 'color: #C0C0C0;';
        case 3: return 'color: #B1560F;';
        case 4: return 'color: #C0C0C0;';
      }
      return '';
    },

    isSelected() {
      return this.team.teamId === this.teamId || this.team.teamId === this.oppTeamId;
    },

    isMyTeam() {
      return this.team.userId === this.myUserId;
    },

    openPlayerCard(playerId) {
      EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', playerId, this.sport, this.league.id, true);
    },

    viewLineup() {
      EventBus.$emit('SELECT_DAILY_LEADERBOARD_TEAM', this.team);
    },
  },

};
</script>

<style lang="scss" scoped>

.entryContainer {
  background: var(--obcolor-background-5);
  margin-top: 6px;
  position: relative;
  overflow: hidden;
}

.teamContainer {
  display: flex;
  height: 50px;
  padding: 10px;
  background: var(--obcolor-background-4);
  border-bottom: 1px solid var(--obcolor-background-page);
  align-items: center;
  cursor: pointer;
}
.teamContainer:hover {
  background: var(--obcolor-background-3);
}

.fas {
  font-size: 22px;
}
.teamRank {
  margin-top: 5px;
  margin-bottom: -5px;
  font-size: 16px;
}

.teamLabel {
  font-size: 12px;
  margin-top: 2px;
  color: var(--obcolor-font-secondary);
}
.teamValue {
  font-weight: bold;
  font-size: 16px;
}

.featuredPlayer {
  flex: 1;
  cursor: pointer;
  padding: 8px;
}
.featuredPlayer:hover {
  background: var(--obcolor-background-3);
}
.featuredPlayer:not(:last-child) {
  border-right: 1px solid var(--obcolor-background-page);
}

.myTeamIndicator {
  $size: 18px;
  height: $size;
  width: $size;
  background: var(--obcolor-sportscolour-nba);
  transform: rotate(45deg);
  position: absolute;
  top: -($size/2);
  left: -($size/2);
}

.blueText {
  color: var(--obcolor-ob-blue) !important;
}

.moneyText {
  color: var(--obcolor-green) !important;
}

</style>
