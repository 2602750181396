<template>
  <!-- ONLY USE v-if FOR MODALS, NOT v-show, otherwise the body won't scroll -->
  <div class="modal-mask" @scroll.stop="" @mouseup="closeModal"
    :style="'z-index: ' + zIndex"
  >
    <div class="modal-wrapper">
      <div class="modal-container ob-scroll" @mousedown="preventCloseModal" :style="containerStyle">
        <div v-if="hideCloseButton != true" class="closeBtn" @click="() => inputVal = false">
          <i class="fa fa-times"></i>
        </div>
        <div class="modal-header" :class="{ defaultHeader: defaultHeaderStyle == true }">
          <slot name="header"></slot>
        </div>
        <div ref="body" class="modal-body ob-scroll" :style="bodyStyle"
          @scroll="onScrollBody"
        >
          <slot name="body"></slot>
        </div>
        <div class="modal-footer" :style="footerStyle">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'Modal',

  props: {
    containerStyle: String,
    bodyStyle: String,
    footerStyle: String,
    defaultHeaderStyle: Boolean,
    zIndex: String,
    disableClickaway: Boolean,
    hideCloseButton: Boolean,
  },

  data() {
    return {
      preventingModalClose: false,
    };
  },

  directives: {
    ClickOutside,
  },

  created() {
    this.setBodyScrollClass(true);
    EventBus.$on('MODAL_SCROLL_BODY', this.scrollBodyTo);
  },

  destroyed() {
    this.setBodyScrollClass(false);
    EventBus.$off('MODAL_SCROLL_BODY', this.scrollBodyTo);
  },

  computed: {
    // Updates internally when this.value is changed
    // Emits any changes that are made on the input
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {
    closeModal(e) {
      if (this.disableClickaway) {
        return;
      }
      // If user clicked on the modal body, the close event will be prevented
      // I'm doing it this way because it lets EVERY OTHER event happen rather than using stopPropagation which doesn't play nicely with things like click-outside
      if (this.preventingModalClose || e.target.className != 'modal-wrapper') {
        this.preventingModalClose = false;
        return;
      }
      e.stopPropagation();
      this.inputVal = false;
      this.$emit('close');
    },

    preventCloseModal(e) {
      this.preventingModalClose = true;
    },

    setBodyScrollClass(isOpen) {
      if (isOpen) {
        document.body.classList.add('modal-open');
      } else {
        // Only re-add scrolling if all modals have been closed on the page
        const modalWrappers = document.getElementsByClassName('modal-wrapper');
        if (modalWrappers.length === 0) {
          document.body.classList.remove('modal-open');
        }
      }
    },

    onScrollBody(event) {
      this.$emit('scrollBody', event);
    },

    scrollBodyTo(scrollHeight, behavior) {
      const body = this.$refs.body;
      body.scrollTo({
        left: body.scrollLeft,
        top: scrollHeight - body.offsetTop,
        behavior: behavior || 'auto',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.closeBtn {
  position: absolute;
  height: 50px;
  font-size: 26px;
  top: -2px;
  right: 15px;
  z-index: 200;
  color: var(--obcolor-background-1);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.closeBtn:hover {
  color: red;
}

.closeBtn > i {
  width: 16px;
  text-align: center;
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 15px;
  padding: 10px;
  background-color: var(--obcolor-box-header);
  border-radius: 10px;
  min-height: 0;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  height: auto;
  border: 2px solid var(--obcolor-box-header);
  position: relative;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.defaultHeader {
  padding-left: 15px;
  height: 50px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.modal-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
  overflow: auto;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
</style>