<template>
  <div>
    <div class="componentTitle">Details</div>
    <div class="componentContent">
      <div class="contestSection" v-if="league.creatorName">
        <div class="overviewInfoTitle">Created by:</div>
        <div>{{ league.creatorName }}</div>
      </div>
      <div class="contestSection" style="width: 100%;">
        <div class="overviewInfoTitle">Roster:</div>
        <div class="rosterTableContainer">
          <table class="rosterTable" aria-label="League Overview">
            <tr>
              <th>Position</th>
              <th style="text-align: center;">Spots</th>
            </tr>
            <tr v-for="slot in league.draftRoster.roster" :key="slot.short">
              <td>{{ slot.name }}</td>
              <td style="text-align: center;">x{{ slot.num }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="contestSection">
        <div class="overviewInfoTitle">Type:</div>
        <div>Daily Tournament</div>
      </div>

      <!-- NFL Contest Duration -->
      <div class="contestSection" v-if="isNflTournament">
        <div class="overviewInfoTitle">Duration:</div>
        <div v-if="league.contestStart" style="display: flex">
          {{ getSalaryContestStartDate(league.contestStart) }} -
          {{ getContestStartDate(league.days[league.days.length - 1]) }}
        </div>
      </div>

      <!-- Contest Start -->
      <div v-else class="contestSection">
        <div class="overviewInfoTitle">Contest Start:</div>
        <div v-if="league.contestStart" style="display: flex">
          {{ getSalaryContestStartDate(league.contestStart) }}
          <Tooltip pos="bottom" textKey="LeagueModal_Tournament_ContestStart" />
        </div>
      </div>

      <!-- Fee and Prizes -->
      <div class="contestSection">
        <div class="overviewInfoTitle">Entry Fee:</div>
        <div>{{ (league && league.fee) ? league.fee.removeCentsIfZero() : '-' }}</div>
      </div>
      <div class="contestSection">
        <div class="overviewInfoTitle">Prize Pool:</div>
        <SalaryContestPrizePool :contest="league" />
      </div>

      <div class="contestSection">
        <div class="overviewInfoTitle">Entrants:</div>
        <div>{{ league.playersMax }}</div>
      </div>

      <!-- Salary Cap Contests -->
      <div class="contestSection">
        <div class="overviewInfoTitle">Salary Cap:</div>
        <div>${{ Intl.NumberFormat('en-US').format(league.salaryCap) }}</div>
      </div>
      <div class="contestSection">
        <div class="overviewInfoTitle">Multi Entry:</div>
        <div>{{ league.myEntries || 0 }} of {{ league.entriesPerUser }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SalaryContestPrizePool from '@/views/SalaryContest/SalaryContestPrizePool.vue';
import {mapState} from 'vuex';
import Tooltip from '@/components/tooltip/Tooltip';

export default {
  props: {
    league: Object,
  },

  components: {
    Tooltip,
    SalaryContestPrizePool,
  },

  computed: {
    ...mapState(['contestCutoffs']),

    isNflTournament() {
      return this.league.ctype === 'Tournament' && this.league.sport == 'NFL';
    },
  },

  methods: {
    getSalaryContestStartDate(startTS) {
      return this.$moment(startTS).format('ddd MMM Do, h:mm A');
    },

    getContestStartDate(startDate) {
      if (this.isToday(startDate)) {
        return 'Today';
      }
      return this.$moment(startDate, 'YYYY-MM-DD').format('ddd MMM Do');
    },

    isToday(date) {
      const sDate = this.$moment(date, 'YYYY-MM-DD').format('ddd MMM Do');
      const today = this.$moment().format('ddd MMM Do');
      return sDate === today;
    },
  },
};
</script>

<style lang="scss" scoped>
.componentTitle {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  padding: 10px 18px;
  background: var(--obcolor-background-4);
  width: 100%;
  font-weight: bold;
  box-sizing: border-box;
}

.componentContent {
  padding: 10px 15px;
  font-size: 14px;
}

.contestSection {
  display: flex;
  padding: 5px 10px;
  align-items: flex-start;
  min-width: calc(50% - 20px);
}

.overviewInfoTitle {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  min-width: 110px;
}

.rosterTableContainer {
  border: 2px solid var(--obcolor-background-2);
  margin-bottom: 3px;
}

.rosterTable {
  font-size: 12px;

  th, td {
    padding: 6px 10px;
    min-width: 70px;
  }

  th {
    font-weight: bold;
    background: var(--obcolor-background-2);
  }

  td {
    background: var(--obcolor-background-4);
  }
}
</style>