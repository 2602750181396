<template>
  <div class="twoColumns" style="height: calc(100% - 70px);">
    <!-- Leaderboard -->
    <div class="leaderboardSection">
      <div class="ob-box leaderboardBox"
        :style="leaderboardType === 'results' ? 'background: none;' : ''"
      >
        <div class="tabsContainer">
          <div class="tabItem" :style="getTabStyle('contest')" @click="leaderboardType = 'contest'">
            <div>Standings</div>
          </div>
          <div class="tabItem" :style="getTabStyle('my')" @click="leaderboardType = 'my'">
            <div>My Entries</div>
          </div>
        </div>

        <table v-if="leaderboardType !== 'results'" aria-label="Salary Leaderboard">
          <tr>
            <th style="width: 70px;">Rank</th>
            <th colspan="2" style="text-align: left; padding: 0;">
              <div v-if="leaderboardType !== 'my' && totalPages > 1" class="pageControls">
                <!-- Jump to first or last page -->
                <div>
                  <button class="ob-btn scrollBtn" :disabled="pageLoading" @click="jumpToTop()"><em class="fas fa-caret-up"></em> First</button>
                  <button class="ob-btn scrollBtn" :disabled="pageLoading" @click="jumpToBottom()"><em class="fas fa-caret-down"></em> Last</button>
                </div>

                <!-- Jump to specific team -->
                <div @mouseover="showScrollToTeam = true" @mouseout="showScrollToTeam = false">
                  <div class="ob-btn scrollBtn" style="padding: 4px; cursor: pointer;">
                    <span style="margin-right: 5px;">Scroll to Team</span>
                    <em class="fas fa-caret-down"></em>
                  </div>
                  <div v-show="showScrollToTeam" class="ob-scroll jumpToTeamDropdown">
                    <div v-for="team in myTeams" :key="'jumpToTeam_' + team.teamId" class="jumpToTeamOption"
                      @click="jumpToTeam(team.teamId)"
                    >
                      {{ getTeamName(team) }}
                    </div>
                  </div>
                </div>
              </div>
            </th>
            <th style="width: 100px;">Winning</th>
            <th style="width: 70px;">Proj</th>
            <th style="width: 90px; padding-right: 8px;">Score</th>
          </tr>
        </table>

        <!-- Contest Leaderboard : Using v-show instead of v-if to maintain scroll position -->
        <div v-show="leaderboardType === 'contest'" class="ob-scroll scrollableList" @scroll="onScroll"
          ref="leaderboardDiv"
        >
          <table v-if="leagueData" aria-label="Contest Leaderboard">
            <template v-for="(page, pageIndex) in leaderboardPages">
              <template v-for="(team, teamIndex) in page.leaderboard">
                <SalaryLeaderboardRow :key="pageIndex + '_' + team.teamId"
                  :team="team" :maxGames="maxGames" :leagueData="leagueData"
                  :sport="leagueData.sport"
                  :myUserId="userId" :leagueId="leagueData.id" :teamId="teamId"
                  :oppTeamId="oppTeamId"
                  :customNames="customNames" :salaryWarnings="salaryWarnings" :overLimits="overLimits"
                  :underLimits="underLimits"
                />
                <tr v-if="showPayoutLine(team, pageIndex, teamIndex)" :key="'payoutline_' + team.teamId">
                  <td colspan="99" class="payoutLine"></td>
                </tr>
              </template>
            </template>
          </table>
        </div>

        <!-- My Entries -->
        <div v-if="leaderboardType === 'my'" class="ob-scroll scrollableList">
          <table v-if="myTeams" aria-label="My Entries">
            <template v-for="(team, teamIndex) in myTeams">
              <SalaryLeaderboardRow :key="team.teamId"
                :team="team" :maxGames="maxGames" :leagueData="leagueData"
                :sport="leagueData.sport"
                :myUserId="userId" :leagueId="leagueData.id" :teamId="teamId"
                :oppTeamId="oppTeamId"
                :customNames="customNames" :salaryWarnings="salaryWarnings" :overLimits="overLimits"
                :underLimits="underLimits"
              />
              <tr v-if="showMyTeamsPayoutLine(team, teamIndex)" :key="'mypayoutline_' + team.teamId">
                <td colspan="99" class="payoutLine"></td>
              </tr>
            </template>
          </table>

          <!-- Create new lineup button -->
          <div v-if="canEnterLineup()" class="newLineupBtn">
            <router-link :to="'/lineup-builder/' + leagueData.id">
              <i class="fas fa-plus"></i>
              <span style="margin-left: 10px;">Create New Lineup</span>
            </router-link>
          </div>
        </div>

        <!-- Lazy Load Spinner -->
        <div v-if="pageLoading" class="pageLoading" :style="pageLoadingDirection === 'top' ? 'top: 94px;' : 'bottom: 0;'">
          <em class="fad fa-spinner-third fa-spin"></em>
        </div>

        <!-- Contest Results, only when contest is over -->
        <template v-if="leaderboardType === 'results'">
          <div class="myResults">
            <div>My Placing {{ getMyPlacings() }}</div>
            <div>Best Rank {{ getMyBestRank() }}</div>
            <div>Average Rank {{ getMyAvgRank() }}</div>
            <div>
              Winnings <img v-if="!leagueData.realMoney" style="height: 8px; margin-right: 2px;" :src="require('@/assets/icons/ownersbucks.png')"
                alt="OwnersBucks Icon"
              >
              <span>{{ getMyWinnings() }}</span>
            </div>
          </div>
          <SalaryWinnerEntry v-for="entry in contestWinners" :key="entry.teamId"
            :team="entry" :league="leagueData" :myUserId="userId"
            :sport="leagueData.sport"
            :teamId="teamId" :oppTeamId="oppTeamId"
          />
        </template>
      </div>
      <SponsorImage class="sponsorBannerBox" :sponsor="contestSponsorLeaderboard" width="715px"
        height="97px"
      />
    </div>

    <!-- Team Roster -->
    <div class="ob-box ob-scroll teamRosterContainer">
      <SalaryLivePlayers :leagueData="leagueData" :leagueRoster="leagueRoster" :myTeams="myTeams"
        :rosterCalc="rosterCalc" :myUserId="userId" :teamId="teamId"
        :dataLoaded="dataLoaded" :oppDataLoaded="oppDataLoaded"
        :oppLeagueRoster="oppLeagueRoster" :oppRosterCalc="oppRosterCalc" :oppTeamId="oppTeamId"
        :overLimits="overLimits" :underLimits="underLimits"
        :customNames="customNames"
      />
    </div>
  </div>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import SalaryLivePlayers from './SalaryLivePlayers';
import SalaryLeaderboardRow from './SalaryLeaderboardRow';
import SalaryWinnerEntry from './SalaryWinnerEntry';
import SponsorImage from '@/components/sponsors/SponsorImage';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import Vue from 'vue';

export default {

  components: {
    SalaryLivePlayers,
    SalaryLeaderboardRow,
    SalaryWinnerEntry,
    SponsorImage,
  },

  props: {
    leagueData: {type: Object, default: null},
    leagueRoster: {type: Object, default: null},
    oppLeagueRoster: {type: Object, default: null},
    customNames: {type: Object, default: null},
    salaryWarnings: {type: Object, default: null},
    overLimits: {type: Object, default: null},
    underLimits: {type: Object, default: null},
    myTeams: {type: Array, default: null},
    rosterCalc: {type: Object, default: null},
    teamId: {type: String, default: null},
    dataLoaded: {type: Boolean, default: null},
    oppRosterCalc: {type: Object, default: null},
    oppTeamId: {type: String, default: null},
    oppDataLoaded: {type: Boolean, default: null},
  },

  data() {
    return {
      leaderboardType: 'my',
      leaderboardPage: null,

      lastScrollPos: 0,
      pageLoading: true,
      pageLoadingDirection: '',
      pageLoadDistance: 1200,
      totalPages: 1,
      rowHeight: 63,
      leaderboardPages: [],

      scrollToTeamIndex: null,
      showScrollToTeam: false,
    };
  },

  computed: {
    ...mapState(['socialContent', 'userId']),

    maxGames() {
      if (!this.leagueData || !this.leagueData.gameLimits) {
        return 0;
      }
      return this.leagueData.gameLimits.total;
    },

    contestWinners() {
      if (!this.leagueData || !this.leagueData.leaderboard) {
        return [];
      }
      return this.leagueData.leaderboard.slice(0, 4);
    },

    contestSponsorLeaderboard() {
      if (!this.leagueData?.sponsorAds) {
        return null;
      }
      return this.leagueData?.sponsorAds['salarycap-live-leaderboard'];
    },
  },

  watch: {
    leaderboardType(newVal) {
      localStorage.setItem('salary-leaderboard-type', newVal);
      if (newVal == 'my' || newVal == 'results') {
        EventBus.$emit('UNSELECT_NFL_LEADERBOARD_OPPONENT');
      }
    },
  },

  created() {
    this.setupLeaderboardType();
    this.getLeaderboardPage(1);
    EventBus.$on('SC_RELOAD_LEADERBOARD_PAGES', this.refreshCurrentPages);
  },

  destroyed() {
    EventBus.$off('SC_RELOAD_LEADERBOARD_PAGES', this.refreshCurrentPages);
  },

  methods: {

    getTeamName(team) {
      let teamName = this.customNames[team.teamId];
      if (!teamName) {
        teamName = team.team;
      }
      if (team.entries > 1) {
        teamName += ' (' + team.teamNum + ')';
      }
      return teamName;
    },

    getMyPlacings() {
      let placing = 0;
      for (const entry of this.myTeams) {
        placing += entry.prizeValue > 0 ? 1 : 0;
      }
      return placing + ' / ' + this.myTeams.length;
    },

    getMyBestRank() {
      let rank = '';
      for (const entry of this.myTeams) {
        if (rank === '' || entry.rank < rank) {
          rank = entry.rank;
        }
      }
      return rank !== '' ? rank.addSuffix() : '';
    },

    getMyAvgRank() {
      let rank = 0;
      for (const entry of this.myTeams) {
        rank += entry.rank;
      }
      return Math.round(rank / this.myTeams.length).addSuffix();
    },

    getMyWinnings() {
      const dollarSign = this.leagueData.realMoney ? '$' : '';
      let winnings = 0;
      for (const entry of this.myTeams) {
        winnings += entry.prizeValue;
      }
      return dollarSign + (winnings / 100).toFixed(2).removeCentsIfZero();
    },

    getTabStyle(type) {
      if (this.leaderboardType === type) {
        return 'background: var(--obcolor-background-3); border-bottom: 1px solid var(--obcolor-background-3);';
      }
      return '';
    },

    setupLeaderboardType() {
      // Use last displayed section
      // Results tab is turned off, use default leaderboard if the cookie is set to results
      const sortCookie = localStorage.getItem('salary-leaderboard-type');
      if (sortCookie == null || sortCookie === '' || sortCookie === 'results') {
        return;
      }
      this.leaderboardType = sortCookie;
    },

    showPayoutLine(entry, pageIndex, teamIndex) {
      // We only show the payout seperation line if this entry has a payout value and then next entry does not
      if (entry.prizeValue === 0) {
        return false;
      }
      // Get the next entry from current page
      const pages = this.leaderboardPages;
      const curPage = pages[pageIndex] ? pages[pageIndex].leaderboard : null;
      let nextEntry = curPage && curPage.length > teamIndex + 1 ? curPage[teamIndex + 1] : null;

      if (nextEntry) {
        return nextEntry.prizeValue === 0;
      }

      // If theres no next entry from the current page, check the next page if it exists
      const nextPage = pages[pageIndex + 1] ? pages[pageIndex + 1].leaderboard : null;
      nextEntry = nextPage && nextPage.length > 0 ? nextPage[0] : null;
      return nextEntry && nextEntry.prizeValue === 0;
    },

    showMyTeamsPayoutLine(entry, entryIndex) {
      // We only show the payout seperation line if this entry has a payout value and then next entry does not
      // Same logic as the paged leaderboard version above, but using myTeams instead of the leaderboard
      if (entry.prizeValue === 0) {
        return false;
      }
      const nextEntry = this.myTeams && this.myTeams.length > entryIndex + 1 ? this.myTeams[entryIndex + 1] : null;
      return nextEntry && nextEntry.prizeValue === 0;
    },

    contestIsOver() {
      if (!this.leagueData) {
        return false;
      }
      return this.leagueData.state === 'COMPLETED' || this.leagueData.state === 'FINALIZED';
    },

    canEnterLineup() {
      if (!this.leagueData) {
        return false;
      }
      if (this.leagueData.state !== 'FILLING') {
        return false;
      }
      if (this.leagueData.playersCurrent >= this.leagueData.playersMax) {
        return false;
      }
      if (this.myTeams.length >= this.leagueData.entriesPerUser) {
        return false;
      }
      return !this.leagueData.contestStart < new Date().getTime();
    },

    // New Paged Leaderboard
    getLeaderboardPage(page, loadAtTop = false) {
      this.pageLoading = true;
      ObSalaryCapApi.getLeaderboardPage(this.leagueData.id, page)
          .then((response) => {
            if (!response.page) {
              return;
            }

            // Remove top or bottom page
            if (this.leaderboardPages.length >= 2) {
              if (loadAtTop) {
                this.leaderboardPages.pop();
              } else {
                this.leaderboardPages.shift();
              }
            }

            // Save current values to reset the scroll position
            const topPos = this.$refs.leaderboardDiv.scrollTop;
            const curHeight = this.$refs.leaderboardDiv.scrollHeight;

            // Add new page to top or bottom of the list (depending on scroll direction)
            if (loadAtTop) {
              this.leaderboardPages.unshift(response);
            } else {
              this.leaderboardPages.push(response);
            }

            // Adjust scroll position so transition is seamless
            this.$nextTick(() => {
              const leaderboardDiv = this.$refs.leaderboardDiv;
              const numRows = response.leaderboard.length;

              if (loadAtTop) {
                // Adding entries to the top of the list
                // Scroll down the height of the new page plus the current distance from the top
                leaderboardDiv.scrollTop = (this.rowHeight * numRows) + topPos;
              } else {
                // Adding entries at the bottom of the list
                // Inverse of the above calculation; Subtract height of the new page and current position from the height of the table
                const newHeight = leaderboardDiv.scrollHeight;
                leaderboardDiv.scrollTop = newHeight - (this.rowHeight * numRows) - (curHeight - topPos);
              }
            });

            setTimeout(() => {
              this.totalPages = response.totalPages;
              this.pageLoading = false;
            }, 100);
          })
          .catch((_error) => {
            // Leaderboard page load error
            this.pageLoading = false;
          });
    },

    getLeaderboardPages(pages) {
      this.pageLoading = true;
      ObSalaryCapApi.getLeaderboardPages(this.leagueData.id, pages)
          .then((response) => {
            if (response) {
              this.leaderboardPages = response;
              this.$nextTick(() => {
                // Scroll to bottom
                const leaderboardDiv = this.$refs.leaderboardDiv;
                leaderboardDiv.scrollTop = leaderboardDiv.scrollHeight;
              });
            }
            this.pageLoading = false;
          })
          .catch((_error) => {
            // Leaderboard page load error
            this.pageLoading = false;
          });
    },

    refreshCurrentPages() {
      for (const leaderboardPage of this.leaderboardPages) {
        this.reloadLeaderboardPage(leaderboardPage.page);
      }
    },

    reloadLeaderboardPage(page) {
      ObSalaryCapApi.getLeaderboardPage(this.leagueData.id, page)
          .then((response) => {
            const pageNum = response.page;
            for (let i = 0; i < this.leaderboardPages.length; i++) {
              if (this.leaderboardPages[i].page === pageNum) {
                Vue.set(this.leaderboardPages, i, response);
                break;
              }
            }
          })
          .catch((_error) => {
            // Leaderboard page load error
          });
    },

    getLeaderboardEntries() {
      if (this.leaderboardType === 'my') {
        return this.myTeams;
      }
      const entries = [];
      for (const page of this.leaderboardPages) {
        entries.push(...page.leaderboard);
      }
      return entries;
    },

    nextTeam() {
      if (this.scrollToTeamIndex == null || this.scrollToTeamIndex >= this.myTeams.length + 1) {
        this.scrollToTeamIndex = 0;
      } else {
        this.scrollToTeamIndex += 1;
      }
      this.jumpToTeam(this.myTeams[this.scrollToTeamIndex].teamId);
    },

    jumpToTeam(teamId) {
      if (!teamId) {
        return;
      }
      this.showScrollToTeam = false;
      this.pageLoading = true;
      ObSalaryCapApi.getLeaderboardForTeam(this.leagueData.id, teamId)
          .then((response) => {
            if (response) {
              this.leaderboardPages = response;
              this.scrollToTeamRow(teamId);
            }
            this.pageLoading = false;
          })
          .catch((_error) => {
            // Leaderboard page load error
            this.pageLoading = false;
          });
    },

    scrollToTeamRow(teamId) {
      this.$nextTick(() => {
        const tableRow = document.getElementById('leaderboardRow_' + teamId);
        if (tableRow) {
          tableRow.scrollIntoView({block: 'center'});
        }
      });
    },

    jumpToTop() {
      this.leaderboardPages = [];
      this.pageLoadingDirection = 'top';
      this.getLeaderboardPage(1);
    },

    jumpToBottom() {
      if (!this.leaderboardPages[0]) {
        return;
      }
      // When jumping to the last page we load the 2nd last pages as well in case the last page only has a few entries
      const lastPage = this.leaderboardPages[0].totalPages;
      this.leaderboardPages = [];
      let pagesToLoad = lastPage;
      if (lastPage > 1) {
        pagesToLoad += ',' + (lastPage - 1);
      }
      this.pageLoadingDirection = 'top';
      this.getLeaderboardPages(pagesToLoad);
    },

    onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
      // Show more entries once we scroll to the bottom of the table
      // Extra pixel buffer to reduce stuttering (load starts before reaching the end of the list)
      if (!this.pageLoading) {
        const scrollingDown = this.lastScrollPos < scrollTop;

        // Scrolled to bottom, load next page
        if (scrollingDown && scrollTop + clientHeight + this.pageLoadDistance >= scrollHeight) {
          const curPage = this.leaderboardPages[this.leaderboardPages.length - 1].page;
          const lastPage = this.leaderboardPages[this.leaderboardPages.length - 1].totalPages;
          if (curPage < lastPage) {
            this.pageLoadingDirection = 'bottom';
            this.getLeaderboardPage(curPage + 1, false);
          }
        }

        // Scrolled to top, load previous page
        if (!scrollingDown && scrollTop < this.pageLoadDistance) {
          const curPage = this.leaderboardPages[0].page;
          if (curPage > 1) {
            this.pageLoadingDirection = 'top';
            this.getLeaderboardPage(curPage - 1, true);
          }
        }
      }
      this.lastScrollPos = scrollTop;
    },
  },

};
</script>

<style lang="scss" scoped>

.twoColumns {
  display: flex;
}

.leaderboardSection {
  margin-right: 5px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 715px;
}

.leaderboardBox {
  min-height: 300px;
  position: relative;
  height: 100%;
}

.sponsorBannerBox {
  margin-top: 10px;
  max-width: 715px;
}

.ob-box {
   padding: 0;
   overflow: hidden;
}

.teamRosterContainer {
  margin-left: 5px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  min-width: 715px;
}

table {
  width: 100%;
  text-align: center;
}
th {
  height: 18px;
  padding: 14px 0;
  background: var(--obcolor-background-3);
  border-bottom: 1px solid var(--obcolor-background-page);
}

tr:nth-child(odd) {
  background: var(--obcolor-background-5);
}

// Top Menu
.tabsContainer {
  background: var(--obcolor-background-6);
  height: 47px;
  display: flex;
  font-size: 12px;
  text-align: center;
}
.tabItem {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--obcolor-background-page);
  border-bottom: 1px solid var(--obcolor-background-page);
  cursor: pointer;
}
.tabItem:last-child {
  border-right: none;
}
.tabItem:hover {
  background: var(--obcolor-background-3);
}

.newLineupBtn {
  height: 60px;
  border-bottom: 1px solid var(--obcolor-background-page);
  background: var(--obcolor-background-3);
  font-weight: bold;
  cursor: pointer;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newLineupBtn:hover {
  background: var(--obcolor-background-2);
}

.myResults {
  height: 46px;
  padding: 0 10px;
  text-align: center;
  background: var(--obcolor-background-3);
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.payoutLine {
  height: 1px;
  background: var(--obcolor-green);
}

.pageLoading {
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  text-align: center;
  padding: 6px;
  opacity: 0.5;
}

.scrollableList {
  overflow-y: scroll;
  position: absolute;
  top: 94px;
  left: 0;
  right: 0;
  bottom: 0;
}

.scrollBtn {
  display: inline-block;
  background: var(--obcolor-background-3);
  margin-right: 4px;
  padding: 2px 5px;
  border-radius: 0;
  cursor: pointer;
  color: var(--obcolor-font-primary);
}
.scrollBtn:hover {
  background: var(--obcolor-ob-blue);
}

.pageControls {
  display: flex;
  gap: 15px;
  align-items: center;
  padding-left: 5px;
  white-space: nowrap;
}

.jumpToTeamDropdown {
  position: absolute;
  background: var(--obcolor-background-2);
  max-height: 320px;
  z-index: 30;
  overflow-y: auto;
  border: 1px solid var(--obcolor-background-page);
  margin-left: -1px;
}
.jumpToTeamOption {
  padding: 7px 10px;
  padding-right: 25px;
  border-bottom: 1px solid var(--obcolor-background-3);
  white-space: nowrap;
  cursor: pointer;
}
.jumpToTeamOption:hover {
  background: var(--obcolor-ob-blue);
}
</style>
