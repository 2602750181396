<template>
  <tr v-if="player" :data-testid="'roster-player-row-' + player.id">
    <td class="posCol">{{ pos }}</td>
    <td style="text-align: left;">
      <div style="display: flex; align-items: center;">
        <PlayerHeadshot :id="player.id"
          size="42" :sport="contest.sport"
          :team="player.team"
          :showLogo="true"
        />
        <div class="statusContainer">
          <div v-if="!statusMap"><i class="fas fa-spinner fa-spin"></i></div>
          <PlayerStatus v-else-if="!statusMap[player.id]" :status="'OUT'" />
          <PlayerStatus v-else :status="statusMap[player.id].status" />
        </div>
        <div>
          <div class="playerName ob-link" @click.stop="openPlayerCard()">
            {{ player.name.shortName() }}
            {{ player.nameTag }}
          </div>
          <div>
            <span style="text-transform: uppercase;">{{ $TeamInfo.getDisplayAlias(contest.sport, player.team) }} </span>
            <template v-if="nextGame">
              <span :style="'color: ' + getOprkColour(nextGame.oprk)">{{ nextGameInfo }}</span>
              <span v-if="nextGame.oprk != null" :style="'color: ' + getOprkColour(nextGame.oprk)"> ({{ nextGame.oprk }})</span>
            </template>
            <template v-else>
              <span style="color: var(--obcolor-red);">No Game</span>
            </template>
          </div>
          <div style="margin-top: 5px" class="playerInfo">
            <MlbBattingOrder v-if="isDailyMLB() && !isPitcher()" :myBatterIndex="getBatterIndex()" />
          </div>
        </div>
      </div>
    </td>
    <td class="projText">
      <span v-if="$SportInfo.isDraftPositionMultiplier(pos)" class="projStrikeThrough">{{ getStat('proj').toFixed(0) || '-' }}</span>
      {{ getProj().toFixed(0) || '-' }}
    </td>

    <td class="salaryText">{{ player.salary.formatMoney() }}</td>
    <td>
      <div v-if="allowClick" data-testid="select-player-button" class="removeBtn"
        @click.stop="removePlayer()"
      >
        <i class="fal fa-minus-circle"></i>
      </div>
      <div v-else data-testid="select-player-button" class="lockIcon">
        <i class="far fa-lock"></i>
      </div>
    </td>
  </tr>
  <tr v-else class="emptySlot" @click="selectRosterFilter()">
    <td class="posCol"></td>
    <td colspan="3" class="emptyPos">
      <div>
        <span>{{ posLabel }}</span>
        <span v-if="flexLabel" class="flexPositions">{{ flexLabel }}</span>
      </div>
      <span v-if="$SportInfo.isDraftPositionMultiplier(pos)" class="ptf-multiplier">1.5x PTS</span>
    </td>
    <td>
      <div v-if="allowClick" data-testid="select-player-button" class="addBtn">
        <i class="fal fa-plus-circle"></i>
      </div>
    </td>
  </tr>
</template>

<script>
import PlayerHeadshot from '@/components/PlayerHeadshot';
import PlayerStatus from '@/components/PlayerStatus';
import EventBus from '@/event-bus';
import MlbBattingOrder from '@/components/charts/MlbBattingOrder.vue';
import SalaryCapGameUtils from '@/utils/views/SalaryCapGameUtils';

export default {

  components: {
    PlayerHeadshot,
    PlayerStatus,
    MlbBattingOrder,
  },

  props: {
    player: {type: Object, default: null},
    contest: {type: Object, default: null},
    pos: {type: String, default: null},
    posLabel: {type: String, default: ''},
    flexLabel: {type: String, default: null},
    statusMap: {type: Object, default: null},
    allowClick: {type: Boolean, default: true},
    editingTeam: {type: String, default: null},
    isGameSlateLineup: {type: Boolean, default: false},
  },

  computed: {
    nextGameInfo() {
      if (!this.player.games) {
        return '';
      }
      if (this.nextGame) {
        const isHome = this.nextGame.game.indexOf('@') == -1;
        return (isHome ? 'vs ' : '@ ') + this.getDisplayAlias(this.nextGame.game);
      }
      return '';
    },

    nextGame() {
      return SalaryCapGameUtils.playerNextGame(this.player);
    },
  },

  methods: {
    getDisplayAlias(alias) {
      alias = alias.replace(/@/, '');
      return this.$TeamInfo.getDisplayAlias(this.contest.sport, alias);
    },

    removePlayer() {
      if (!this.allowClick) {
        return;
      }
      EventBus.$emit('SALARY_CAP_REMOVE_PLAYER', this.player);
    },

    getStat(path) {
      const player = this.player;
      if (player && player.wStats && player.wStats[0]) {
        return player.wStats[0][path];
      }
      return null;
    },

    getProj() {
      const projection = this.getStat('proj');
      if (projection && this.$SportInfo.isDraftPositionMultiplier(this.pos)) {
        return projection * 1.5;
      }
      return projection;
    },

    selectRosterFilter() {
      if (!this.allowClick) {
        return;
      }
      EventBus.$emit('SALARY_CAP_SELECT_POS_FILTER', this.pos);
    },

    getOprkColour(oprk) {
      if (oprk <= 10) {
        return 'var(--obcolor-red)';
      } else if (oprk <= 20) {
        return 'var(--obcolor-yellow)';
      }
      return 'var(--obcolor-green)';
    },

    openPlayerCard() {
      if (this.isGameSlateLineup) {
        // Use the generic player card when creating a lineup for a slate (not tied to a contest)
        EventBus.$emit('OPEN_PLAYER_CARD', this.player.id, this.contest.sport);
      } else {
        EventBus.$emit('OPEN_PLAYER_CARD_CONTEST', this.player.id, this.contest.sport, this.contest.id, true);
      }
    },

    getBatterIndex() {
      if (!this.nextGame || !this.nextGame.info) {
        return null;
      }

      return this.nextGame.info.lineup;
    },

    isPitcher() {
      return this.player && this.player.draftPos == 'P';
    },

    isDailyMLB() {
      return this.contest && this.contest.format == 'DAILY' && this.contest.sport == 'MLB';
    },

  },

};
</script>

<style lang="scss" scoped>

td {
  height: 65px;
  border-bottom: 1px solid var(--obcolor-background-2);
  text-align: center;
}
tr:last-child {
  td {
    border: none;
  }
}

.posCol {
  text-align: center;
  font-size: 14px;
  min-width: 40px;
}

.emptySlot {
  cursor: pointer;
}
.emptySlot:hover {
  .addBtn {
    color: var(--obcolor-text);
  }
}

.emptyPos {
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
}
.flexPositions {
  color: var(--obcolor-text-secondary);
  margin-left: 7px;
}

.statusContainer {
  margin-left: 6px;
  width: 15px;
  padding-bottom: 14px;
}

.playerName {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
  margin-top: 4px;
}

.projText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
  font-size: 14px;
}
.projStrikeThrough {
  color: var(--obcolor-font-secondary);
  text-decoration: line-through;
}
.salaryText {
  color: var(--obcolor-green);
  font-weight: bold;
  font-size: 14px;
}

.removeBtn {
  color: var(--obcolor-red);
  font-size: 20px;
  padding: 3px;
  cursor: pointer;
  height: 22px;
}
.removeBtn:hover {
  color: var(--obcolor-text);
}

.lockIcon {
  color: var(--obcolor-font-secondary);
  font-size: 18px;
  padding: 3px;
  margin-top: -3px;
  height: 20px;
}

.addBtn {
  color: var(--obcolor-green);
  font-size: 20px;
  padding: 3px;
  cursor: pointer;
  height: 22px;
}
.addBtn:hover {
  color: var(--obcolor-text);
}

.ptf-multiplier {
  color: var(--obcolor-ob-blue);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: none;
}

</style>