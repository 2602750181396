<template>
  <div v-if="contest" class="iconContainer">
    <img v-if="contest.lobbyRank" :src="require('@/assets/contesticons/Featured.svg')" alt="Featured contest"
      v-tooltip.bottom="tooltipFeatured"
    >
    <img v-if="isPrimeTimeFlex" :src="require('@/assets/contesticons/Primetime.svg')" alt="Primetime Flex contest"
      v-tooltip.bottom="tooltipPrimetimeFlex"
    >
    <img v-if="contest.isGuaranteed && !hideGuaranteed" :src="require('@/assets/contesticons/Guaranteed.svg')" alt="Guaranteed contest"
      v-tooltip.bottom="tooltipGuaranteed"
    >
    <img v-if="contest.entriesPerUser > 1" :src="require('@/assets/contesticons/Multi-Entry.svg')" alt="Multi-Entry contest"
      v-tooltip.bottom="tooltipMulti"
    >
    <img v-if="contest.rookieLeague" :src="require('@/assets/contesticons/Rookie.svg')" alt="Rookie contest"
      v-tooltip.bottom="tooltipRookie"
    >
  </div>
</template>

<script>
export default {

  props: {
    contest: {type: Object, default: null},
    hideGuaranteed: {type: Boolean, default: false},
  },

  data() {
    return {
      tooltipPrimetimeFlex: {content: 'Prime Time Flex Contest', class: 'ObTooltip'},
      tooltipFeatured: {content: 'Featured Contest', class: 'ObTooltip'},
      tooltipGuaranteed: {content: 'Guaranteed Prize Pool', class: 'ObTooltip'},
      tooltipMulti: {content: 'Multi Entry', class: 'ObTooltip'},
      tooltipRookie: {content: 'Rookie Contest', class: 'ObTooltip'},
    };
  },

  computed: {
    isPrimeTimeFlex() {
      return this.contest.rosterFormat === 'Prime Time Flex';
    },
  },
};
</script>

<style lang="scss" scoped>
.iconContainer {
  display: flex;
  gap: 2px;
}

img {
  width: 20px;
  height: 20px;
}
</style>