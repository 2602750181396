<template>
  <div class="ob-box">
    <SponsorImage class="badgeContainer" v-if="contestSponsorHeader" :sponsor="contestSponsorHeader"
      height="90px"
    />
    <div v-else class="badgeContainer">
      <img :src="require('@/assets/contestbadge/' + contest.sport + '.png')" :alt="contest.sport">
    </div>
    <div style="flex: 1; display: flex; flex-direction: column;">
      <div class="contestHeaderContainer">
        <!-- Contest Name and Icons -->
        <div class="contestName">
          <div>{{ contestHeader }}</div>
          <div><ContestIcons :contest="contest" /></div>
          <i v-if="!isMultiCreate" class="far fa-info-circle infoIcon" @click="openContestModal()"></i>
        </div>
        <div v-if="isDailyContest && showLive" class="lineupEditorLink" @click.stop="editLineups">CSV Lineup Editor</div>
        <div v-else-if="!editingTeam && promoInfo.state == 'VALID' && !isMultiCreate" class="promoCodeApplied">
          <span @click="openPromoCodeModal">{{ contest.fee.removeCentsIfZero() }} Ticket Applied</span>
          <i class="fas fa-times" @click="removePromo"></i>
        </div>
        <div v-else-if="!editingTeam && promoInfo.state == 'NO_CODE' && !isMultiCreate" class="promoCodeNone" @click="openPromoCodeModal">
          <span>Add Ticket</span>
        </div>
        <button v-if="contest.state === 'FILLING'" @click="openInviteModal()" class="ob-btn shareContestLink">
          <i class="fas fa-share-square"></i>
          <div>Share Contest</div>
        </button>
      </div>

      <!-- Contest Info -->
      <div class="infoContainer">
        <div v-if="isMultiCreate">
          <div>Custom {{ contest.userCreatedContestTypeDisplayName }}</div>
          <div class="infoLabel">Contest</div>
        </div>
        <div v-if="showLeaderboardData">
          <div v-if="contest.state === 'SCHEDULED' || contest.state === 'INITIALIZING'">{{ getContestDateFormat() }}</div>
          <div v-else>{{ getContestDuration() }}</div>
          <div class="infoLabel" v-if="contest.days.length > 1">Duration</div>
          <div class="infoLabel" v-else>Date</div>
        </div>
        <div v-if="showLeaderboardData">
          <div>{{ String(contest.playersCurrent).commaSeparatedFormat() }}</div>
          <div class="infoLabel">Entrants</div>
        </div>
        <div>
          <div>
            <img v-if="!contest.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
              alt="OwnersBucks Icon"
            >
            {{ entryFee }}
          </div>
          <div class="infoLabel">{{ entryFeeTitle }}</div>
        </div>

        <div v-if="isMultiCreate">
          <div>{{ numCreateContests }}</div>
          <div class="infoLabel">Contests</div>
        </div>

        <!-- Prizes (contest view) -->
        <div v-if="showLeaderboardData">
          <img v-if="!contest.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
            alt="OwnersBucks Icon"
          >
          <SalaryContestPrizePool :contest="contest" />
          <div class="infoLabel">Prizes</div>
        </div>

        <!-- My number of entries (lineup builder) or Placing (contest view) -->
        <div v-if="!showLeaderboardData && !isMultiCreate">
          <div>{{ contest.myEntries }} / {{ contest.entriesPerUser }}</div>
          <div class="infoLabel">Entered</div>
        </div>
        <div v-else-if="!isMultiCreate">
          <div>{{ numPlacing }} / {{ contest.myEntries }}</div>
          <div class="infoLabel">Placing</div>
        </div>

        <!-- Number of Entrants -->
        <div v-if="!showLeaderboardData && !isMultiCreate">
          <div style="text-align: center; padding-right: 6px;">{{ contest.playersCurrent }} of {{ contest.playersMax }}</div>
          <div class="infoLabel">
            <ContestFillGraph :playersCurrent="contest.playersCurrent"
              :playersMax="contest.playersMax" :format="'Tournament'" :playerJoined="playerJoined"
              textPosition="none" style="min-width: 120px; margin-top: -2px;"
            />
          </div>
        </div>

        <!-- Prizes -->
        <div v-if="!showLeaderboardData" style="text-align: right; font-size: 16px;">
          <div :class="contest.realMoney ? 'moneyText' : 'blueText'">
            <img v-if="!contest.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
              alt="OwnersBucks Icon"
            >
            <SalaryContestPrizePool :contest="contest" />
          </div>
          <div class="infoLabel">Prizes</div>
        </div>

        <!-- My entries bar -->
        <div v-if="showLeaderboardData">
          <div style="margin-top: 30px; height: 20px;">
            <div class="payoutBar" style="position: relative;">
              <div class="payoutZone" :style="getPayoutZoneWidth()"></div>
            </div>
            <div class="myEntries">
              <div v-for="entry in myTeams" :key="entry.teamId" class="entryCircle"
                :style="getRankPct(entry)" @click="changeTeam(entry.teamId)"
              >
                <div class="entryGraph">
                  <PieChart size="18" :slices="getPieSlices(entry)" />
                </div>
                <div class="entryTriangle"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Countdown to start time -->
        <div style="text-align: right;" v-if="!showLeaderboardData">
          <div class="contestCountDown">{{ contestCountdownVal }}</div>
          <div class="infoLabel">{{ getContestDate() }}</div>
        </div>

        <!-- Current winnings -->
        <div v-else style="text-align: right; font-size: 20px;">
          <div :class="contest.realMoney ? 'moneyText' : 'blueText'">
            <img v-if="!contest.realMoney" class="ownersBucksIcon" :src="require('@/assets/icons/ownersbucks.png')"
              alt="OwnersBucks Icon"
            >
            <img v-else class="moneyIcon" :src="require('@/assets/icons/moneybag.png')"
              alt="Money Bag"
            >
            <span>{{ (contest.realMoney ? '$' : '') + (totalWinnings || 0).formatDecimal().removeCentsIfZero() }}</span>
            <!-- Ticket Prizes -->
            <template v-if="numTicketsWon > 0">
              <span style="padding: 0 3px;">+</span>
              <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket"
                v-tooltip.bottom="{content: numTicketsWon + ' Ticket' + (numTicketsWon > 1 ? 's' : ''), class: 'ObTooltip'}"
              >
            </template>
          </div>
          <div class="infoLabel">Winning</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SalaryContestPrizePool from '@/views/SalaryContest/SalaryContestPrizePool.vue';
import ContestIcons from '@/components/Contests/ContestIcons.vue';
import ContestFillGraph from '@/views/Lobby/ContestFillGraph.vue';
import SalaryCapGameUtils from '@/utils/views/SalaryCapGameUtils';
import PieChart from '@/components/charts/PieChart';
import SponsorImage from '@/components/sponsors/SponsorImage';
import EventBus from '@/event-bus';

export default {

  components: {
    ContestIcons,
    ContestFillGraph,
    PieChart,
    SponsorImage,
    SalaryContestPrizePool,
  },

  props: {
    contest: {type: Object, default: null},
    myTeams: {type: Array, default: null},
    editingTeam: {type: String, default: null},
    showLive: {type: Boolean, default: false},
    overLimits: {type: Object, default: null},
    underLimits: {type: Object, default: null},
    selectedTeamId: {type: String, default: null},
    promoInfo: {type: Object, default: null},
  },

  data() {
    return {
      contestCountdownInterval: null,
      contestCountdownVal: '',
      playerJoined: null,
    };
  },

  created() {
    this.getContestTimeRemaining();
    this.setCountdownInterval();
  },

  destroyed() {
    clearInterval(this.contestCountdownInterval);
  },

  watch: {
    'contest.playersCurrent'() {
      this.flashPlayerJoined(true);
    },
  },

  computed: {
    contestHeader() {
      if (this.isMultiCreate) {
        return 'Custom Contests';
      }
      let name = this.contest.contestName;
      if (this.contest.groupContestsRemaining) {
        name += ' (x' + this.contest.groupContestsRemaining + ')';
      }
      return name;
    },

    contestSponsorHeader() {
      if (!this.contest?.sponsorAds) {
        return null;
      }
      return this.contest?.sponsorAds['salarycap-header'];
    },

    isMultiCreate() {
      return SalaryCapGameUtils.isMultiCreate(this.contest);
    },

    multiCreateData() {
      return SalaryCapGameUtils.multiCreateData(this.contest);
    },

    multiCreateFees() {
      const tot = SalaryCapGameUtils.multiCreateFees(this.contest);
      return tot.formatMoneyDecimals().removeCentsIfZero();
    },

    multiCreatePrizes() {
      const tot = SalaryCapGameUtils.multiCreatePrizes(this.contest);
      return tot.formatMoneyDecimals().removeCentsIfZero();
    },

    entryFee() {
      if (!this.isMultiCreate) {
        return this.contest.fee.removeCentsIfZero();
      }

      return this.multiCreateFees;
    },

    prizePool() {
      if (!this.isMultiCreate) {
        return this.contest.prizePool.removeCentsIfZero();
      }

      return this.multiCreatePrizes;
    },

    entryFeeTitle() {
      return this.isMultiCreate ? 'Entry Fees' : 'Entry Fee';
    },

    numCreateContests() {
      if (!this.isMultiCreate || !this.multiCreateData) {
        return null;
      }

      return this.multiCreateData.contestCount;
    },

    contestHasStarted() {
      return this.contestCountdownVal === 'Contest Started';
    },

    showLeaderboardData() {
      return this.contestHasStarted && this.showLive;
    },

    numPlacing() {
      let nPlacing = 0;
      for (const t of this.myTeams) {
        if (t.prizeValue && t.prizeValue > 0 || t.ticketPrize || t.splitTicketPrize || this.isPlacing(t.rank)) {
          nPlacing++;
        }
      }
      return nPlacing;
    },

    totalWinnings() {
      let tot = 0;
      for (const t of this.myTeams) {
        if (t.prizeValue && t.prizeValue > 0) {
          tot += t.prizeValue;
        }
      }
      return tot / 100;
    },

    numTicketsWon() {
      let numTickets = 0;
      for (const entry of this.myTeams) {
        if (entry.ticketPrize || entry.splitTicketPrize) {
          numTickets += 1;
        }
      }
      return numTickets;
    },

    isDailyContest() {
      return this.contest.format == 'DAILY';
    },
  },

  methods: {
    openContestModal() {
      EventBus.$emit('OPEN_LEAGUE_MODAL', this.contest, true, false);
    },

    openPromoCodeModal() {
      EventBus.$emit('SALARY_CAP_OPEN_PROMO_CODE_MODAL', this.contest, this.promoInfo);
    },

    flashPlayerJoined(change) {
      const self = this;
      this.playerJoined = change;
      setTimeout(() => {
        self.playerJoined = null;
      }, 4000);
    },

    setCountdownInterval() {
      clearInterval(this.contestCountdownInterval);
      const self = this;
      this.contestCountdownInterval = setInterval(() => {
        self.getContestTimeRemaining();
      }, 1000);
    },

    getContestDuration() {
      if (!this.contest?.days) {
        return '-';
      }
      const lastDay = this.contest.days.length - 1;
      const start = this.$moment(this.contest.days[0], 'YYYY-MM-DD').format('MMM D');
      const end = this.$moment(this.contest.days[lastDay], 'YYYY-MM-DD').format('MMM D');

      // If the date is only one day, only show the start date
      if (lastDay == 0) {
        return start;
      }

      return start + ' - ' + end;
    },

    getContestDate() {
      if (!this.contest?.contestStart) {
        return '';
      }
      return this.$moment(this.contest.contestStart).format('ddd, MMM D @ h:mma');
    },

    getContestDateFormat() {
      if (!this.contest?.contestStart) {
        return '';
      }
      return this.$moment(this.contest.contestStart).format('MMM D');
    },

    // Gets hour, minute and second difference between now and contest start
    // Displays it in a nice format, if contest has already started just show 00:00:00
    getContestTimeRemaining() {
      if (!this.contest?.contestStart) {
        return '';
      }

      const now = this.$moment();
      const cDate = this.$moment(this.contest.contestStart);

      if (cDate.diff(now) < 0) {
        this.contestCountdownVal = 'Contest Started';
        return;
      }

      const hours = String(cDate.diff(now, 'hours'));
      let minutes = String(cDate.diff(now, 'minutes') % 60);
      let seconds = String(cDate.diff(now, 'seconds') % 60);

      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      this.contestCountdownVal = hours + ':' + minutes + ':' + seconds;
    },

    getPayoutZoneWidth() {
      let pct = this.getNumWinners() / this.contest.playersCurrent * 100;
      if (pct >= 100) {
        pct = 100;
      }
      return 'width: ' + pct + '%;' + (pct >= 100 ? ' border-radius: 6px;' : '');
    },

    getNumWinners() {
      let total = 0;
      for (const entry of this.contest.payouts) {
        total += entry.payouts;
      }
      return total;
    },

    getRankPct(entry) {
      const pct = (entry.rank - 1) / (this.contest.playersCurrent - 1) * 100;
      return 'right: calc(' + pct + '% - 8px);';
    },

    changeTeam(teamId) {
      this.$router.push('/salarycontest/leaderboard/' + this.contest.id + '/' + teamId);
    },

    getPieSlices(team) {
      const slices = [];
      const pct = Math.round( team.gp / this.contest.gameLimits.total * 100 );

      let baseColor = 'var(--obcolor-ob-blue)';
      if (this.underLimits[team.teamId]) {
        baseColor = 'red';
      } else if (this.overLimits[team.teamId]) {
        baseColor = 'orange';
      }

      slices.push({color: '#474F6D', percent: pct});
      slices.push({color: baseColor, percent: 100 - pct});
      return slices;
    },

    openInviteModal() {
      EventBus.$emit('SHARE_SOCIALS_FOR_CONTEST', this.contest);
    },

    removePromo() {
      EventBus.$emit('SALARY_CAP_SET_PROMO_CODE', {code: '', state: 'NO_CODE'});
    },

    editLineups() {
      EventBus.$emit('OPEN_SALARY_CAP_EDIT_LINEUP_MODAL', this.contest);
    },

    isPlacing(rank) {
      return rank <= this.contest?.numPayouts;
    },
  },

};
</script>

<style lang="scss" scoped>

.ob-box {
  height: 80px;
  margin-bottom: 15px;
  display: flex;
  padding: 15px;
}

.badgeContainer {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 90px;
  }
}

.contestName {
  flex: 1;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 10px;
}

.promoCodeApplied, .promoCodeNone {
  font-size: 14px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover > span {
    text-decoration: underline;
  }

  em {
    cursor: pointer;
    margin-left: 5px;
    margin-bottom: 1px;
    font-size: 16px;
  }

  .fa-times {
    margin-left: 5px;
  }
}

.promoCodeApplied {
  color: var(--obcolor-yellow);
}

.promoCodeNone {
  span {
    color: var(--obcolor-green);
    padding-right: 10px;
  }
}

.infoContainer {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  margin-top: 5px;
  padding-right: 10px;
}

.infoLabel {
  color: var(--obcolor-text-secondary);
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: -12px;
}

.ownersBucksIcon {
  height: 12px;
  margin-right: 2px;
}
.moneyIcon {
  height: 22px;
  margin-bottom: -4px;
}

.infoIcon {
  color: var(--obcolor-text-secondary);
  cursor: pointer;
  font-size: 18px;
  margin-right: 10px;
}
.infoIcon:hover {
  color: var(--obcolor-text);
}

.blueText {
  color: var(--obcolor-ob-blue) !important;
  font-weight: bold;
}
.moneyText {
  color: var(--obcolor-green) !important;
  font-weight: bold;
}


.payoutBar {
  margin-top: 3px;
  height: 10px;
  width: 300px;
  border-radius: 6px;
  background: var(--obcolor-background-2);
  display: flex;
  align-items: center;
  margin-bottom: -1px;
}

.payoutZone {
  background: var(--obcolor-green);
  height: 100%;
  width: 25%;
  border-radius: 0 6px 6px 0;
  position: absolute;
  right: 0;
}

.myEntries {
  width: calc(100% - 8px);
  margin-left: 4px;
  height: 17px;
  position: relative;
}
.entryCircle.entryCircleHl {
  background: var(--obcolor-ob-blue) !important;
  z-index: 2;
  .entryTriangle {
    border-bottom: 6px solid var(--obcolor-ob-blue) !important;
  }
}
.entryCircle {
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  position: absolute;
  color: white;
  transition-property: right;
  transition-duration: 600ms;
  cursor: pointer;
  border-radius: 50%;
  background: #474F6D;
}
.entryCircle:hover {
  bottom: 33px;
}
.entryGraph {
  height: 18px;
}

.entryTriangle {
  top: 16px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #474F6D;
  position: absolute;
}

.contestHeaderContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.shareContestLink {
  padding: 5px 10px;
  height: 28px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;

  .fa-share-square {
    margin-right: 6px;
    padding-bottom: 1px;
  }

  > div {
    padding-top: 2px;
  }
}

.ob-btn {
  border: 1px solid var(--obcolor-ob-blue);
}

.lineupEditorLink {
  color: var(--obcolor-font-primary);
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  cursor: pointer;
  border: 1px solid var(--obcolor-font-primary);
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.lineupEditorLink:hover {
  background: var(--obcolor-background-2);
}

.contestCountDown {
  font-size: 20px;
  font-weight: bold;
}

.ticketIcon {
  height: 12px;
}
</style>