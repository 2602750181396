<template>
  <div>
    <div class="container">
      <div class="leftContainer">
        <!-- Sport Selector -->
        <div class="sportFilters">
          <div>
            <div class="ob-btn-transparent searchBar">
              <div class="inputIcon"><i class="fa fa-search"></i></div>
              <input placeholder="Search" v-model="playerSearchText" style="background: none">
              <div class="inputIcon cancelSearch" @click="playerSearchText = ''">
                <i class="fa fa-times"></i>
              </div>
            </div>
          </div>
          <div class="verticalSeperator"></div>
          <div v-for="(selectedSport, index) in getSportsList()" :key="index">
            <button class="btnContainer ob-btn-transparent" @click="selectSport(selectedSport)" :disabled="!selectedSport.isActive"
              :class="getSelectedClass(selectedSport)"
            >
              <i :class="selectedSport.icon"></i>
              {{ selectedSport.label }}
            </button>
          </div>
        </div>

        <div v-if=" firstLoad || marketTypesLoading ">
          <div class="activeGames">
            <button v-for="index in displayedGameAndMarketTypeTiles" :key="index" class="gameFilter loadingGame"
              data-testid="game-loading"
            >
            </button>
          </div>
          <div class="marketTypesContainer">
            <div class="typesContainer loading">
              <div v-for="index in displayedGameAndMarketTypeTiles" :key="index" class="marketType ob-btn-transparent loadingMarketType"
                data-testid="market-type-loading"
              ></div>
            </div>
          </div>
          <div class="builderContainer">
            <div class="marketContainer loading">
              <div v-for="index in displayedMarketTiles" :key="index" class="marketTileLoading flexColumn "
                data-testid="market-loading"
              >
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PropsGameFilters :games="games" v-model="gameFilter" :sport="sport.value" />
          <div class="marketTypesContainer">
            <div class="typesContainer">
              <div v-for="(marketType, index) in sortedMarketTypes" :key="index" class="marketType ob-btn-transparent"
                :class="isMarketTypeSelected(marketType) ? 'btnSelected' : 'btnDeselected'"
                @click.stop="selectMarketType(marketType)"
                data-testid="market-type-filter"
              >
                <div v-if="marketType.totalDiscounts > 0">
                  <img :src="require('@/assets/playerpicks/small-tag.svg')" alt="discount tag">
                </div>
                {{ marketType.name }}
              </div>
            </div>
          </div>

          <div class="builderContainer">
            <ContentCards locationId="player-picks-lobby" :filter="{isRealMoney: currency == 'realMoney'}" />
            <div v-if="marketsLoading" class="marketContainer loading">
              <div v-for="index in displayedMarketTiles" :key="index" class="marketTileLoading flexColumn">
              </div>
            </div>
            <div v-else-if="filteredMarkets.length == 0" class="flexColumn centered resultText">
              <div class="primaryText">
                No Search Results Found
              </div>
              <div class="secondaryText">
                Please try another search query
              </div>
            </div>
            <div v-else class="marketContainer">
              <PropsPlayerTile v-for="market in filteredMarkets" :key="marketKey(market)" :market="market" />
            </div>
          </div>
        </div>
      </div>
      <PropsEntrySlip :propsSettings="propsSettings" />
    </div>
    <PropsSubmitModal />
    <PropsHowToPlay />
    <ApplyTicketsModal contestType="PROPS" bottomNote="Tickets will apply their entry fee and currency type to a contest." />
    <PayoutStructureModal :propsSettings="propsSettings" />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObPropsApi from '@/api/ObPropsApi';
import PropsPlayerTile from './PropsPlayerTile.vue';
import PropsSubmitModal from './PropsSubmitModal.vue';
import PropsEntrySlip from './PropsEntrySlip.vue';
import PropsHowToPlay from './PropsHowToPlay.vue';
import ApplyTicketsModal from '@/components/tickets/ApplyTicketsModal.vue';
import moment from 'moment';
import PayoutStructureModal from '@/components/modals/Props/PayoutStructureModal.vue';
import MarketsMixin from '@/mixins/MarketsMixin';
import PropsSettingsMixin from '@/mixins/PropsSettingsMixin';
import MarketTypesMixin from '@/mixins/MarketTypesMixin';
import PropsGameFilters from '@/views/Props/LineupBuilder/PropsGameFilters.vue';
import {fromSearchParam, numericArrayProperty, numericProperty} from '@/utils/query';
import ContentCards from '@/components/ContentCards.vue';
import {mapGetters} from 'vuex';
import GamesMixin from '@/mixins/GamesMixin';
import {isMarketActive} from '@ownersbox/common/player-picks';
import {CanceledError} from 'axios';

export default {
  mixins: [MarketsMixin, PropsSettingsMixin, MarketTypesMixin, GamesMixin()],
  data() {
    return {
      submitData: {entryFee: null, contestType: '', picks: []},
      state: 'ACTIVE',
      marketsLoading: true,
      firstLoad: true,
      selectedCurrencyOption: 'realMoney',
      payouts: null,
      displayedMarketTiles: 0,
      displayedGameAndMarketTypeTiles: 0,
      initialPageHeight: document.documentElement.scrollHeight,
      resizeTimeout: null,
      getMarketsAbortController: null,
    };
  },

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.addTilesUntilOverflow();
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },

  created() {
    this.fullReload();
    EventBus.$on('PROPS_SELECT_MARKET', this.selectMarket);
    EventBus.$on('PROPS_UPDATE_SELECTED_MARKET', this.updateMarket);
    EventBus.$on('PROPS_REMOVE_ENTRY', this.removeEntry);
    EventBus.$on('PROPS_RESET_SLIP', this.resetSlip);
    EventBus.$on('ENTER_PLAYER_PICKS_CONTEST', this.onEnterContest);
  },

  destroyed() {
    EventBus.$off('PROPS_SELECT_MARKET', this.selectMarket);
    EventBus.$off('PROPS_UPDATE_SELECTED_MARKET', this.updateMarket);
    EventBus.$off('PROPS_REMOVE_ENTRY', this.removeEntry);
    EventBus.$off('PROPS_RESET_SLIP', this.resetSlip);
    EventBus.$off('ENTER_PLAYER_PICKS_CONTEST', this.onEnterContest);
  },

  watch: {
    sport(to, from) {
      if (!to) {
        this.selectSport(this.getSportsList()[0]);
        return;
      }
      if (to?.value === from?.value) {
        return;
      }

      this.selectedMarketTypeId = null;
      this.gameFilter = [];
      this.clearMarkets();
      this.fullReload();
    },

    marketTypes(newVal) {
      if (this.firstLoad && newVal.length === 0) {
        this.marketTypesLoading = true;
      } else {
        this.marketTypesLoading = false;
      }
    },

    marketsLoading(newVal) {
      if (newVal) {
        this.addTilesUntilOverflow();
      }
    },

    firstLoad(newVal) {
      if (newVal && this.marketTypesLoading) {
        this.addTilesUntilOverflow();
      }
    },

    propsSettings() {
      if (!this.sport) {
        this.selectSport(this.getSportsList()[0]);
      }
    },

    selectedMarketTypeId(to, from) {
      if (to == from) {
        return;
      }
      this.refreshMarkets();
    },

    games(to) {
      this.gameFilter = this.gameFilter.filter((gameId) => to.find((g) => g.id === gameId));
    },
  },

  components: {
    PropsPlayerTile,
    PropsEntrySlip,
    PropsSubmitModal,
    PropsHowToPlay,
    ApplyTicketsModal,
    PayoutStructureModal,
    PropsGameFilters,
    ContentCards,
  },

  computed: {
    ...mapGetters('playerPicks', [
      'currency',
      'selectedMarkets',
      'contestType',
      'hasDiscounts',
    ]),

    selectedMarketTypeId: fromSearchParam('market_type', numericProperty),
    gameFilter: fromSearchParam('game', numericArrayProperty),
    playerSearchText: fromSearchParam('q'),

    selectedMarketTypeIdWithDefault() {
      if (this.selectedMarketTypeId) {
        return this.selectedMarketTypeId;
      } else if (this.markets && this.markets.length > 0) {
        const firstMarketTypeId = this.markets[0].marketTypeId;
        return firstMarketTypeId;
      } else {
        return null;
      }
    },

    filteredSelectedMarkets() {
      return Object.values(this.selectedMarkets).filter((selectedMarket) => selectedMarket !== null);
    },

    sortedMarkets() {
      const allMarkets = this.markets.slice();
      // Sort by lobby rank then alphabetically
      allMarkets.sort((a, b) => {
        if (a.discount && !b.discount) {
          return -1;
        }
        if (!a.discount && b.discount) {
          return 1;
        }

        const aTime = a.game.date;
        const bTime = b.game.date;
        if (aTime !== bTime) {
          return aTime - bTime;
        }

        const compareRankResult = this.compareRank(this.getMarketType(a), this.getMarketType(b));
        if (compareRankResult !== 0) {
          return compareRankResult;
        }

        const aPlayerName = a.player.name;
        const bPlayerName = b.player.name;
        return aPlayerName.localeCompare(bPlayerName);
      });
      return allMarkets;
    },

    filteredMarketTypes() {
      return this.marketTypes.filter((type) => {
        return type.hasActiveMarkets;
      });
    },

    sortedMarketTypes() {
      // Sort by lobby rank then alphabetically
      const allTypes = this.filteredMarketTypes.slice().sort((a, b) => {
        const compareDiscountResult = this.compareDiscount(a, b);
        if (compareDiscountResult !== 0) {
          return compareDiscountResult;
        }
        const compareRankResult = this.compareRank(a, b);
        if (compareRankResult !== 0) {
          return compareRankResult;
        }
        return this.compareName(a, b);
      });
      return allTypes;
    },

    filteredMarkets() {
      return this.sortedMarkets.filter((market) => {
        return this.filterPlayerList(market.player, market.game) &&
          this.filterByGame(market.gameId);
      });
    },

    sport: {
      get() {
        return this.sports.find((sport) => sport.value == this.$route.params.sport);
      },
      set(sport) {
        if (!sport) {
          this.$router.push({params: {sport: null}});
          return;
        }
        if (sport?.value !== this.$route.params.sport) {
          this.$router.push({params: {sport: sport?.value}});
        }
      },
    },

    sports() {
      return [
        {label: 'NFL', value: 'NFL', icon: 'fas fa-football-ball', isActive: this.propsSettings.isNFLActive},
        {label: 'NBA', value: 'NBA', icon: 'fas fa-basketball-ball', isActive: this.propsSettings.isNBAActive},
        {label: 'MLB', value: 'MLB', icon: 'fas fa-baseball-ball', isActive: this.propsSettings.isMLBActive},
        {label: 'NHL', value: 'NHL', icon: 'fas fa-hockey-puck', isActive: this.propsSettings.isNHLActive},
      ];
    },
  },

  methods: {
    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.$nextTick(() => {
          this.initialPageHeight = document.documentElement.scrollHeight;
          this.addTilesUntilOverflow();
        });
      }, 200);
    },

    addTilesUntilOverflow() {
      this.displayedMarketTiles = 0;
      this.displayedGameAndMarketTypeTiles = 0;
      this.initialPageHeight = document.documentElement.scrollHeight;
      let marketTileCount = 0;
      let gameAndMarketTypeTileCount = 0;
      const containerWidth = document.querySelector('.builderContainer')?.offsetWidth || 1000;
      const tileWidth = 100;
      const tilesPerRow = Math.floor(containerWidth / tileWidth);
      const interval = setInterval(() => {
        marketTileCount++;
        this.displayedMarketTiles = marketTileCount;
        gameAndMarketTypeTileCount++;
        this.displayedGameAndMarketTypeTiles = Math.min(gameAndMarketTypeTileCount, tilesPerRow);
        this.$nextTick(() => {
          if (this.displayedMarketTiles >= tilesPerRow) {
            if (document.documentElement.scrollHeight > this.initialPageHeight) {
              clearInterval(interval);
              this.displayedMarketTiles--;
            }
          }
          if (gameAndMarketTypeTileCount >= tilesPerRow) {
            clearInterval(interval);
          }
        });
      }, 100);
    },

    isMarketTypeSelected(marketType) {
      return this.selectedMarketTypeIdWithDefault == marketType.id;
    },

    selectMarketType(marketType) {
      this.selectedMarketTypeId = marketType.id;
    },

    selectSport(sport) {
      if (!sport?.isActive) {
        return;
      }
      this.sport = sport;
    },

    refreshMarkets() {
      if (!this.sport) {
        return;
      }

      if (this.getMarketsAbortController) {
        this.getMarketsAbortController.abort();
      }
      this.getMarketsAbortController = new AbortController();

      const sport = this.sport.value;
      this.marketsLoading = true;
      return ObPropsApi.getMarkets(sport, this.selectedMarketTypeId, {
        signal: this.getMarketsAbortController.signal,
      })
          .then((response) => {
            if (sport == this.sport.value) {
              this.setMarkets(response.markets);
              this.games = response.games.sort((a, b) => {
                if (a.date !== b.date) {
                  return a.date - b.date;
                }
                return a.id - b.id;
              });
              this.marketsLoading = false;
            }
          })
          .catch((e) => {
            if (e instanceof CanceledError) {
              return;
            }
            console.log(e);
            this.marketsLoading = false;
          });
    },

    /**
     * @param {Market} market
     * @return {MarketType}
     */
    getMarketType(market) {
      return this.marketTypes.find((mt) => mt.id === market.marketType.id) || market.marketType;
    },

    fullReload() {
      if (!this.sport) {
        return;
      }

      this.firstLoad = true;
      this.setMarketTypeSport(this.sport.value);
      return this.refreshMarkets()
          .finally(() => {
            this.firstLoad = false;
          });
    },

    /**
     * @param {Market} market
     * @param {'MORE'|'LESS'} moreOrLess
     */
    selectMarket(market, moreOrLess) {
      if (!isMarketActive(market, Date.now())) {
        return;
      }
      const id = market.id;
      const discountId = market.discount?.id;
      const selectedMarket = {...market, action: moreOrLess};

      const existingMarket = this.selectedMarkets.find((item) => item.id === id && item.discount?.id === discountId);

      if (existingMarket === undefined) {
        const newSelectedMarkets = this.selectedMarkets.concat([selectedMarket]);
        this.$store.commit('playerPicks/setSelectedMarkets', newSelectedMarkets);

        const perfectMax = this.hasDiscounts ? this.propsSettings.discountMaxPicks : this.propsSettings.maxPicks;
        const protectedMax = this.hasDiscounts ? this.propsSettings.protectedDiscountMaxPicks : this.propsSettings.protectedMaxPicks;
        const overPerfectMax = newSelectedMarkets.length > perfectMax;
        const overProtectedMax = newSelectedMarkets.length > protectedMax;

        if (this.contestType === 'STANDARD' && overPerfectMax && protectedMax > perfectMax) {
          this.$store.commit('playerPicks/setContestType', 'PROTECTED');
        } else if (this.contestType === 'PROTECTED' && overProtectedMax && perfectMax > protectedMax) {
          this.$store.commit('playerPicks/setContestType', 'PERFECT');
        }
      } else if (moreOrLess === existingMarket.action) {
        this.$store.commit('playerPicks/setSelectedMarkets', this.selectedMarkets.filter((item) => !(item.id === id && item.discount?.id === discountId)));
      } else {
        this.$store.commit('playerPicks/setSelectedMarkets', this.selectedMarkets.map((market) => {
          if (market.id === selectedMarket.id && market.discount?.id === selectedMarket.discount?.id) {
            return selectedMarket;
          }
          return market;
        }));
      }
    },

    /**
     * @param {Market} market
     * @param {'MORE'|'LESS'} moreOrLess
     */
    updateMarket(market) {
      const id = market.id;
      const discountId = market.discount?.id;

      const existingIndex = this.selectedMarkets.findIndex((item) => item.id === id && item.discount?.id === discountId);

      this.$store.commit('playerPicks/setSelectedMarkets', [
        ...this.selectedMarkets.slice(0, existingIndex),
        {
          ...market,
          action: this.selectedMarkets[existingIndex].action,
        },
        ...this.selectedMarkets.slice(existingIndex + 1),
      ]);
    },

    resetSlip() {
      this.$store.commit('playerPicks/setSelectedMarkets', []);
    },

    removeEntry(market) {
      this.$store.commit('playerPicks/setSelectedMarkets', this.selectedMarkets.filter((item) => item.id !== market.id || item.discount?.id !== market.discount?.id));
    },

    onEnterContest(submitData) {
      const discountPicks = new Map(
          submitData.picks
              .filter((pick) => pick.discountId)
              .map((pick) => [pick.marketId, pick.discountId]),
      );

      const marketTypeIDs = new Set(
          this.markets
              .filter((market) => {
                const discountId = discountPicks.get(market.id);
                return discountId !== undefined && market.discount?.id === discountId;
              })
              .map((market) => market.marketTypeId),
      );

      this.setMarkets(this.markets.filter((market) => !market.isDiscounted || !discountPicks.has(market.id) || market.discount.id !== discountPicks.get(market.id) ));

      this.marketTypes = this.marketTypes.map((marketType) => {
        if (marketTypeIDs.has(marketType.id)) {
          marketType.totalDiscounts--;
        }
        return marketType;
      });
    },

    getSportsList() {
      // Display active sports first, inactives get moved to the end of the list
      const active = [];
      const inactive = [];
      for (const sport of this.sports) {
        if (sport.isActive) {
          active.push(sport);
        } else {
          inactive.push(sport);
        }
      }
      return active.concat(inactive);
    },

    getSelectedClass(selectedSport) {
      if (!selectedSport.isActive) {
        return 'btnDisabled';
      }
      if (this.sport?.value == selectedSport.value) {
        return 'btnSelected';
      } else {
        return 'btnDeselected';
      }
    },

    compareDiscount(a, b) {
      if (a.totalDiscounts > 0 && b.totalDiscounts <= 0 ) {
        return -1;
      }
      if (b.totalDiscounts > 0 && a.totalDiscounts <= 0) {
        return 1;
      }
      return 0;
    },

    compareRank(a, b) {
      // Market Type rank sort
      if (a.lobbyRank && !b.lobbyRank) {
        return -1;
      } else if (b.lobbyRank && !a.lobbyRank) {
        return 1;
      } else if (a.lobbyRank && b.lobbyRank) {
        return a.lobbyRank - b.lobbyRank;
      }

      return 0;
    },

    compareName(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },

    filterByGame(gameID) {
      return this.gameFilter.length === 0 || this.gameFilter.includes(gameID);
    },

    filterPlayerList(player, game) {
      if (!this.playerSearchText) {
        return true;
      }
      const playerName = player?.name?.toLowerCase();
      const homeTeamAlias = game?.homeTeam?.alias.toLowerCase();
      const awayTeamAlias = game?.awayTeam?.alias.toLowerCase();
      const gameDate = moment(game?.date);
      const formattedGameDay = gameDate.format('dddd').toLowerCase();
      const shortFormattedGameDay = gameDate.format('ddd').toLowerCase();

      const filterCriteria = this.playerSearchText.toLowerCase();
      const searchFilters = filterCriteria.split(' ');

      return searchFilters.every((element) =>
        (playerName.includes(element)) ||
        (homeTeamAlias?.includes(element)) ||
        (awayTeamAlias?.includes(element)) ||
        (element === 'today' && formattedGameDay === moment().format('dddd').toLowerCase()) ||
        (element === 'tomorrow' && formattedGameDay === moment().add(1, 'day').format('dddd').toLowerCase()) ||
        (element === formattedGameDay || element === shortFormattedGameDay),
      );
    },

    updateSportActive(sport, activeValue) {
      const index = this.sports.findIndex((item) => item.value.toUpperCase() === sport);

      if (index !== -1) {
        this.sports[index].isActive = activeValue;
      }
    },

    sportActive(sport) {
      const index = this.sports.findIndex((item) => item.value.toUpperCase() === sport);
      if (index !== -1) {
        return this.sports[index].isActive;
      } else {
        return false;
      }
    },

    marketKey(market) {
      return `${market.id}|${market.discount?.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 10px;
  gap: 13px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.leftContainer {
  max-width: 1040px;
  flex-grow: 1;
  margin-top: 10px;
  overflow: hidden;
}

.activeGames {
  display: flex;
  gap: 10px;
  min-height: 46px;
  }

.gameFilter {
  all: inherit;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: var(--obcolor-background-5);
  cursor: pointer;
  font-size: 14px;
  padding: 8px;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  flex-shrink: 0;
  white-space: nowrap;
   overflow: hidden;
}

.gameFilter.loadingGame {
  border: 1px solid var(--obcolor-timeline);
  pointer-events: none;
  min-width: 185px;
  animation: pulse 1s infinite;
}

.marketTypesContainer {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  align-items: center;
  gap: 8px;
}

.typesContainer {
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.marketType {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--obcolor-font-secondary);
  height: 40px;
  box-sizing: border-box;
  gap: 8px;

  &.btnDeselected {
    font-weight: 400 !important;
  }

  &.btnSelected {
    border: 1px solid var(--obcolor-ob-blue);
    color: var(--obcolor-font-primary) !important;
  }
}

.typesContainer.loading{
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  position: relative;
  flex-shrink: none;
  overflow-x: hidden;
}

.marketType.loadingMarketType {
  pointer-events: none;
  min-width: 125px;
  border: 1px solid var(--obcolor-timeline);
  animation: pulse 1s infinite;
}

.allMarketType {
  width: 40px;
  box-sizing: border-box;
}

.builderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0 0 0;
}

.marketContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 8px;
  margin-top: 8px;
  width: 100%;
}

.filterToggle {
  display: flex;
  width: 40px;
  height: 40px;
  background: var(--obcolor-background-6);
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--obcolor-font-secondary);

  &.active {
    color: var(--obcolor-ob-blue);
  }

  .inputIcon {
    font-size: 16px;
  }
}
.sportFilters {
  display: flex;
  gap: 8px;
  padding-bottom: 8px;

  .btnContainer {
    color: var(--obcolor-text-secondary);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      padding-right: 8px;
    }
  }

  .btnDeselected {
    font-weight: 400 !important;
  }

  .btnSelected {
    border: 1px solid var(--obcolor-ob-blue);
    color: var(--obcolor-text-primary) !important;
  }

  > div {
    display: flex;
  }

  .btnContainer.btnDisabled {
    cursor: not-allowed;
    order: 1;
    opacity: 0.5;
  }
}

.ob-btn-transparent {
  padding-right: 8px;
  color: var(--obcolor-font-secondary);
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBar {
  width: 250px;

  &:hover {
    background-color: var(obcolor-background-6) !important;
  }

  input {
    flex-grow: 1;
  }

  .cancelSearch {
    color: red;
    font-size: 12px;
    cursor: pointer;
    padding: 5px;
    margin-right: -4px;
  }

  .cancelSearch:hover {
    color: var(--obcolor-cancel);
  }
}

.verticalSeperator {
  align-self: center;
  height: 40px;
  max-height: 100%;
  width: 1px;
  margin: 0 8px;
  background: var(--obcolor-background-4);
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryText {
  font-weight: 700;
  color: var(--obcolor-font-primary);
}

.secondaryText {
  color: var(--obcolor-font-secondary);
}

.resultText {
  transform: translateY(50px);
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.5;
  }
}

.marketTileLoading {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  background-color: var(--obcolor-background-6);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 8px;
  min-height: 230px;
  min-width: 200px;
  pointer-events: none;
  animation: pulse 1s infinite;
  border: 1px solid var(--obcolor-timeline);
}
</style>
