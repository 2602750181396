import Api from '@/api/ObBaseApi';
import {getGeopacketConfig} from '@/utils/GeoComply.js';

export default {

  getSalaryCapDraftList(sport, ts) {
    let params = '?sport=' + sport;
    params += '&timestamp=' + ts;

    return Api().get('/fsp/sportdata/getTestDraftList' + params)
        .then((response) => {
          return response.data;
        });
  },

  async checkContestEligibility(params) {
    const reason = 'GamePlay';
    const gameType = 'SalaryCap';
    const config = await getGeopacketConfig(reason, gameType);
    return Api().get('/fsp/salarycap/checkContestEligibility', config)
        .then((response) => {
          return response.data;
        });
  },

  getContest(id, withPlayers, groupContest) {
    let optionalParams = '';
    if (withPlayers) {
      optionalParams = '&players=' + withPlayers;
    }
    if (groupContest) {
      optionalParams += '&groupContest=' + groupContest;
    }
    return Api().get('/fsp/salarycap/getContest?id=' + id + optionalParams)
        .then((response) => {
          return response.data;
        });
  },

  getLeaderboard(id) {
    return Api()
        .get('/fsp/salarycap/getLeaderboard?id=' + id)
        .then((response) => {
          return response.data;
        });
  },

  getLeaderboardPage(id, page) {
    return Api()
        .get('/fsp/salarycap/getLeaderboardPage?id=' + id + '&page=' + page)
        .then((response) => {
          return response.data;
        });
  },

  getLeaderboardPages(id, pages) {
    return Api()
        .get('/fsp/salarycap/getLeaderboardPages?id=' + id + '&pages=' + pages)
        .then((response) => {
          return response.data;
        });
  },

  getLeaderboardForUser(contestId) {
    return Api()
        .get('/fsp/salarycap/getLeaderboardForUser?id=' + contestId)
        .then((response) => {
          return response.data;
        });
  },

  getLeaderboardForTeam(contestId, teamId) {
    return Api()
        .get('/fsp/salarycap/getLeaderboardPageForTeam?id=' + contestId + '&team=' + teamId)
        .then((response) => {
          return response.data;
        });
  },

  getSwapData(id, teamId) {
    const params = '&team=' + teamId;
    return Api().get('/fsp/salarycap/getSwapData?id=' + id + params)
        .then((response) => {
          return response.data;
        });
  },

  async submitLineup(params, id, amount = 1) {
    const reason = 'GamePlay';
    const gameType = 'SalaryCap';
    const config = await getGeopacketConfig(reason, gameType);
    const queryParams = '?id=' + id + '&entryCount=' + amount + '&errorType=json';
    return Api().post('/fsp/salarycap/submitLineup2' + queryParams, params, config)
        .then((response) => {
          return response.data;
        });
  },

  editLineup(id, teamId, players, name) {
    const params = {
      players: players,
    };
    if (name) {
      params.name = name;
    }
    return Api().post('/fsp/salarycap/editLineup?id=' + id + '&team=' + teamId, params)
        .then((response) => {
          return response.data;
        });
  },

  submitLateEdit(contestId, teamId, roster) {
    const params = {
      contestId: contestId,
      teamId: teamId,
      roster: roster,
    };

    return Api().post('/fsp/salarycap/submitLateEdit', params)
        .then((response) => {
          return response.data;
        });
  },

  getTeam(id, teamId) {
    return Api().get('/fsp/salarycap/getTeam?id=' + id + '&team=' + teamId)
        .then((response) => {
          return response.data;
        });
  },

  getTeam2(id, teamId) {
    const teamParam = teamId ? '&team=' + teamId : '';
    return Api().get('/fsp/salarycap/getTeam2?id=' + id + teamParam)
        .then((response) => {
          return response.data;
        });
  },

  getPreContestLineups(id) {
    return Api().get('/fsp/salarycap/getPreContestLineups?id=' + id)
        .then((response) => {
          return response.data;
        });
  },

  renameTeam(id, teamId, name) {
    const params = {name: name};
    return Api().post('/fsp/salarycap/renameTeam?id=' + id + '&team=' + teamId, params)
        .then((response) => {
          return response.data;
        });
  },

  getTransactions(id, teamId) {
    return Api().get('/fsp/salarycap/getTransactions?league=' + id + '&team=' + teamId)
        .then((response) => {
          return response.data;
        });
  },

  getPlayerForContest(id, playerId) {
    return Api().get('/fsp/salarycap/getPlayerForContest?league=' + id + '&player=' + playerId)
        .then((response) => {
          return response.data;
        });
  },

  swapPlayer(id, teamId, pos, toDrop, toAdd) {
    const params = {
      pos: pos,
      drop: toDrop,
      add: toAdd,
    };
    return Api().post('/fsp/salarycap/swapPlayer?league=' + id + '&team=' + teamId, params)
        .then((response) => {
          return response.data;
        });
  },

  swapPlayers(id, teamId, swaps) {
    const params = {
      swaps: swaps,
    };
    return Api().post('/fsp/salarycap/swapPlayers?league=' + id + '&team=' + teamId, params)
        .then((response) => {
          return response.data;
        });
  },

  deactivatePlayer(leagueId, teamId, active, pos, player, game) {
    const params = {
      active: active,
      pos: pos,
      player: player,
      game: game,
    };
    return Api()
        .post('/fsp/salarycap/deactivatePlayer?league=' + leagueId + '&team=' + teamId, params)
        .then((response) => {
          return response.data;
        });
  },

  getContestsToImport(contestId) {
    return Api()
        .get('/fsp/salarycap/getContestsToImport?contestId=' + contestId)
        .then((response) => {
          return response.data;
        });
  },

  getTeamsForContest(contestId) {
    return Api()
        .get('/fsp/salarycap/getTeamsForContest?contestId=' + contestId)
        .then((response) => {
          return response.data;
        });
  },

  validateLineups(contestId, teams) {
    const data = {
      teams: teams,
    };

    return Api()
        .post('/fsp/salarycap/validateLineups?contestId=' + contestId, data)
        .then((response) => {
          return response.data;
        });
  },

  getPlayerSlate(contestId, contestName) {
    return Api().get('/fsp/salarycap/getPlayerSlate?contestId=' + contestId)
        .then((response) => {
          // Download csv file
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', contestName.replaceAll(' ', '-') + '-Player-Slate.csv');
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        });
  },

  getPlayerSlateWithLineups(contestId, contestName) {
    return Api().get('/fsp/salarycap/getPlayerSlateWithLineups?contestId=' + contestId)
        .then((response) => {
          // Download csv file
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', contestName.replaceAll(' ', '-') + '-Edit-Lineups.csv');
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        });
  },

  async submitLineups(params, id) {
    const reason = 'GamePlay';
    const gameType = 'SalaryCap';
    const config = await getGeopacketConfig(reason, gameType);
    return Api().post('/fsp/salarycap/submitLineups?id=' + id, params, config)
        .then((response) => {
          return response.data;
        });
  },

  editLineups(contestId, teams, onlyValidateEdits) {
    const params = {
      teams: teams,
      onlyValidateEdits: onlyValidateEdits,
    };

    return Api()
        .post('/fsp/salarycap/editLineups?contestId=' + contestId, params)
        .then((response) => {
          return response.data;
        });
  },

  async getContestsForLineup(lineupId) {
    const reason = 'GamePlay';
    const config = await getGeopacketConfig(reason, null, true);
    return Api()
        .get('/fsp/salarycap/getContestsForLineup?lineupId=' + lineupId, config)
        .then((response) => {
          return response.data;
        });
  },

  async submitLineupToContests(params) {
    const reason = 'GamePlay';
    const gameType = 'SalaryCap';
    const config = await getGeopacketConfig(reason, gameType);
    return Api()
        .post('/fsp/salarycap/submitLineupToContests', params, config)
        .then((response) => {
          return response.data;
        });
  },

  getGlobalSwapOptions() {
    return Api()
        .get('/fsp/salarycap/getGlobalSwapOptions')
        .then((response) => {
          return response.data;
        });
  },

  getQuickSwapSlates() {
    return Api()
        .get('/fsp/salarycap/getQuickSwapSlates')
        .then((response) => {
          return response.data;
        });
  },

  getQuickSwapPlayers(slateId) {
    return Api()
        .get('/fsp/salarycap/getQuickSwapPlayers?gameSlateId=' + slateId)
        .then((response) => {
          return response.data;
        });
  },

  validateQuickSwap(slateId, swaps) {
    const swapJSON = [{
      add: swaps.add.id,
      drop: swaps.drop.id,
      pos: swaps.drop.lineupPosition,
    }];
    const params = {
      gameSlateId: slateId,
      swaps: swapJSON,
    };
    return Api()
        .post('/fsp/salarycap/validateQuickSwap', params)
        .then((response) => {
          return response.data;
        });
  },

  submitQuickSwap(teamKeys, swaps) {
    const swapJSON = {
      add: swaps.add.id,
      drop: swaps.drop.id,
      pos: swaps.drop.lineupPosition,
    };
    const params = {
      teamKeys: teamKeys,
      swaps: [swapJSON],
    };
    return Api()
        .post('/fsp/salarycap/submitQuickSwap', params)
        .then((response) => {
          return response.data;
        });
  },

  uploadGlobalSwapCSV(csv) {
    const data = new FormData();
    data.append('file', csv);

    return Api()
        .post('/fsp/salarycap/uploadGlobalSwapCSV', data)
        .then((response) => {
          return response.data;
        });
  },

  processGlobalSwaps(swapsJSON) {
    const params = {
      swaps: swapsJSON,
    };
    return Api()
        .post('/fsp/salarycap/processGlobalSwaps', params)
        .then((response) => {
          return response.data;
        });
  },

  getGlobalSwapResults(swapId) {
    return Api()
        .get('/fsp/salarycap/getGlobalSwapResults?id=' + swapId)
        .then((response) => {
          return response.data;
        });
  },

  getUserGameSlateLineups(gameSlateId, gameSlateName) {
    return Api().get('/fsp/salarycap/getUserGameSlateLineups?gameSlateId=' + gameSlateId)
        .then((response) => {
          // Download csv file
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', gameSlateName.replaceAll(' ', '-') + '-GlobalSwap-Lineups.csv');
          document.body.appendChild(fileLink);

          fileLink.click();
          URL.revokeObjectURL(fileLink.href);
        });
  },

  getCreateContestOptions() {
    return Api().get('/fsp/salarycap/getCreateContestOptions')
        .then((response) => {
          return response.data;
        });
  },

  getCreateContestPayouts(entryFee, entries, multiplierSizeId, prizeStructureId, contestTypeId) {
    let params = '?entryFee=' + entryFee;
    params += '&entries=' + entries;
    params += '&multiplierSizeId=' + multiplierSizeId;
    params += '&prizeStructureId=' + prizeStructureId;
    params += '&contestTypeId=' + contestTypeId;
    return Api().get('/fsp/salarycap/getCreateContestPayouts' + params)
        .then((response) => {
          return response.data;
        });
  },

  createUserContest(params) {
    return Api().post('/fsp/salarycap/createUserContest', params)
        .then((response) => {
          return response.data;
        });
  },

  getGameSlates() {
    return Api()
        .get('/fsp/salarycap/getGameSlates')
        .then((response) => {
          return response.data;
        });
  },

  getGameSlatePlayers(slateId) {
    return Api()
        .get('/fsp/salarycap/getGameSlatePlayers?id=' + encodeURIComponent(slateId))
        .then((response) => {
          return response.data;
        });
  },

  createLineup(slateId, params) {
    return Api()
        .post('/fsp/salarycap/createLineup?slateId=' + encodeURIComponent(slateId), params)
        .then((response) => {
          return response.data;
        });
  },

  getLineups() {
    return Api()
        .get('/fsp/salarycap/getLineups')
        .then((response) => {
          return response.data;
        });
  },

  getLineupHistory() {
    return Api()
        .get('/fsp/salarycap/getLineupHistory')
        .then((response) => {
          return response.data;
        });
  },

  // Lineups page calls
  getEnteredContestsForSlate(slateId) {
    return Api()
        .get('/fsp/salarycap/getEnteredContestsForSlate?slateId=' + encodeURIComponent(slateId))
        .then((response) => {
          return response.data;
        });
  },

  getEditLineupData(slateId) {
    return Api()
        .get('/fsp/salarycap/getEditLineupData?id=' + encodeURIComponent(slateId))
        .then((response) => {
          return response.data;
        });
  },

  editSalaryCapLineup(lineupId, players) {
    const params = {
      lineupId: lineupId,
      players: players,
    };
    return Api()
        .post('/fsp/salarycap/editSalaryCapLineup', params)
        .then((response) => {
          return response.data;
        });
  },

  async getQuickEntryContests(slateId, realMoney, players) {
    let params = '?slateId=' + encodeURIComponent(slateId);
    params += '&realMoney=' + encodeURIComponent(realMoney);
    const reason = 'GamePlay';
    const config = await getGeopacketConfig(reason, null, true);
    const payload = {players: players};
    return Api()
        .post('/fsp/salarycap/getQuickEntryContests' + params, payload, config)
        .then((response) => {
          return response.data;
        });
  },

  async submitQuickEntry(players, entries) {
    const payload = {
      players: players,
      entries: entries,
    };
    const reason = 'GamePlay';
    const gameType = 'SalaryCap';
    const config = await getGeopacketConfig(reason, gameType);
    return Api()
        .post('/fsp/salarycap/submitQuickEntry', payload, config)
        .then((response) => {
          return response.data;
        });
  },
};