<template>
  <div class="slipContainer" data-testid="entry-slip">
    <div class="slipHeader">
      <div class="flexRow">
        <div class="smallRightMargin boldText">
          Current Entry
        </div>
        <div class="slipText">
          {{ slipText }}
        </div>
      </div>
      <div class="flexRow clickable" @click="resetSlip()" v-show="numSelectedPlayers > 0">
        <div class="subHeader">Clear Entry</div>
      </div>
    </div>
    <div class="slipEntries ob-scroll" ref="scrollContainer">
      <PropsSlipTile :market="market" v-for="market in filteredSelectedMarkets"
        :key="marketKey(market)" class="slipItem"
      />
      <!-- picks to go -->
      <div v-show="underMinPicks" class="picksToGo" :style="numSelectedPlayers == 0 && 'border-top: 1px solid var(--obcolor-background-4);'">
        {{ picksToGoText }}
        <div class="subHeader">Make {{ contestMinPicks }}-{{ contestMaxPicks }} picks to play</div>
      </div>
      <PropsReducedPayout v-if="!underMinPicks && payoutReduced" />
      <div v-if="numSelectedPlayers == maxPicks" class="subHeader">Max picks reached</div>
      <div v-else-if="numSelectedPlayers >= contestMinPicks" class="subHeader">Make more picks to increase payout</div>
    </div>
    <div class="slipSubmission">
      <ContentCards locationId="player-picks-entry-slip" :filter="{isRealMoney: currency == 'realMoney'}" />
      <!-- entry fee section -->
      <label class="slipTitle" for="entry-input">
        Entry Amount
      </label>
      <div class="smallMargin flexRow">
        <div class="entryTextBox smallRightMargin">
          <div class="entryValue">
            <span class="entryInput currencyInput">
              <OBCurrency :realMoney="currency !== 'ownersBucks'" />
            </span>
            <input id="entry-input" class="entryInput entryInputValue" v-model="entryValueDollars"
              placeholder="0.00" :readonly="ticketApplied"
            >
          </div>
        </div>
        <div class="flexRow currencyOptionContainer">
          <button class="entryCurrencyOption ob-btn-transparent" @click="selectCurrencyOption('realMoney')" :class="{selected: currency == 'realMoney'}">$</button>
          <button class="ownersBucks entryCurrencyOption ob-btn-transparent" @click="selectCurrencyOption('ownersBucks')" :class="{selected: currency == 'ownersBucks'}">
            <img :src="require('@/assets/icons/ownersbucks.png')" class="currencyIcon" alt="OwnersBucks Icon">
          </button>
          <button class="tickets entryCurrencyOption ob-btn-transparent" @click="openTicketsModal()" :class="{selected: currency == 'ticket'}">
            <img :src="require('@/assets/icons/ticket.png')" class="currencyIcon" alt="Contest Ticket">
          </button>
        </div>
      </div>
      <!-- Entry type selectors -->
      <button class="slipTitle" @click="showPayouts">Payout <i class="far fa-info-circle"></i></button>
      <div class="entryTypeContainer smallMargin flexRow">
        <div class="entryTypeOption" :class="{selected: contestType === 'STANDARD', entryTypeDisabled: perfectPicksDisabled}" @click="selectContestType('STANDARD')">
          <div class="flexRow boldText centered spacedBottom">
            <div class="selectable" :class="{selected: contestType === 'STANDARD'}">
            </div>
            <div class="optionTitle">Perfect Picks</div>
          </div>
          <div class="flexRow spacedContent centered">
            <div class="boldText">
              {{ perfectCount }}/{{ perfectCount }} <span class="normalText">Correct</span>
            </div>
            <div class="multiplier">
              {{ perfectPayoutMultiplier }}x
            </div>
          </div>
        </div>
        <div class="entryTypeOption" :class="{selected: contestType == 'PROTECTED', entryTypeDisabled: protectedPicksDisabled}" @click="selectContestType('PROTECTED')">
          <div class="flexRow boldText centered spacedBottom">
            <div class="selectable" :class="{selected: contestType == 'PROTECTED'}">
            </div>
            <div class="optionTitle">Protected Picks</div>
          </div>

          <div class="flexColumn">
            <div v-for="(multiplier, key) in protectedPayoutMultipliers" :key="key" class="flexRow spacedContent centered spacedBottom">
              <div class="boldText">
                {{ protectedCount - parseInt(key) }}/{{ protectedCount }} <span class="normalText">Correct</span>
              </div>
              <div class="multiplier">
                {{ multiplier }}x
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="hasSubmitError" class="errorContainer">
        {{ submitError }}
      </div>
      <div class="flexRow centered smallMargin">
        <div v-if="isSubmitting" class="ob-btn-grey confirmBtn disabledBtn confirmBtnLoading">
          <ObLoading :size="30" class="loading" />
          Submitting...
        </div>
        <div v-else class="ob-btn confirmBtn" type="button"
          :class="canSubmit ? 'ob-btn' : 'ob-btn-grey disabledBtn'" @click="createPropContest()"
        >
          <template v-if="errorMessage">
            {{ errorMessage }}
          </template>
          <template v-else-if="canSubmit">
            {{ this.ticketApplied ? 'Submit Free Entry |' : 'Submit Entry |' }}
            <OBCurrency :realMoney="currency !== 'ownersBucks'" invert :cents="this.toWinCents" /> Payout
          </template>
          <template v-else>
            Submit
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObPropsApi from '@/api/ObPropsApi';
import Decimal from 'decimal.js';
import PropsSlipTile from './PropsSlipTile.vue';
import ObLoading from '@/components/ObLoading.vue';
import OBCurrency from '@/components/OBCurrency.vue';
import MarketsMixin from '@/mixins/MarketsMixin';
import PropsReducedPayout from './PropsReducedPayout.vue';
import {deepEqual} from '@/utils/objects';
import {clamp} from '@/utils/NumberUtils';
import ContentCards from '@/components/ContentCards.vue';
import {mapGetters} from 'vuex';
import {CanceledError} from 'axios';
import {isMarketActive} from '@ownersbox/common/player-picks';
import TimeMixin from '@/mixins/TimeMixin';

export default {
  mixins: [MarketsMixin, TimeMixin],

  props: {
    propsSettings: {type: Object, required: true},
  },

  data() {
    return {
      submitData: {entryFee: null, contestType: '', picks: []},
      entryValueDollars: null,
      ticketApplied: null,
      isSubmitting: false,
      submitError: null,

      payouts: null,
      /** @type {AbortController} */
      payoutsAbort: null,
      perfectPayoutMultiplier: 1,
      perfectCount: 1,
      protectedPayoutMultipliers: {0: 1},
      protectedCount: 1,
    };
  },

  created() {
    EventBus.$on('PROPS_RESET_ENTRY_DATA', this.resetEntryData);
    EventBus.$on('APPLY_TICKET', this.applyTicket);
    EventBus.$on('SOCKET_BROADCAST', this.socketBroadcast);
    this.$SocketController.subscribeToRoom('PROP_CONTEST_PAYOUTS_UPDATE');
    this.updatePayouts(this.selectedMarkets);
  },

  destroyed() {
    EventBus.$off('PROPS_RESET_ENTRY_DATA', this.resetEntryData);
    EventBus.$off('APPLY_TICKET', this.applyTicket);
    EventBus.$off('SOCKET_BROADCAST', this.socketBroadcast);
    this.$SocketController.unsubscribeFromRoom('PROP_CONTEST_PAYOUTS_UPDATE');

    this.$store.commit('playerPicks/setCurrency', 'realMoney');
    this.$store.commit('playerPicks/setSelectedMarkets', []);
    this.$store.commit('playerPicks/setContestType', 'STANDARD');
  },

  watch: {
    // Remove any part of the entryValue that doesn't follow the decimal format
    entryValueDollars(newVal, oldVal) {
      this.entryValueDollars = this.$Helpers.validateEntryFee(newVal);
    },

    clonedSelectedMarkets(newVal, oldVal) {
      const self = this;
      if (newVal.length > oldVal.length) {
        this.$nextTick(function() {
          self.$refs.scrollContainer.scrollTop = self.$refs.scrollContainer.scrollHeight;
        });
      }
    },

    selectedMarkets(newVal) {
      const liveMarkets = this.markets;
      const newLiveMarkets = [];

      for (const selectedMarket of newVal) {
        const liveMarket = liveMarkets.find((market) => market.id === selectedMarket.id && market.discount?.id == selectedMarket.discount?.id);
        if (liveMarket) {
          newLiveMarkets.push(liveMarket);
        } else {
          newLiveMarkets.push(selectedMarket);
        }
      }

      this.setMarkets(newLiveMarkets);
      this.updatePayouts(newVal);
      this.submitError = null;
    },

    propsSettings() {
      this.updatePayouts(this.selectedMarkets);
    },
  },

  components: {
    PropsSlipTile,
    ObLoading,
    OBCurrency,
    PropsReducedPayout,
    ContentCards,
  },

  computed: {
    ...mapGetters('playerPicks', [
      'currency',
      'contestType',
      'selectedMarkets',
      'contestMinPicks',
      'contestMaxPicks',
      'hasDiscounts',
    ]),

    isRealMoney() {
      return this.currency !== 'ownersBucks';
    },

    maxEntryFee() {
      if (this.hasDiscount) {
        return Math.min(this.propsSettings.maxEntryFee, this.propsSettings.discountMaxEntryFee);
      }
      return this.propsSettings.maxEntryFee;
    },
    minEntryFee() {
      return this.propsSettings.minEntryFee;
    },
    minPicks() {
      return this.propsSettings.minPicks;
    },
    maxPicks() {
      return this.propsSettings.maxPicks;
    },
    discountMaxEntryFee() {
      return this.propsSettings.discountMaxEntryFee;
    },

    payoutReduced() {
      return !deepEqual(
          this.propsSettings.payoutMultipliers,
          this.contestPayouts.payoutMultipliers,
      ) ||
      !deepEqual(
          this.propsSettings.protectedPayoutMultipliers,
          this.contestPayouts.protectedPayoutMultipliers,
      );
    },

    contestPayouts() {
      if (this.payouts) {
        return this.payouts;
      }
      return {
        payoutMultipliers: this.propsSettings.payoutMultipliers,
        protectedPayoutMultipliers: this.propsSettings.protectedPayoutMultipliers,
      };
    },

    selectedMarketsChanged() {
      for (const selectedMarket of this.selectedMarkets) {
        const liveMarket = this.markets.find((market) => market.id === selectedMarket.id && market.discount?.id == selectedMarket.discount?.id);

        const liveLine = liveMarket?.discount?.discountLine ?? liveMarket?.line.balancedLine;
        const selectedLine = selectedMarket.discount?.discountLine ?? selectedMarket?.line.balancedLine;
        if (
          liveLine !== selectedLine ||
          !isMarketActive(liveMarket, this.now)
        ) {
          return true;
        }
      }
      return false;
    },

    numDiscounts() {
      return this.selectedMarkets.filter((m) => m.isDiscounted).length;
    },

    multipleDiscounts() {
      return this.numDiscounts > 1;
    },
    hasDiscount() {
      return this.numDiscounts > 0;
    },

    clonedSelectedMarkets() {
      return structuredClone(this.selectedMarkets);
    },

    toWinValue() {
      return (this.toWinCents / 100).toFixed(2);
    },

    toWinCents() {
      let entryValueDollarsDecimal = new Decimal(0);
      try {
        entryValueDollarsDecimal = new Decimal(this.entryValueDollars);
        if (this.contestType === 'STANDARD') {
          entryValueDollarsDecimal = entryValueDollarsDecimal.times(this.perfectPayoutMultiplier);
        } else {
          entryValueDollarsDecimal = entryValueDollarsDecimal.times(this.protectedPayoutMultipliers[0]);
        }
      } catch (error) {
        // Do nothing, use 0
      }
      return entryValueDollarsDecimal.times(100).toNumber();
    },

    entryValue() {
      let entryDecimal = new Decimal(0);
      try {
        entryDecimal = new Decimal(this.entryValueDollars);
      } catch (error) {
        // Do nothing, use 0
      }
      entryDecimal = entryDecimal.times(100);
      return Number(entryDecimal);
    },

    canSubmit() {
      return this.errorMessage === '';
    },

    filteredSelectedMarkets() {
      return Object.values(this.selectedMarkets).filter((selectedMarket) => selectedMarket !== null);
    },

    numSelectedPlayers() {
      return Object.keys(this.selectedMarkets).length.toString();
    },

    slipText() {
      const numPlayers = !this.selectedMarkets || Object.keys(this.selectedMarkets).length == 0 ? 'No' : this.numSelectedPlayers;
      const text = numPlayers + ' players selected';
      if (this.numSelectedPlayers == 1) {
        return text.removePluralWords();
      }
      return text;
    },

    underMinPicks() {
      return this.numSelectedPlayers < this.contestMinPicks;
    },

    aboveMaxPicks() {
      return this.numSelectedPlayers > this.contestMaxPicks;
    },

    picksToGo() {
      return numToPlay;
    },

    picksToGoText() {
      const numToPlay = this.contestMinPicks - this.numSelectedPlayers;
      const text = numToPlay !== 1 ? 'Picks to Go' : 'Pick to Go';
      return numToPlay + ' ' + text;
    },

    hasDuplicatePlayerIds() {
      return this.selectedMarkets?.some((market, index, marketsArray) => {
        return marketsArray.findIndex((m) => m?.playerId === market?.playerId) !== index;
      });
    },

    isAllSameTeam() {
      if (this.selectedMarkets.length > 1) {
        const firstTeamId = this.selectedMarkets[0].player?.teamId;
        return this.selectedMarkets.every((market) => market.player.teamId === firstTeamId);
      }
      return false;
    },

    aboveEntryRange() {
      return this.entryValue > this.maxEntryFee;
    },

    underDiscountMinPicks() {
      return this.hasDiscount && this.numSelectedPlayers < this.discountMinPicks;
    },

    belowEntryRange() {
      return this.entryValue != '' && this.entryValue < this.minEntryFee;
    },

    hasSubmitError() {
      return this.submitError != null && this.submitError != '';
    },

    errorMessage() {
      if (this.underMinPicks) {
        return 'Select Minimum ' + this.contestMinPicks + ' Players to Place Entry';
      } else if (this.aboveMaxPicks) {
        return 'Select Maximum ' + this.contestMaxPicks + ' Players to Place Entry';
      } else if (this.aboveEntryRange) {
        return 'Max Entry Amount is $' + this.maxEntryFee.centsToDollars();
      } else if (this.belowEntryRange) {
        return 'Min Entry Amount is $' + this.minEntryFee.centsToDollars();
      } else if (this.hasDuplicatePlayerIds) {
        return 'Cannot Pick the Same Player More Than Once';
      } else if (this.isAllSameTeam) {
        return 'Cannot Pick Players from Only One Team';
      } else if (this.multipleDiscounts) {
        return 'Only One Discounted Player Per Entry';
      } else if (!this.entryValueDollars || this.entryValueDollars == 0) {
        return 'Entry Amount is Required';
      } else if (this.selectedMarketsChanged) {
        return 'Unresolved Errors in Entry Slip';
      }
      return '';
    },

    perfectPicksText() {
      return Math.max(this.selectedMarkets.length, this.contestMinPicks);
    },

    protectedPicksHigh() {
      return Math.max(this.selectedMarkets.length, this.protectedMinPicks);
    },

    perfectPicksDisabled() {
      return this.selectedMarkets.length > (this.hasDiscount ? this.propsSettings.discountMaxPicks : this.propsSettings.maxPicks);
    },

    protectedPicksDisabled() {
      return this.selectedMarkets.length > (this.hasDiscount ? this.propsSettings.protectedDiscountMaxPicks : this.propsSettings.protectedMaxPicks);
    },
  },

  methods: {
    resetSlip() {
      EventBus.$emit('PROPS_RESET_SLIP');
    },

    resetEntryData() {
      // Fee is reset after submiting a contest entry
      this.entryValueDollars = null;
      this.$store.commit('playerPicks/setContestType', 'STANDARD');

      // If we used a ticket to enter, clear it since it will no longer be valid
      this.ticketApplied = null;
      if (this.currency == 'ticket') {
        this.$store.commit('playerPicks/setCurrency', 'realMoney');
      }
    },

    createPropContest() {
      if (this.canSubmit) {
        // create entry object
        const picks = [];
        this.isSubmitting = true;
        this.submitError = '';

        for (const pick in this.selectedMarkets) {
          const {
            id,
            action,
            line: {balancedLine},
            discount,
          } = this.selectedMarkets[pick];
          picks.push({
            marketId: id,
            selection: action,
            balancedLine: balancedLine,
            discountId: discount?.id,
            discountLine: discount?.discountLine,
          });
        }
        this.submitData.picks = picks;
        this.submitData.entryFee = this.entryValue;
        this.submitData.contestType = this.contestType;
        this.submitData.promoCode = this.ticketApplied;
        this.submitData.realMoney = this.isRealMoney;

        if (this.contestType == 'STANDARD') {
          this.submitData.payoutMultipliers = this.contestPayouts.payoutMultipliers;
          this.submitData.minPicks = this.propsSettings.minPicks;
        } else {
          this.submitData.protectedPayoutMultipliers = this.contestPayouts.protectedPayoutMultipliers;
          this.submitData.protectedMinPicks = this.propsSettings.protectedMinPicks;
        }

        ObPropsApi.createPropContest(this.submitData)
            .then((_response) => {
              this.submitSuccess();
            })
            .catch((error) => {
              this.submitFailure(error);
            })
            .finally(() => {
              this.resetSubmitData();
            });
      }
    },

    resetSubmitData() {
      this.submitData = {entryFee: null, contestType: '', picks: []};
      this.isSubmitting = false;
    },

    submitFailure(error) {
      const errorMsg = error?.response?.data?.errorMessage ?? 'Invalid Entry Slip';

      // Show modal if this is a location error
      const locationError = error?.response?.data?.locationError;
      if (locationError) {
        this.submitError = null;
        EventBus.$emit('SHOW_LOCATION_ERROR_MODAL', locationError);
      } else {
        this.submitError = errorMsg;
      }
    },

    submitSuccess() {
      const params = {
        selectedMarkets: this.filteredSelectedMarkets,
        submitData: this.submitData,
        numSelectedPlayers: this.numSelectedPlayers,
        isRealMoney: this.isRealMoney,
        entryValueDollars: this.entryValueDollars,
        toWinValue: this.toWinValue,
      };
      EventBus.$emit('UPDATE_ACCOUNT_INFO');
      EventBus.$emit('ENTER_PLAYER_PICKS_CONTEST', this.submitData);
      EventBus.$emit('PROPS_OPEN_SUBMIT_MODAL', params);
    },

    selectCurrencyOption(option) {
      if (option !== 'ticket') {
        this.ticketApplied = null;
        // Reset the entry fee if it was previously set by a ticket
        if (this.currency == 'ticket') {
          this.entryValueDollars = null;
        }
      }

      this.$store.commit('playerPicks/setCurrency', option);
    },

    openTicketsModal() {
      EventBus.$emit('OPEN_APPLY_TICKETS_MODAL', this.ticketApplied, null);
    },

    // Triggered by the ApplyTicketsModal, when a valid ticket is selected
    applyTicket(ticket) {
      if (ticket && ticket.id && ticket.feeValue) {
        this.ticketApplied = ticket.id;
        this.entryValueDollars = ticket.feeValue.centsToDollars().removeCentsIfZero();
        this.$store.commit('playerPicks/setCurrency', 'ticket');
      }
    },

    getSubmitText() {
      if (this.errorMessage) {
        return this.errorMessage;
      } else if (this.canSubmit) {
        const submitText = this.ticketApplied ? 'Submit Free Entry | $' : 'Submit Entry | $';
        return submitText + this.toWinValue + ' Payout';
      } else {
        return 'Submit';
      }
    },

    selectContestType(contestType) {
      if (contestType == null) {
        return;
      }
      if (contestType === 'STANDARD' && this.perfectPicksDisabled) {
        return;
      }
      if (contestType === 'PROTECTED' && this.protectedPicksDisabled) {
        return;
      }
      this.$store.commit('playerPicks/setContestType', contestType);
    },

    showPayouts() {
      EventBus.$emit('OPEN_PROPS_PAYOUT_STRUCTURE_MODAL', this.contestPayouts);
    },

    updatePayouts(selectedMarkets) {
      const picks = selectedMarkets.map((m) => ({
        marketId: m.id,
        balancedLine: m.line.balancedLine,
        selection: m.action,
      }));
      if (this.payoutsAbort) {
        this.payoutsAbort.abort();
      }
      this.payoutsAbort = new AbortController();
      return ObPropsApi.payouts({picks: picks}, {signal: this.payoutsAbort.signal}).then((payouts) => {
        this.payouts = payouts;

        const perfectMin = this.hasDiscounts ? this.propsSettings.discountMinPicks : this.propsSettings.minPicks;
        const perfectMax = this.hasDiscounts ? this.propsSettings.discountMaxPicks : this.propsSettings.maxPicks;

        this.perfectCount = clamp(picks.length, perfectMin, perfectMax);
        this.perfectPayoutMultiplier = payouts.payoutMultipliers[this.perfectCount] ?? 1;

        const protectedMin = this.hasDiscounts ? this.propsSettings.protectedDiscountMinPicks : this.propsSettings.protectedMinPicks;
        const protectedMax = this.hasDiscounts ? this.propsSettings.protectedDiscountMaxPicks : this.propsSettings.protectedMaxPicks;
        this.protectedCount = clamp(picks.length, protectedMin, protectedMax);

        this.protectedPayoutMultipliers = payouts.protectedPayoutMultipliers[this.protectedCount] ?? {0: 1};
      }).catch((err) => {
        if (err instanceof CanceledError) {
          return;
        }
        throw err;
      });
    },

    socketBroadcast(data) {
      if (data.type !== 'PROP_CONTEST_PAYOUTS_UPDATE') {
        return;
      }
      this.updatePayouts(this.selectedMarkets);
    },

    marketKey(market) {
      return `${market.id}|${market.discount?.id}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.flexRow {
  display: flex;
  flex-direction: row;
}

.centered {
  display: flex;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.subHeader {
  font-weight: 400;
  font-size: 16px;
  color: var(--obcolor-font-secondary);
  margin: 0;
  padding: 0;
}

.builderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 10px 0 0;
}

.slipContainer {
  width: 384px;
  box-sizing: border-box;
  align-self: start;
  min-height: 650px;
  max-height: calc(100vh - 145px);
  border-radius: 8px;
  flex-direction: column;
  display: flex;
  margin-top: 10px;
  position: sticky;
  top: 135px;
  border: 1px solid var(--obcolor-timeline);
  overflow-y: hidden;
  flex-shrink: 0;

  @media (max-height: 795px) {
    min-height: unset;
    height: calc(100vh - 145px);
  }
}


.slipHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

.slipEntries {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}

.slipSubmission {
  display: flex;
  padding-top: 8px;
  gap: 4px;
  flex-direction: column;
  background-color: var(--obcolor-background-6);
  border-radius: 0 0 8px 8px;
}

.confirmBtn {
  padding: 5px;
  width: 100%;
  border-radius: 4px;
  font-size: 16px;
  line-height: 38px;
  font-weight: bold;
  box-sizing: border-box;
}
.confirmBtnLoading {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.disabledBtn {
  cursor: not-allowed;
  color: var(--obcolor-font-secondary);
}

.submitText {
  margin-right: 0.25em;
}

.entryTextBox {
  height: 34px;
  padding: 0 4px;
  flex: 1;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--obcolor-timeline);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--obcolor-background-2);
  font-size: 14px;
}

.entryValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.entryInput {
  font-size: 16px;
  font-weight: bold;
  padding-left: 0;
  background: none;
  margin-left: 4px;
  height: 20px;
  display: flex;
  align-items: center;
}

.currencyInput {
  display: inline-block;
  width: 2ch;
  text-align: right;

  & > img {
    width: 100%;
  }
}

.entryInputValue {
  width: 100%;
  line-height: 20px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.smallMargin {
  margin: 4px;
}

.medMargin {
  margin: 8px;
}

.slipTitle {
  all: unset;
  margin-left: 16px;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
}

.smallRightMargin {
  margin-right: 4px;
}

.clickable {
  cursor: pointer;
}

.slipText {
  margin-left: 2px;
  color: var(--obcolor-font-secondary);
}

.boldText {
  font-weight: 700;
}

.normalText {
  font-weight: 400;
  font-size: 14px;
}

.loading {
  margin-left: -10px;
  margin-top: -2px;
}

.ob-btn-transparent {
  padding-right: 8px;
  color: var(--obcolor-font-secondary);
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorContainer {
  display: flex;
  justify-content: center;
  margin: 0 16px;
  color: var(--obcolor-ob-red);
}

.picksToGo {
  height: 77px;
  width: 368px;
  color: var(--obcolor-font-primary);
  font-weight: 700;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.slipItem {
  margin: 4px 0;
}

.currencyIcon {
  width: 20px;
}

.entryCurrencyOption {
  color: var(--obcolor-font-primary);
  height: 32px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  &:hover:not(.comingSoon) {
    background-color: var(--obcolor-background-4);
    cursor: pointer;
  }
  &.comingSoon {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.entryCurrencyOption.selected {
  background-color: var(--obcolor-background-2);
  border: 1px solid var(--obcolor-ob-blue);
}

.currencyOptionContainer {
  border-radius: 8px;
  border: 1px solid var(--obcolor-timeline);
}

.entryTypeContainer {
  gap: 4px;
}

.entryTypeOption {
  display: flex;
  flex-direction: column;
  width: 182px;
  border-radius: 8px;
  border: 1px solid var(--obcolor-timeline);
  align-self: flex-start;
  padding: 8px;

  &:hover:not(.comingSoon) {
    background-color: var(--obcolor-background-4);
    cursor: pointer;
  }

  &.selected {
    border: 1px solid var(--obcolor-ob-blue);
  }

  &.comingSoon {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.entryTypeDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.multiplier {
  font-weight: 700;
  width: 40px;
  height: 28px;
  background-color: var(--obcolor-background-4);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectable {
  border-radius: 50%;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 2px solid var(--obcolor-font-primary);
  box-shadow: inset 0 0 0 1.5px var(--obcolor-background-6);

  &.selected {
    background-color: var(--obcolor-ob-blue);
  }
}

.spacedContent {
  display: flex;
  justify-content: space-between;
}

.optionTitle {
  font-size: 14px;
  margin-left: 4px;
}

.spacedBottom {
  margin-bottom: 4px;
}

.hidden {
  visibility: hidden;
}

</style>
