import { render, staticRenderFns } from "./SalaryCapConfirmModal.vue?vue&type=template&id=2e07d4d2&scoped=true"
import script from "./SalaryCapConfirmModal.vue?vue&type=script&lang=js"
export * from "./SalaryCapConfirmModal.vue?vue&type=script&lang=js"
import style0 from "./SalaryCapConfirmModal.vue?vue&type=style&index=0&id=2e07d4d2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e07d4d2",
  null
  
)

export default component.exports