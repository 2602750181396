<template>
  <div class="rosterBox ob-box">
    <!-- Header -->
    <div class="headerContainer">
      <div>
        <div>{{ isEditing ? 'Your' : 'Editing' }} Lineup</div>
        <div v-if="contest.entriesPerUser > 0" class="headerLabel">Entry {{ entryNumber }}/{{ contest.entriesPerUser }}</div>
        <div v-else class="headerLabel">Entry</div>
      </div>
      <div>
        <div class="moneyText">{{ dollarFormat(contest.salaryCap - totSpent) }}</div>
        <div class="headerLabel">Rem. Salary</div>
      </div>
      <div>
        <div class="moneyText">{{ dollarFormat(avgRemainingSalary) }}</div>
        <div class="headerLabel">Avg Rem.</div>
      </div>
      <div>
        <div class="blueText">{{ $SportInfo.totalProjections(roster, picks) }}</div>
        <div class="headerLabel">Projected</div>
      </div>
    </div>

    <!-- Selected Players -->
    <div class="playerList ob-scroll">
      <table class="playersTable" aria-label="Selected Players">
        <tr>
          <th style="width: 35px;"></th>
          <th>PLAYER</th>
          <th style="text-align: center; width: 90px;">PROJ</th>
          <th style="text-align: center; width: 90px;">SALARY</th>
          <th style="width: 40px;"></th>
        </tr>
        <template v-for="(entry, index) in displayGroups">
          <SalaryCapRosterPlayerRow v-for="(player, index2) in picks[entry.pos]" :key="index + '_'+ index2"
            :player="player" :contest="contest" :pos="entry.pos"
            :statusMap="statusMap" :isGameSlateLineup="isGameSlateLineup"
            :allowClick="allowClick(player)"
            :editingTeam="editingTeam"
          />
          <SalaryCapRosterPlayerRow v-for="index3 in rosterRemainingPicks(entry.pos)"
            :contest="contest" :pos="entry.pos" :posLabel="entry.label"
            :flexLabel="entry.flexPos"
            :statusMap="statusMap" :key="index + '_' + index3 + '_empty'"
          />
        </template>
      </table>
    </div>

    <!-- Submit, Clear, Import Buttons -->
    <div class="submitContainer">
      <div v-if="!editingGameSlateLineup" class="ob-link" @click="resetRoster()">Clear</div>
      <div v-if="showImportButton" class="ob-link" @click="openImportLineups()">Import</div>

      <!-- Create Game Slate Lineup -->
      <div v-if="isGameSlateLineup" class="submitButtonContainer">
        <div v-if="canSubmit" class="ob-btn submitBtn" @click="submitChanges()">{{ submitBtnText }}</div>
        <div v-else-if="overCap" disabled class="ob-btn-grey submitBtn">Over Salary Cap</div>
        <div v-else disabled class="ob-btn-grey submitBtn">{{ submitBtnText }}</div>
      </div>

      <!-- Submit Salary Cap Contest Entry -->
      <div v-else class="submitButtonContainer">
        <div v-if="canSubmit" class="ob-btn submitBtn" @click="openSubmitConfirmModal()">
          {{ submitText }}
        </div>
        <div v-else-if="overCap" disabled class="ob-btn-grey submitBtn">Over Salary Cap</div>
        <div v-else-if="contestFull" disabled class="ob-btn-grey submitBtn">Contest Full</div>
        <div v-else-if="maxEntriesReached && !isEditing" disabled class="ob-btn-grey submitBtn">Max Entries Reached</div>
        <div v-else disabled class="ob-btn-grey submitBtn">
          {{ submitText }}
        </div>

        <!-- Number of lineups dropdown -->
        <DropdownButton v-if="!isEditing && !isMultiCreate && numLineupsDropdownOptions.length > 1" class="numLineupsDropdown">
          <div class="dropdownBtn" :class="canSubmit ? 'ob-btn' : 'ob-btn-grey'" slot="button">
            <i class="fas fa-chevron-down"></i>
          </div>
          <template slot="optionList">
            <div v-for="option in numLineupsDropdownOptions" class="dropdownOption" :class="{disabled: !canSubmitAmount(option)}"
              :key="'dropdownOption_' + option" @click.stop="selectNumLineups(option)"
            >
              <div>{{ option }} {{ option === 1 ? 'Entry' : 'Entries' }}</div>
              <div v-if="contest.realMoney" class="moneyText">${{ numLineupsPriceCents(option).dollarWithCentsFormat() }}</div>
              <div v-else class="blueText">
                <img :src="require('@/assets/icons/ownersbucks.png')" class="ownersbucksIcon" alt="OwnersBucks">
                {{ numLineupsPriceCents(option).dollarWithCentsFormat() }}
              </div>
            </div>
          </template>
        </DropdownButton>
      </div>
    </div>
  </div>
</template>

<script>

import SalaryCapRosterPlayerRow from './SalaryCapRosterPlayerRow.vue';
import DropdownButton from '@/components/buttons/DropdownButton';
import SalaryCapGameUtils from '@/utils/views/SalaryCapGameUtils';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import LineupsUtils from '@/utils/shared/LineupsUtils';

export default {

  components: {
    SalaryCapRosterPlayerRow,
    DropdownButton,
  },

  props: {
    picks: {type: Object, default: null},
    contest: {type: Object, default: null},
    totSpent: {type: Number, default: null},
    statusMap: {type: Object, default: null},
    lineupName: {type: Object, default: null},
    submittedLineups: {type: Object, default: null},
    editingTeam: {type: String, default: null},
    promoInfo: {type: Object, default: null},
    playerList: {type: Array, default: null},
    numLineups: {type: Number, default: null},
    isGameSlateLineup: {type: Boolean, default: false},
    editingGameSlateLineup: {type: Boolean, default: false},
    rosterHasChanged: {type: Boolean, default: false},
  },

  data() {
    return {
      editLineup: 'New',
    };
  },

  created() {
    EventBus.$on('SALARY_CAP_SET_SELECTED_LINEUP', this.setSelectedLineup);
  },

  destroyed() {
    EventBus.$off('SALARY_CAP_SET_SELECTED_LINEUP', this.setSelectedLineup);
  },

  computed: {
    ...mapState(['balanceValue', 'ownersbucksValue']),

    submitBtnText() {
      return this.isEditing ? 'Submit Changes' : 'Create Lineup';
    },

    isEditing() {
      return this.editingTeam != null || this.editingGameSlateLineup;
    },

    isMultiCreate() {
      return SalaryCapGameUtils.isMultiCreate(this.contest);
    },

    roster() {
      if (!this.contest || !this.contest.draftRoster || !this.contest.draftRoster.roster) {
        return null;
      }
      return this.contest.draftRoster.roster;
    },

    submitText() {
      const editText = 'Changes';
      const createText = this.numLineups + ' Lineup' + (this.numLineups > 1 ? 's' : '');
      return 'Submit ' + (this.isEditing ? editText : createText);
    },

    maxPossibleEntries() {
      if (!this.contest || !this.contest.entriesRemaining || !this.contest.myEntriesRemaining ) {
        return 0;
      }
      return Math.min(this.contest.entriesRemaining, this.contest.myEntriesRemaining);
    },

    numLineupsDropdownOptions() {
      const defaultOptions = [1, 5, 10, 25, 50, 100, 150];
      const dropdownOptions = [];

      for (const option of defaultOptions) {
        if (option >= this.maxPossibleEntries) {
          dropdownOptions.push(this.maxPossibleEntries);
          break;
        }
        dropdownOptions.push(option);
      }
      return dropdownOptions;
    },

    entryNumber() {
      if (!this.contest || this.contest.myEntries == null) {
        return 1;
      }
      if (this.editingTeam == null) {
        return this.contest.myEntries + 1;
      }

      return this.editingTeam.split('_')[1];
    },

    canSubmit() {
      if (this.editingGameSlateLineup && !this.rosterHasChanged) {
        return false;
      }
      return this.totPicksRemaining == 0 && this.contest && this.contest.salaryCap &&
        this.contest.id && !this.overCap && (!this.maxEntriesReached || this.isEditing) && (!this.contestFull || this.isEditing);
    },

    overCap() {
      return this.totSpent !== null && this.totSpent > this.contest.salaryCap;
    },

    contestFull() {
      return this.contest.playersCurrent >= this.contest.playersMax;
    },

    maxEntriesReached() {
      if (!this.contest || !this.contest.myEntries || !this.contest.entriesPerUser) {
        return false;
      }

      return this.contest.myEntries >= this.contest.entriesPerUser;
    },

    displayGroups() {
      if (!this.contest) {
        return [];
      }
      return this.$SportInfo.getScDisplayGroupsDaily(this.contest.sport);
    },

    totPicked() {
      let tot = 0;
      for (const posInfo of this.roster) {
        if (this.picks[posInfo.short]) {
          tot += this.picks[posInfo.short].length;
        }
      }

      return tot;
    },

    totPicksRemaining() {
      let tot = 0;
      for (const posInfo of this.roster) {
        tot += posInfo.num;
      }
      return tot - this.totPicked;
    },

    avgRemainingSalary() {
      if (this.totPicksRemaining == 0) {
        return 0;
      }
      if (this.totSpent == null) {
        return this.contest.salaryCap / this.totPicksRemaining;
      }
      const remainingAvg = (this.contest.salaryCap - this.totSpent) / this.totPicksRemaining;
      return remainingAvg.toFixed(0);
    },

    showImportButton() {
      return !this.isGameSlateLineup && !this.isLateSwap;
    },

    isLateSwap() {
      return this.isEditing && !SalaryCapGameUtils.isPreContest(this.contest);
    },
  },

  watch: {
    editLineup(to, from) {
      EventBus.$emit('SALARY_CAP_SELECT_LINEUP', to);
    },
  },

  methods: {
    allowClick(player) {
      if (this.isLateSwap && !player.canSwap) {
        return false;
      }
      return player.canSwap;
    },

    setSelectedLineup(teamNum) {
      this.editLineup = teamNum;
    },

    selectNumLineups(numLineups) {
      if (!this.canAffordAmountCents(this.numLineupsPriceCents(numLineups))) {
        return;
      }

      this.$emit('setNumLineups', numLineups);
      EventBus.$emit('CLOSE_DROPDOWNS');
    },

    canSubmitAmount(amt) {
      const canAfford = this.canAffordAmountCents(this.numLineupsPriceCents(amt));
      const canSubmit = amt <= this.maxPossibleEntries;
      return canAfford && canSubmit;
    },

    canAffordAmountCents(amt) {
      if (this.contest.realMoney) {
        return this.balanceValue >= amt;
      }

      return this.ownersbucksValue >= amt;
    },

    numLineupsPriceCents(numLineups) {
      return this.contest.feeValue * numLineups;
    },

    rosterAtPos(pos) {
      return LineupsUtils.rosterAtPos(this.roster, pos);
    },

    rosterRemainingPicks(pos) {
      // Added check to make old contests viewable
      if (!this.rosterAtPos(pos)) {
        return 0;
      }
      return this.rosterAtPos(pos).num - this.picks[pos].length;
    },

    resetRoster() {
      EventBus.$emit('SALARY_CAP_CLEAR_ROSTER');
    },

    openImportLineups() {
      EventBus.$emit('OPEN_SALARY_CAP_IMPORT_MODAL', this.contest, this.playerList);
    },

    openSubmitConfirmModal() {
      const params = {
        picks: this.picks,
        contest: this.contest,
        totSpent: this.totSpent,
        statusMap: this.statusMap,
        lineupName: this.lineupName.name,
        editMode: this.isEditing,
        promoInfo: this.promoInfo,
        entryCount: this.numLineups,
      };
      EventBus.$emit('OPEN_SALARY_CAP_CONFIRM_MODAL', params);
    },

    submitChanges() {
      if (this.isEditing) {
        this.editGameSlateLineup();
      } else {
        this.createGameSlateLineup();
      }
    },

    editGameSlateLineup() {
      EventBus.$emit('SALARY_CAP_EDIT_LINEUP');
    },

    createGameSlateLineup() {
      EventBus.$emit('SALARY_CAP_CREATE_LINEUP');
    },

    dollarFormat(dollarAmount) {
      if (!dollarAmount) {
        return '$0';
      }
      const d = Math.round(dollarAmount);
      return '$' + Intl.NumberFormat('en-US').format(d);
    },
  },

};
</script>

<style lang="scss" scoped>

.rosterBox {
  display: flex;
  flex-direction: column;
  min-height: 0;
  flex: 1;
}

.headerContainer {
  height: 45px;
  min-height: 45px;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerLabel {
  color: var(--obcolor-text-secondary);
  font-weight: normal;
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: -2px;
}

.moneyText {
  color: var(--obcolor-green);
}
.blueText {
  color: var(--obcolor-ob-blue);
}

.playerList {
  max-height: 100%;
  overflow-y: auto;
}

.playersTable {
  width: 100%;

  td, th {
    min-width: 20px;
  }
  th {
    font-weight: bold;
    font-size: 14px;
    background: var(--obcolor-background-2);
    height: 40px;
    position: sticky;
    top: 0;
    z-index: 60;
  }
}

.submitContainer {
  height: 50px;
  min-height: 50px;
  padding: 5px 15px 0px 15px;
  display: flex;
  border-top: 1px solid var(--obcolor-background-2);
  gap: 10px;
  align-items: center;
  > div {
    min-width: 85px;
    text-align: center;
  }
}

.submitButtonContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  .submitBtn:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.submitBtn {
  width: 150px;
}

.dropdownBtn {
  width: 20px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

::v-deep {
  .numLineupsDropdown .settingsDropdown {
    margin-top: -70px;
  }
}

.dropdownOption {
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  &:not(:hover), &.disabled {
    background: var(--obcolor-background-4) !important;
  }

  &.disabled {
    cursor: not-allowed;

    > div {
      color: var(--obcolor-font-secondary);
    }
  }

  div:first-child {
    text-align: left;
  }

  div:last-child {
    text-align: right;
    margin-left: 10px;
  }

  div {
    display: flex;
    align-items: center;

    .ownersbucksIcon {
      height: 10px;
      margin-right: 5px;
    }
  }

  &:not(.disabled) div {
    &:first-child {
      color: var(--obcolor-font-primary);
    }
  }
}

</style>