<template>
  <div>
    <div class="componentTitle" style="display: flex;">
      <div style="flex: 1;">Prizes</div>
      <div v-if="league.ctype === 'Tournament'" style="color: var(--obcolor-font-primary);">
        {{ getNumWinners() }}
      </div>
    </div>

    <!-- Salary Cap Contest -->
    <div v-if="league.ctype === 'Tournament'">
      <div v-if="league.payouts">
        <div v-for="(payout, index) in league.payouts" :key="'payout'+index" class="scPayout">
          <div style="flex: 1;">{{ payout.label }}</div>
          <template v-if="payout.ticket">
            <img :src="require('@/assets/icons/ticket.png')" class="ticketIcon" alt="Contest Ticket">
            <div>{{ payout.ticket.name }}</div>
            <div v-if="payout.prizeValue != 0">+</div>
          </template>
          <div v-if="payout.prizeValue != 0" :class="league.realMoney == true ? 'moneyText' : 'obText'">
            {{ payout.prize.removeCentsIfZero() }}
          </div>
          <div v-if="payout.prizeValue == 0 && !payout.ticket">
            No Prizes
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    league: Object,
  },

  methods: {
    getNumWinners() {
      let total = 0;
      for (const entry of this.league.payouts) {
        total += entry.payouts;
      }
      const pct = (total / this.league.playersMax * 100).toFixed(0);
      return total + ' Winners (' + pct + '%)';
    },
  },
};
</script>

<style lang="scss" scoped>

.moneyText, .obText {
  font-weight: bold;
}

.componentTitle {
  font-size: 14px;
  color: var(--obcolor-font-secondary);
  padding: 10px 15px;
  background: var(--obcolor-background-4);
  width: 100%;
  font-weight: bold;
  box-sizing: border-box;
}

.componentContent {
  padding: 10px 15px;
  display: flex;
  justify-content: center;
}

.scPayout {
  padding: 10px 20px;
  display: flex;
  font-size: 14px;
  gap: 10px;
  align-items: center;
}
.scPayout:nth-child(odd) {
  background: var(--obcolor-background-5);
}

.ticketIcon {
  height: 13px;
  margin-top: -2px;
}

.winner {
  text-align: center;
  flex: 1;
}

.winnerTitle {
  font-size: 12px;
  font-weight: bold;
  color: var(--obcolor-font-secondary);
}

.winnerAmount {
  font-size: 16px;
  color: var(--obcolor-font-primary);
  margin-top: 5px;
}

.ownersbucksIcon {
  height: 16px;
  margin-right: 3px;
  margin-bottom: -3px;
}
</style>