<template>
  <div class="contestBox" :class="{selectedContest: inputVal > 0}">
    <div class="contestTitleSection">
      <img class="sportIcon" :src="require('@/assets/contestbadge/' + contest.sport + '.png')" :alt="contest.sport">
      <div class="titleContainer">
        <div class="contestTitle">
          <div>{{ truncateTitle(contest.contestName, this.characterLimit) }}</div>
          <div v-if="contest.groupContestsRemaining" style="margin-right: 6px;">(x{{ contest.groupContestsRemaining }})</div>
        </div>
        <!-- Contest icons -->
        <ContestIcons :contest="contest" hide-guaranteed />
      </div>

      <div class="lineupSelector">
        <input type="number" v-model="inputVal">
        <div class="contestCounter">
          <i class="fas fa-chevron-up" @click="addEntry(contest)"></i>
          <i class="fas fa-chevron-down" @click="subtractEntry(contest)"></i>
        </div>
        <p @click="maxEntry(contest)">Max</p>
      </div>
    </div>

    <div class="contestInfoSection">
      <!-- Graph container -->
      <div>
        <div class="infoField">
          <ContestFillGraph :playersCurrent="contest.playersCurrent" :playersMax="contest.playersMax"
            :format="contest.ctype == 'Tournament' ? 'Tournament' : 'H2H'"
          />
        </div>
      </div>
      <!-- Entry fee -->
      <div>
        <div class="infoField">
          <span>
            <img v-if="!contest.realMoney"
              :src="require('@/assets/icons/ownersbucks.png')"
              class="ownersbucksIcon" alt="OwnersBucks"
            >
            {{ contest.fee.removeCentsIfZero() }}
          </span>
          <span>(Max {{ contest.entriesPerUser }})</span>
        </div>
        <div class="titleField">Entry Fee</div>
      </div>
      <!-- Prize pool -->
      <div>
        <div class="infoField">
          <img v-if="contest.isGuaranteed && contest.realMoney" class="contestIcon"
            :src="require('@/assets/contesticons/Guaranteed.svg')"
            alt="Guaranteed"
          >
          <img v-if="contest.isGuaranteed && !contest.realMoney" class="contestIcon"
            :src="require('@/assets/contesticons/GuaranteedBlue.svg')"
            alt="Guaranteed"
          >
          <span :class="contest.realMoney ? 'moneyText' : 'blueText'">
            <img v-if="!contest.realMoney"
              :src="require('@/assets/icons/ownersbucks.png')"
              class="ownersbucksIcon" alt="OwnersBucks"
            >
            {{ contest.prizePool.removeCentsIfZero() }}
          </span>
        </div>
        <div class="titleField">Prize Pool</div>
      </div>
    </div>
  </div>
</template>

<script>
import ContestFillGraph from '@/views/Lobby/ContestFillGraph.vue';
import ContestIcons from '@/components/Contests/ContestIcons.vue';
import EventBus from '@/event-bus';

export default {
  props: {
    contest: {type: Object, default: null},
    entries: {type: Number, default: null},
    characterLimit: {type: Number, default: 15},
  },

  components: {
    ContestFillGraph,
    ContestIcons,
  },

  computed: {
    maxPossibleEntries() {
      return Math.min(this.contest.myEntriesRemaining, this.contest.entriesRemaining);
    },
  },

  created() {
    this.inputVal = this.entries;
  },

  data() {
    return {
      // Value to be watched for input, to work around for inputs changing despite validation
      inputVal: null,
    };
  },

  watch: {
    inputVal(newVal) {
      if (newVal === null || newVal === '') {
        this.setEntries(null);
        return;
      }

      if (newVal >= 0 && newVal <= Math.min(this.contest.myEntriesRemaining, this.contest.entriesRemaining)) {
        this.setEntries(parseInt(newVal));
        return;
      }

      this.inputVal = this.entries;
    },

    entries(newVal) {
      this.inputVal = newVal;
    },
  },

  methods: {
    setEntries(newVal) {
      EventBus.$emit('QUICK_ENTRY_CHANGE_ENTRY_VAL', this.contest.id, newVal);
    },

    maxEntry(contest) {
      this.setEntries(Math.min(contest.myEntriesRemaining, contest.entriesRemaining));
    },

    addEntry() {
      if (this.entries < this.maxPossibleEntries) {
        this.setEntries(this.entries + 1);
      }
    },

    subtractEntry() {
      if (this.entries > 0) {
        this.setEntries(this.entries - 1);
      }
    },

    truncateTitle(title, maxLength) {
      const words = title.split(' ');
      const lastWord = words[words.length - 1];
      if (lastWord.length > maxLength) {
        words[words.length - 1] = lastWord.slice(0, maxLength) + '...';
      }
      title = words.join(' ');
      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.contestBox {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  background: var(--obcolor-background-4);
  border: 1px solid var(--obcolor-background-4);
  // Text not selectable
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.selectedContest {
    border: 1px solid var(--obcolor-ob-blue);
    background: var(--obcolor-background-3);
  }
}

.contestTitleSection {
  display: flex;
  align-items: center;

  .sportIcon {
    height: 45px;
  }

  .titleContainer {
    margin-left: 8px;
    flex: 1;
    .contestTitle {
      font-size: 14px;
      font-weight: bold;
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .lineupSelector {
    cursor: pointer;
    border: 1px solid var(--obcolor-font-secondary);
    height: 28px;
    min-width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .contestCounter {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 23px;

      .fa-chevron-up,
      .fa-chevron-down {
        width: 23px;
        align-items: center;
        display: flex;
        justify-content: center;
        transition: all 0.2s;
        height: 11px;
        font-size: 10px;
        border-left: 1px solid var(--obcolor-font-secondary);

        &:hover {
          background: var(--btn-grey-light);
        }
      }

      .fa-chevron-up {
        border-bottom: 1px solid var(--obcolor-font-secondary);
        padding-bottom: 2px;
      }

      .fa-chevron-down {
        padding-top: 2px;
        height: 12px;
      }
    }

    p {
      margin: 0;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 29px;
      border-left: 1px solid var(--obcolor-font-secondary);
      padding: 0 5px;
      background: var(--btn-grey-light);

      &:hover {
        background: none;
      }
    }

    input {
      width: 25px;
      padding: 0 10px;
      background: none;
      border: none;
      text-align: center;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.contestInfoSection {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  ::v-deep {
    .fullText {
      margin: 0 0 0 5px;
      order: 1;
      font-weight: 400;
      font-size: 14px;
    }
    .progressBar {
      margin-left: 0;
      width: 70px;
    }
    .progressBarContainer {
      display: flex;
      margin-top: 1px;
      align-items: center;
    }
    .graphContainer {
      margin-left: 5px;
      align-items: flex-start;
    }
    .graphText {
      font-weight: 400;
    }
  }

  .infoField {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 90px;
    overflow: visible;
    white-space: nowrap;

    span:not(:first-child) {
      margin-left: 3px;
    }
  }

  .titleField {
    font-size: 14px;
    color: var(--obcolor-font-secondary);
  }
}

::v-deep {
  .iconContainer {
    margin-top: 3px;

    img {
      height: 16px;
      width: 16px;
    }
  }
}

.contestIcon {
  height: 16px;
  margin-right: 2px;
}

.moneyText {
  color: var(--obcolor-green);
}

.blueText {
  color: var(--obcolor-ob-blue);
}

.ownersbucksIcon {
  height: 10px;
  margin-left: 2px;
  margin-right: 2px;
}
</style>