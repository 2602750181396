<template>
  <div class="ob-page">
    <!-- Loading or Invalid Slate -->
    <ObLoading v-if="loadingSlate" />
    <div v-else-if="!gameSlate">
      <div class="ob-box invalidSlateContainer">
        <div>The selected {{ isEdit ? 'lineup' : 'game slate' }} is invalid</div>
        <button class="ob-btn-grey" @click="goToLineupsPage()">Back to Lineups Page</button>
      </div>
    </div>

    <div v-else class="boxContainer">
      <!-- Left Column: Header, game slate, player list -->
      <div class="playersContainer">
        <GameSlateLineupHeader :gameSlate="gameSlate" :isEdit="isEdit" :lineupInfo="lineupInfo" />
        <SalaryCapGameSlate v-if="contestSlate"
          :contest="gameSlate" :contestSlate="contestSlate"
          v-model="selectedTeams"
        />
        <SalaryCapPlayerList :isGameSlateLineup="true"
          :playerList="playerList" :contest="gameSlate"
          :picks="picks" :pickedPlayerIds="pickedPlayerIds"
          :totSpent="totSpent" :statusMap="statusMap"
          :editingGameSlateLineup="isEdit"
          :selectedTeams="selectedTeams"
        />
      </div>
      <!-- Right Column: Selected Roster -->
      <div class="rosterContainer">
        <SalaryCapRoster :isGameSlateLineup="true"
          :picks="picks" :totSpent="totSpent"
          :contest="gameSlate" :selectedPlayer="selectedPlayer"
          :statusMap="statusMap" :lineupName="null"
          :submittedLineups="{}" :playerList="playerList"
          :numLineups="1" :editingGameSlateLineup="isEdit"
          :rosterHasChanged="rosterHasChanged"
        />
      </div>
    </div>

    <!-- Confirm Edit Modal -->
    <Modal v-if="confirmEditOpen" v-model="confirmEditOpen" :disableClickaway="true"
      :containerStyle="'width: 400px; min-height: 120px; padding: 15px;'"
    >
      <div slot="header" class="header modalTitle">Confirm Changes</div>
      <div slot="body" class="confirmEditBody">
        <div>Are you sure you want to edit this lineup?</div>
        <div>{{ confirmEditMessage }}</div>
        <div class="btnFlexbox">
          <button class="ob-btn-grey" @click="confirmEditOpen = false">Cancel</button>
          <button class="ob-btn-grey" @click="editLineup()">Edit Lineup</button>
        </div>
      </div>
    </Modal>

    <!-- Submit Modal -->
    <Modal v-if="submitModalOpen" v-model="submitModalOpen"
      :disableClickaway="true" :hideCloseButton="isLoading"
      :containerStyle="'width: ' + modalWidth + '; min-height: 120px; padding: 15px;'"
    >
      <div v-if="!isLoading" slot="header" class="header">
        <template v-if="submitLineupError">
          <div class="submitErrorTitle">Failed to {{ isEdit ? 'Edit' : 'Create' }}{{ swapErrorsList.length > 0 ? ' Some' : '' }} Lineup{{ swapErrorsList.length > 0 ? 's' : '' }}</div>
        </template>
        <template v-else>
          <div v-if="isEdit" class="modalTitle">Changes Saved Successfully</div>
          <div v-else class="modalTitle">Lineup Successfully Created</div>
        </template>
      </div>
      <div slot="body" class="submitModalBody">
        <div v-if="isLoading">
          <ObLoading />
          <div class="savingLineup">Saving {{ isEdit ? 'Changes' : 'Lineup' }}...</div>
        </div>
        <div v-else-if="submitLineupError">
          <div class="submitErrorMsg">
            <p>{{ submitLineupError }}</p>
            <p v-for="(errorMsg, index) in swapErrorsList" :key="index">{{ errorMsg }}</p>
          </div>
          <button class="ob-btn-grey" @click="submitModalOpen = false">Close</button>
        </div>
        <div v-else>
          <div v-if="isEdit && numSuccess" class="numSuccess">
            <template v-if="numSuccess > 1">{{ numSuccess }} entries have been updated.</template>
            <template v-else>{{ numSuccess }} entry has been updated.</template>
          </div>
          <div class="btnFlexbox">
            <button v-if="!isEdit" class="ob-btn-grey" @click="submitModalOpen = false">Create More Lineups</button>
            <button v-else class="ob-btn-grey" @click="submitModalOpen = false">Continue Editing</button>
            <button class="ob-btn-grey" @click="goToLineupsPage()">View Lineups</button>
          </div>
        </div>
      </div>
    </Modal>

    <LineupQuickEntryModal />
    <QuickEntrySuccessModal footerButton="lineups" />
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import ObPlayersApi from '@/api/ObPlayersApi';

import Modal from '@/components/Modal';
import ObLoading from '@/components/ObLoading';
import GameSlateLineupHeader from '@/views/SalaryCapLineups/GameSlateLineupHeader';
import SalaryCapGameSlate from '@/views/SalaryCapGame/SalaryCapGameSlate';
import SalaryCapPlayerList from '@/views/SalaryCapGame/SalaryCapPlayerList';
import SalaryCapRoster from '@/views/SalaryCapGame/SalaryCapRoster';
import LineupQuickEntryModal from './LineupQuickEntryModal/LineupQuickEntryModal.vue';
import QuickEntrySuccessModal from '@/views/SalaryCapGame/QuickEntryModal/QuickEntrySuccessModal.vue';
import LineupsUtils from '@/utils/shared/LineupsUtils';

export default {

  components: {
    Modal,
    ObLoading,
    GameSlateLineupHeader,
    SalaryCapGameSlate,
    SalaryCapPlayerList,
    SalaryCapRoster,
    LineupQuickEntryModal,
    QuickEntrySuccessModal,
  },

  data() {
    return {
      loadingSlate: false,
      slateId: null,
      playerList: null,
      picks: {},
      pickedPlayerIds: {},
      selectedPlayer: null,
      gameSlate: null,
      contestSlate: null,
      statusMap: null,
      rosterCookieKey: 'salary-cap-picked-players',
      selectedTeams: {},
      isEdit: null,
      lineupId: null,
      lineupInfo: null,
      initialPicks: [],

      confirmEditOpen: false,
      submitModalOpen: false,
      savingLineup: false,
      loadingSwapResults: false,
      submitLineupError: null,

      swapLoadDelay: 2000,
      swapId: null,
      swapData: null,
      swapLoadProgress: null,
    };
  },

  watch: {
    '$route.params.slateId'(to) {
      if (this.slateId !== to) {
        this.slateId = to;
        this.loadGameSlate();
      }
    },
  },

  created() {
    this.slateId = this.$route.params.slateId;
    if (this.$route.params.lineupId) {
      this.lineupId = this.$route.params.lineupId;
      this.isEdit = true;
    }
    this.loadGameSlate();

    EventBus.$on('SALARY_CAP_CREATE_LINEUP', this.submitLineup);
    EventBus.$on('SALARY_CAP_EDIT_LINEUP', this.confirmEditLineup);
    EventBus.$on('SALARY_CAP_CLEAR_ROSTER', this.resetRoster);
    EventBus.$on('SALARY_CAP_PICK_PLAYER', this.pickPlayer);
    EventBus.$on('SALARY_CAP_REMOVE_PLAYER', this.removePlayer);
  },

  destroyed() {
    EventBus.$off('SALARY_CAP_CREATE_LINEUP', this.submitLineup);
    EventBus.$off('SALARY_CAP_EDIT_LINEUP', this.confirmEditLineup);
    EventBus.$off('SALARY_CAP_CLEAR_ROSTER', this.resetRoster);
    EventBus.$off('SALARY_CAP_PICK_PLAYER', this.pickPlayer);
    EventBus.$off('SALARY_CAP_REMOVE_PLAYER', this.removePlayer);
  },

  computed: {
    roster() {
      return this.gameSlate?.draftRoster?.roster;
    },

    totSpent() {
      return LineupsUtils.totSpent(this.roster, this.picks);
    },

    isLoading() {
      return this.savingLineup || this.loadingSwapResults;
    },

    numSuccess() {
      if (!this.swapData) {
        return 0;
      }

      let tot = 0;
      for (const contest of this.swapData.contests) {
        for (const swap of contest.swaps) {
          if (swap.success) {
            tot++;
          }
        }
      }
      return tot;
    },

    modalWidth() {
      if (this.swapErrorsExist) {
        return '750px';
      }
      return '400px';
    },

    swapErrorsList() {
      if (!this.swapData) {
        return [];
      }
      const swapErrorMessage = [];
      for (const contest of this.swapData.contests) {
        for (const swap of contest.swaps) {
          if (!swap.success) {
            const entryNum = this.getTeamNumFromId(swap.teamId);
            swapErrorMessage.push(`\n${contest.contest.contestName} - Entry ${entryNum}: ${swap.error}`);
          }
        }
      }
      return swapErrorMessage;
    },

    confirmEditMessage() {
      let message = '';
      if (this.lineupInfo?.numEntries > 0) {
        const numEntries = this.lineupInfo.numEntries;
        const numContests = this.lineupInfo.numContests;
        if (numEntries == 1) {
          message = '1 contest entry will be modified';
        } else {
          message += ' ' + numEntries;
          message += numEntries > 1 ? ' entries' : ' entry';
          message += ' from ' + numContests;
          message += numContests > 1 ? ' contests' : ' contest';
          message += ' will be modified';
        }
      }
      return message;
    },

    swapErrorsExist() {
      return this.swapErrorsList.length > 0;
    },

    rosterHasChanged() {
      return LineupsUtils.rosterHasChanged(this.initialPicks, this.roster, this.picks);
    },
  },

  methods: {
    loadGameSlate() {
      if (this.isEdit) {
        this.loadEditGameSlate(true);
      } else {
        this.loadCreateGameSlate();
      }
    },

    loadCreateGameSlate() {
      // Get game slate info and player list
      this.loadingSlate = true;
      ObSalaryCapApi.getGameSlatePlayers(this.slateId)
          .then((response) => {
            this.gameSlate = response.gameSlate;
            this.contestSlate = response.gameSlate.gameSlateData;
            this.playerList = response.players;
            this.selectedPlayer = this.playerList[0];
            this.initializePicks();
            this.loadStatuses();
            this.loadingSlate = false;
          }).catch((_error) => {
            this.resetData();
            this.loadingSlate = false;
          });
    },

    loadEditGameSlate(showLoading = true) {
      // Show loading dial during first load, don't show when refreshing data after an edit
      if (showLoading) {
        this.loadingSlate = true;
      }
      // Get game slate info and player list
      ObSalaryCapApi.getEditLineupData(this.lineupId)
          .then((response) => {
            this.gameSlate = response.gameSlate;
            this.contestSlate = response.gameSlate.gameSlateData;
            this.playerList = response.players;
            this.selectedPlayer = this.playerList[0];
            this.lineupInfo = response.lineupInfo;
            this.initializePicks(response.roster);
            this.loadStatuses();
            this.loadingSlate = false;
          }).catch((_error) => {
            this.resetData();
            this.loadingSlate = false;
          });
    },

    getTeamNumFromId(teamId) {
      if (!teamId) {
        return '';
      }
      const parts = teamId.split('_');
      if (!parts[1]) {
        return '';
      }
      return parts[1];
    },

    pickPlayerInSlot(playerId, slotName) {
      const isOpen = this.draftSpotIsOpen(slotName);
      if (!isOpen) {
        return;
      }
      const player = this.playerList.find((p) => p.id == playerId);
      this.$set(this.pickedPlayerIds, player.id, true);
      player.rosterSlotTaken = slotName;
      this.picks[slotName].push(player);
    },

    resetData() {
      this.gameSlate = null;
      this.contestSlate = null;
      this.playerList = null;
      this.selectedPlayer = null;
    },

    confirmEditLineup() {
      this.confirmEditOpen = true;
    },

    editLineup() {
      this.confirmEditOpen = false;
      this.savingLineup = true;
      this.submitModalOpen = true;
      let players = [];
      for (const posInfo of this.roster) {
        if (this.picks?.[posInfo.short]) {
          players = players.concat(this.picks[posInfo.short].map((p) => {
            return {id: p.id, position: posInfo.short};
          }));
        }
      }

      ObSalaryCapApi.editSalaryCapLineup(this.lineupId, players)
          .then((response) => {
            this.submitLineupError = null;
            if (response.swapId) {
              this.swapId = response.swapId;
              this.loadingSwapResults = true;
              this.loadGlobalSwapResults(this.swapLoadDelay);
            } else {
              // If there's no global-swap to process, reload lineup data
              // If there is a global-swap, data will reload after processing
              this.loadEditGameSlate(false);
            }
            this.savingLineup = false;
          }).catch((error) => {
            const errorMsg = error?.response?.data;
            this.submitLineupError = errorMsg || 'Could not save lineup, please try again';
            this.savingLineup = false;
          });
    },

    // Loading global swap results is used both for validation, and submission
    loadGlobalSwapResults(delayMs) {
      setTimeout(() => {
        // If swapId is null, stop loading
        // This will happen if the modal is closed while data is still processing
        if (!this.swapId) {
          return;
        }
        // Continue to load swap results until a response is given, or we hit an error
        ObSalaryCapApi.getGlobalSwapResults(this.swapId)
            .then((response) => {
              this.processSwapResults(response);
            }).catch((error) => {
              this.processSwapResultsError(error);
            });
      }, delayMs);
    },

    processSwapResults(response) {
      // Check if processing is complete, if not update loading bar progress
      if (response && response.status === 'processed') {
        this.swapData = response;
        if (this.swapErrorsExist) {
          this.submitLineupError = 'Errors exist. Some lineups were not edited:';
        }
        // Reload lineup after editing
        this.loadEditGameSlate(false);
        this.loadingSwapResults = false;
      } else {
        // Data is still processing, try again in a few seconds
        if (response && response.progress) {
          this.swapLoadProgress = response.progress;
        }
        this.loadGlobalSwapResults(this.swapLoadDelay);
      }
    },

    processSwapResultsError(error) {
      // Display error message from server, or a generic error if none is provided
      if (error && error.response && error.response.data) {
        this.submitLineupError = error.response.data;
      } else {
        this.submitLineupError = 'Could not process edit, please try again.';
      }
    },

    submitLineup() {
      this.savingLineup = true;
      this.submitModalOpen = true;
      let players = [];
      for (const posInfo of this.roster) {
        if (this.picks?.[posInfo.short]) {
          players = players.concat(this.picks[posInfo.short].map((p) => {
            return p.id;
          }));
        }
      }
      const params = {players: players};
      const sport = this.gameSlate?.sport;
      const slate = this.slateId;
      ObSalaryCapApi.createLineup(this.slateId, params)
          .then((response) => {
            this.submitLineupError = null;
            this.savingLineup = false;

            // Safeguard since player data is required by the quick entry modal
            // If player data is missing, the generic 'Lineup Created' message will show instead
            if (response.players) {
              // Open quick entry modal to enter lineup in contests
              this.submitModalOpen = false;
              EventBus.$emit('OPEN_LINEUP_QUICK_ENTRY', sport, response.players, slate);
            }
          }).catch((error) => {
            const errorMsg = error?.response?.data;
            this.submitLineupError = errorMsg || 'Could not save lineup, please try again';
            this.savingLineup = false;
          });
    },

    initializePicks(editRoster = null) {
      const picks = {};
      if (!this.roster) {
        this.picks = {};
      }
      for (const posInfo of this.roster) {
        this.$set(picks, posInfo.short, []);
      }
      this.picks = picks;

      if (editRoster) {
        this.selectEditPicks(editRoster);
      }
    },

    selectEditPicks(roster) {
      for (const posInfo of roster) {
        this.pickPlayerInSlot(posInfo.id, posInfo.position);
      }
      this.initialPicks = roster;
    },

    resetRoster() {
      this.initializePicks();
      this.pickedPlayerIds = {};
    },

    loadStatuses() {
      ObPlayersApi.getGlobalStatusMap(this.gameSlate.sport)
          .then((data) => {
            this.statusMap = data;
          })
          .catch((_error) => {
            // Do nothing
          });
    },

    hasValidRosterSlot(player) {
      return LineupsUtils.hasValidRosterSlot(player, this.gameSlate, this.roster, this.picks, this.pickedPlayerIds);
    },

    draftSpotIsOpen(draftPos) {
      return LineupsUtils.draftSpotIsOpen(this.roster, this.picks, draftPos);
    },

    rosterAtPos(position) {
      return LineupsUtils.rosterAtPos(this.roster, position);
    },

    pickPlayer(player) {
      const rosterSlot = this.hasValidRosterSlot(player);
      if (rosterSlot == null) {
        return;
      }
      this.$set(this.pickedPlayerIds, player.id, true);
      player.rosterSlotTaken = rosterSlot;
      this.picks[rosterSlot].push(player);
    },

    removePlayer(player) {
      const rosterSlot = player.rosterSlotTaken;
      if (!this.picks || !this.pickedPlayerIds || !player.id || !this.picks[rosterSlot]) {
        return;
      }

      this.$delete(this.pickedPlayerIds, player.id);
      this.picks[rosterSlot] = this.picks[rosterSlot].filter((p) => {
        return p.id != player.id;
      });
    },

    goToLineupsPage() {
      this.$router.push('/salarycap/lineups');
    },
  },
};
</script>

<style lang="scss" scoped>

.invalidSlateContainer {
  text-align: center;
  padding: 25px;
  .ob-btn-grey {
    margin-top: 15px;
    font-size: 14px;
  }
}

.ob-page {
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  transition: height ease-out 0.3s;
  padding-bottom: 0;
}

.headerBoxHeight {
  box-sizing: border-box;
  height: 110px;
  min-height: 110px;
  max-height: 110px;
}

.boxContainer {
  flex: 1;
  min-height: 0;
  display: flex;
  align-items: flex-start;
}

.playersContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 940px;
  max-height: 100%;
}

.rosterContainer {
  width: 490px;
  min-width: 490px;
  margin-bottom: 45px;
  transition: height ease-out 0.3s;
  margin-left: 10px;
  position: sticky;
  top: calc(15px + var(--ob-nav-offset));
  display: flex;
  flex-direction: column;
  padding: 0;
  max-height: calc(100% - 45px);
}

.submitModalBody {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  > div {
    width: 100%;
  }
  .ob-btn-grey {
    font-size: 14px;
  }
}

.confirmEditBody {
  text-align: center;
  > div:first-child {
    margin-bottom: 5px;
  }
  .btnFlexbox {
    padding-top: 15px;
  }
}

.btnFlexbox {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-top: 10px;

  .ob-btn-grey {
    font-size: 14px;
  }
}

.savingLineup {
  font-weight: bold;
  margin-bottom: 10px
}

.header {
  height: 30px;
  text-align: center;
}
.modalTitle, .submitErrorTitle {
  font-weight: bold;
}
.submitErrorTitle {
  color: var(--obcolor-red);
}
.submitErrorMsg {
  padding: 0 15px 15px 15px;
  max-height: 200px;
  width: 100%;
  overflow: auto;
  box-sizing: border-box;
  p {
    margin: 2px;
  }
  p:not(:first-child) {
    margin: 5px 0;
  }
  p:not(:last-child) {
    margin: 5px 0;
  }
}

.numSuccess {
  margin: 5px 0;
}
</style>