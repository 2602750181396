<template>
  <div>
    <table class="headerTable" aria-label="League Summary">
      <tr class="summaryRow">
        <td class="sportImgCol">
          <img :src="require('@/assets/icons/sporticon_' + league.sport + '.png')" :alt="league.sport"
            style="width: 26px;"
          >
        </td>
        <td class="graphCol" style="width: 220px; text-align: left;">
          <div class="graphColContent">
            <div class="contestInfo">
              <div class="contestName" :title="getContestName()">{{ getContestName() }}</div>
              <div v-if="league.groupContestsRemaining">(x{{ league.groupContestsRemaining }})</div>
            </div>
            <div class="summaryValue" style="margin-top: 6px; margin-bottom: -6px; font-size: 12px;">
              <img v-if="!league.realMoney" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
                class="ownersbucksIcon"
                style="margin-right: 5px; height: 11px; margin-top: -1px;"
              >
              <SalaryContestPrizePool :contest="league" :class="{moneyText: league.realMoney, obText: !league.realMoney}" />
              <div @click="goToPrizes()" class="ob-hyperlink" style="margin-left: 5px; font-weight: normal">PRIZES</div>
            </div>
          </div>
        </td>
        <td class="infoCol" style="width: 120px;">
          <div class="summaryContainer">
            <div class="summaryValue">
              <div class="fieldValue" style="justify-content: center;" v-if="league.contestStart && $moment().diff(league.contestStart) > 0">
                Started
              </div>
              <template v-else-if="league.contestStart">
                <LobbyContestCountdown :end="league.contestStart" :serverOffset="0" />
              </template>
              <template v-else-if="league.draftTime">
                <LobbyContestCountdown :end="league.draftTime" :serverOffset="0" />
              </template>
              <template v-else-if="getContestCutoff(league, true)">
                <LobbyContestCountdown :end="getContestCutoff(league, true)" :end2="getContestCutoff(league, false)" :serverOffset="0" />
              </template>
              <div v-else>N/A</div>
            </div>
            <div class="summaryTitle">
              CUTOFF
              <Tooltip pos="bottom" :textKey="getCutoffTooltipKey()" />
            </div>
          </div>
        </td>
        <td class="infoCol">
          <div class="summaryContainer">
            <div class="summaryValue">
              <img v-if="!league.realMoney" :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon"
                class="ownersbucksIcon"
              >
              <div :class="{moneyText: league.realMoney, obText: !league.realMoney}">{{ league.fee.removeCentsIfZero() }}</div>
            </div>
            <div class="summaryTitle">ENTRY</div>
          </div>
        </td>
      </tr>
      <tr class="summaryRow">
        <td></td>
        <td class="graphCol">
          <div class="graphColContent" :style="'padding-left: ' + (league.ctype === 'Tournament' ? '16px' : '8px;')">
            <ContestFillGraph style="max-width: 170px; font-size: 14px;"
              :style="league.ctype === 'Tournament' ? 'min-width: 130px;' : 'width: max-content;'"
              :playersCurrent="league.playersCurrent"
              :playersMax="league.playersMax"
              :format="league.ctype === 'Tournament' ? 'Tournament' : 'H2H'"
            />
          </div>
        </td>
        <td class="infoCol">
          <div class="summaryContainer">
            <template v-if="league.ctype === 'Tournament'">
              <div v-if="league.contestStart">{{ $moment(league.contestStart).format("MMM D, h:mma") }}</div>
              <div v-else-if="league.scheduledStartDate">
                {{ getContestStartDate(league.scheduledStartDate) }}
              </div>
              <div class="summaryTitle">
                CONTEST START
              </div>
            </template>
            <template v-else>
              <div v-if="league.draftStart === 'Scheduled' && league.draftTime" class="summaryValue">
                <TimeConditionalDisplay :end="league.draftTime - 3600000"
                  style="margin-right: 5px;"
                  :altStyle="'color: var(--obcolor-ob-blue); font-weight: bold;'"
                  :text="getDraftStartDate(league.draftTime)" :altText="getDraftStartDate(league.draftTime)" :serverOffset="0"
                />
                <TimeConditionalDisplay :end="league.draftTime - 3600000"
                  :altStyle="'color: var(--obcolor-ob-blue); font-weight: bold;'"
                  :text="getDraftStartTime(league.draftTime)" :altText="getDraftStartTime(league.draftTime)" :serverOffset="0"
                />
              </div>
              <div v-else class="summaryValue">
                <div>{{ league.draftStart }}</div>
              </div>
              <div class="summaryTitle">
                DRAFT
                <Tooltip pos="bottom" textKey="LeagueModal_H2H_WhenFilled" />
              </div>
            </template>
          </div>
        </td>
        <td class="infoCol" v-if="league.realMoney">
          <div class="summaryContainer">
            <div class="summaryValue">{{ accountBalance.removeCentsIfZero() || "-" }}</div>
            <div class="summaryTitle">BALANCE</div>
          </div>
        </td>
        <td class="infoCol" v-else>
          <div class="summaryContainer">
            <div class="summaryValue">
              <img :src="require('@/assets/icons/ownersbucks.png')" alt="OwnersBucks Icon" class="ownersbucksIcon">
              {{ ownersbucks.removeCentsIfZero() || "-" }}
            </div>
            <div class="summaryTitle">OWNERSBUCKS</div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import SalaryContestPrizePool from '@/views/SalaryContest/SalaryContestPrizePool.vue';
import {mapState} from 'vuex';
import ContestFillGraph from '@/views/Lobby/ContestFillGraph';
import TimeConditionalDisplay from '@/components/timers/TimeConditionalDisplay.vue';
import LobbyContestCountdown from '@/views/Lobby/LobbyContestCountdown.vue';
import EventBus from '@/event-bus';
import Tooltip from '@/components/tooltip/Tooltip';

export default {
  components: {
    ContestFillGraph,
    LobbyContestCountdown,
    TimeConditionalDisplay,
    Tooltip,
    SalaryContestPrizePool,
  },

  props: {
    league: {type: Object, default: null},
  },

  computed: {
    ...mapState(['accountBalance', 'ownersbucks', 'contestCutoffs']),

    contestSponsorHeader() {
      if (!this.league?.sponsorAds) {
        return null;
      }
      return this.league?.sponsorAds['salarycap-header'];
    },
  },

  methods: {
    getContestName() {
      if (!this.league) {
        return '';
      }
      if (this.league.contestName) {
        return this.league.contestName;
      }

      const typeName = this.league.ctype === 'Tournament' ? 'Tournament' : 'H2H';
      return typeName + ' ' + this.league.playersMax + ' Team' + ' ' + this.league.sport;
    },

    getContestCutoff(league, firstCutoff) {
      if (this.contestCutoffs && league) {
        const cutoffData = this.contestCutoffs[league.sport][league.playersMax];
        if (!cutoffData) {
          return 0;
        }
        const cutoff = firstCutoff ? cutoffData.startCutoff : cutoffData.nextCutoff;
        if (cutoff) {
          return cutoff;
        }
      }
      return 0;
    },

    getDraftStartDate(draftStart) {
      const draftDate = this.$moment(draftStart).format('YYYY-MM-DD');
      const today = this.$moment().format('YYYY-MM-DD');
      if (draftDate === today) {
        return 'Today';
      }
      return this.$moment(draftStart).format('MMM Do');
    },

    getDraftStartTime(draftStart) {
      return this.$moment(draftStart).format('h:mma');
    },

    getContestStartDate(startDate) {
      const sDate = this.$moment(startDate, 'YYYY-MM-DD').format('ddd MMM Do');
      const today = this.$moment().format('ddd MMM Do');
      if (sDate === today) {
        return 'Today';
      }
      return sDate;
    },

    goToPrizes() {
      EventBus.$emit('MODAL_SCROLL_TO_COMPONENT', 'payouts', 'smooth');
    },

    getCutoffTooltipKey() {
      if (this.league.ctype === 'Tournament') {
        return 'LeagueModal_Tournament_Cutoff';
      }

      if (this.league.draftTime) {
        return 'LeagueModal_H2H_Cutoff_Scheduled';
      }

      return 'LeagueModal_H2H_Cutoff';
    },
  },
};
</script>

<style lang="scss" scoped>
.headerTable {
  width: calc(100% - 17px);
  margin-left: -6px;
  margin-top: 3px;
}

.sportImgCol {
  width: 28px;

  .sponsorBox {
    height: 26px;
  }
}

.graphCol {
  min-width: 0;
  width: 1px;
  padding: 0 8px;
  max-width: 220px;
  text-align: center;
  vertical-align: middle;
}

.graphColContent {
  white-space: nowrap;
  width: 100%;
}

.contestInfo {
  color: var(--obcolor-font-primary);
  display: flex;
  gap: 8px;
}
.contestName {
  overflow: hidden;
  text-overflow: ellipsis;
}

.summaryRow {
  font-size: 16px;
  font-weight: bold;
}

.summaryRow:last-child td {
  padding-top: 15px;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
}

.summaryValue {
  color: var(--obcolor-font-primary);
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.summaryTitle {
  margin-top: 2px;
  font-size: 12px;
  font-weight: normal;
  color: var(--obcolor-font-secondary);
}

.summaryTitle .infoBtn {
  margin: 0px;
}

.moneyText {
  color: var(--obcolor-green);
}

.obText {
  color: var(--obcolor-ob-blue);
}

.ownersbucksIcon {
  height: 12px;
  margin-right: 3px;
}

.playerCountContainer {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  font-weight: bold;
}

.progressBar {
  margin: 0 10px;
  background: var(--obcolor-background-2);
  border-radius: 5px;
  height: 10px;
  flex: 1;
  overflow: hidden;
}

.progress {
  height: 100%;
  background: var(--obcolor-ob-blue);
}
</style>