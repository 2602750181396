/**
 * Tells whether a contest is multi-create and currently has no entries
 * @param {Object} contest The contest to be entered
 * @return {Boolean} whether or not the contest is multi-create.
 */
function isMultiCreate(contest) {
  if (!contest) {
    return false;
  }

  return multiCreateData(contest) && multiCreateData(contest).contestCount > 1;
}

/**
 * The multi-create data inside of a contest
 * @param {Object} contest The contest to be entered
 * @return {Object} the contest's multi-create data
 */
function multiCreateData(contest) {
  if (!contest || !contest.userCreatedContestsData) {
    return {};
  }

  return contest.userCreatedContestsData;
}

/**
 * Total fees for multi create contest with null checking
 * @param {Object} contest The contest to be entered
 * @return {Number} the contest's fees in cents
 */
function multiCreateFees(contest) {
  if (!contest || !isMultiCreate(contest)) {
    return null;
  }

  return multiCreateData(contest).totalFees / 100;
}

/**
 * Total prizes for multi create contest with null checking
 * @param {Object} contest The contest to be entered
 * @return {Number} the contest's prizes in cents
 */
function multiCreatePrizes(contest) {
  if (!contest || !isMultiCreate(contest)) {
    return null;
  }

  return multiCreateData(contest).totalPrizePool / 100;
}

/**
 * Whether a player can be dropped mid contest in the live view
 * @param {Object} player The player to be dropped
 * @param {Object} contest The contest that is being checked
 * @return {Number} the contest's prizes in cents
 */
function canDropPlayerInLiveView(player, contest) {
  return !isPreContest(contest) && player.canSwap;
}

/**
 * Get game projection for either game or double header game
 * @param {Object} player The player to check projection
 * @return {Number} the contest's prizes in cents
 */
function getGameEntryProjection(player) {
  if (!player) {
    return 0;
  }
  const game = playerNextGame(player);
  const canSwapDelayed = player.canSwap;
  const now = new Date().getTime();
  if (!game) {
    return 0;
  }
  let proj = 0;
  if (game.ts && (canSwapDelayed || game.ts > now)) {
    proj += game.proj ? Math.round(game.proj) : 0;
  }
  if (game.tsDH && (canSwapDelayed || game.tsDH > now)) {
    proj += game.projDH ? Math.round(game.projDH) : 0;
  }
  return proj;
}

/**
 * Determines if a contest is pre-contest or if it has started
 * @param {Object} contest The contest to be entered
 * @return {Boolean} whether contest is pre-contest
 */
function isPreContest(contest) {
  if (!contest) {
    return false;
  }
  if (contest.contestStart < new Date().getTime()) {
    return false;
  }
  return contest.state === 'FILLING' || contest.state === 'FILLED';
}

/**
 * Determines player's next game if games are available
 * @param {Object} player The player to check
 * @return {Object} Player's next game entity
 */
function playerNextGame(player) {
  if (!player?.games) {
    return null;
  }
  return player.games.find((g) => g.ts != null) || null;
}

export default {isMultiCreate, multiCreateData, multiCreateFees, multiCreatePrizes, getGameEntryProjection, isPreContest, playerNextGame, canDropPlayerInLiveView};