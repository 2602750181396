<template>
  <div id="app">
    <DraftStartingModal />
    <SystemNotificationModal />
    <MobileMenu />
    <!-- The android apk screen uses a different header than the rest of the website -->
    <template v-if="$route.name !== 'androidapk' && $route.name !== 'mobileapp' && $route.name != 'integrationstatus'">
      <MainNavBar v-show="$route.name !== 'signup' && $route.name !== 'homeView'" />
    </template>
    <div id="content" :class="{ removedNavbar: $route.name === 'androidapk' || $route.name === 'mobileapp' || $route.name == 'homeView' }">
      <router-view :class="{noSubMenu: !$route.meta.subMenu}" />
    </div>
    <ContestModalView />
    <PlayerCardView slot="body" />
    <BugReportView />
    <GameModalView />
    <ReferralModalView />
    <GlobalSwapModalView />
    <QuickSwapModalView />
    <ShareContestSocials />
    <BattlePassChallengeNotifications />
    <ProfileTeamEditModal />
    <OwnersBucksRedeemModal />
    <LocationErrorModal />
  </div>
</template>

<script>
import MainNavBar from './components/MainNavbar.vue';
import MobileMenu from './components/MobileMenu.vue';
import PlayerCardView from '@/views/PlayerCard/PlayerCardView';
import ContestModalView from '@/views/Lobby/ContestModal/ContestModalView';
import GlobalUtils from './utils/global';
import {mapState} from 'vuex';
import EventBus from '@/event-bus';
import ObLeagueApi from '@/api/ObLeagueApi';
import ObSportDataApi from '@/api/ObSportDataApi';
import ObAccountApi from '@/api/ObAccountApi';
import BugReportView from '@/views/BugReport/BugReportView';
import SystemNotificationModal from './components/modals/SystemNotificationModal.vue';
import DraftStartingModal from './components/modals/DraftStartingModal.vue';
import ReferralModalView from './components/modals/ReferralModalView.vue';
import GameModalView from './components/modals/GameModal/GameModalView.vue';
import GlobalSwapModalView from './components/modals/GlobalSwapModal/GlobalSwapModalView.vue';
import QuickSwapModalView from './components/modals/QuickSwapModal/QuickSwapModalView.vue';
import OwnersBucksRedeemModal from './components/modals/Financial/ownersbucks/OwnersBucksRedeemModal.vue';
import ShareContestSocials from '@/components/ShareContestSocials';
import BattlePassChallengeNotifications from '@/components/battlepass/notifications/BattlePassChallengeNotifications';
import ProfileTeamEditModal from '@/views/Profile/ProfileTeamEditModal';
import LocationErrorModal from './components/modals/LocationErrorModal.vue';
import * as braze from '@braze/web-sdk';
import {initBraze} from './utils/braze';
import {initGeoComply, clearCachedGeopackets, clearCachedGeopacket} from '@/utils/GeoComply';

export default {
  components: {
    MainNavBar,
    MobileMenu,
    PlayerCardView,
    ContestModalView,
    SystemNotificationModal,
    BugReportView,
    DraftStartingModal,
    ReferralModalView,
    GameModalView,
    GlobalSwapModalView,
    QuickSwapModalView,
    OwnersBucksRedeemModal,
    // REMEMBER to swap the variables in variables.scss if removing/adding this
    ShareContestSocials,
    BattlePassChallengeNotifications,
    ProfileTeamEditModal,
    LocationErrorModal,
  },

  data: function() {
    return {
      showAllowPush: false,
      cutoffExpiryTimer: null,
      cutoffExpiry: null,
      hideMesssenger: {
        'androidapk': true,
        'mobileapp': true,
        'signup': true,
        'homeView': true,
      },
    };
  },

  mounted() {
    let afDevKey = '123f844f-0d68-4a41-bf42-fe9897e2beff';
    switch (location.hostname) {
      case 'app.ownersbox.com':
      case 'ownersbox.com':
        afDevKey = 'b7eb73c2-1d1b-4264-bc04-af0fc9a8f57c';
        break;
    }

    const afScript = document.createElement('script');
    afScript.innerHTML = `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
    (t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))}
    ,t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],
    o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")
    +(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF", "pba,banners",
    {pba: {webAppId: "${afDevKey}"}, banners: {key: "bff775b8-58d4-4054-ae57-95e5faa6225f"}});AF('banners', 'showBanner');`;
    document.head.appendChild(afScript);
  },

  computed: {
    ...mapState([
      'isLoggedIn',
      'userId',
      'userState',
      'nodeServer',
      'nodeToken',
      'todaysGames',
      'redirectedLink',
      'defaultRoute',
    ]),
  },

  created() {
    initBraze(this.isLoggedIn, this.userId);
    initGeoComply();
    this.setupTheme();
    this.initContestCutoffs();
    this.incrementUserLogoVersion();
    this.loadTodaysGames();

    if (this.isLoggedIn) {
      this.$GeographicInfo.setupGeolocationCheck();
      this.connectSocket();
    }

    EventBus.$on('CHANGE_PROFILE_PICTURE', this.incrementUserLogoVersion);
    EventBus.$on('LOGIN_USER', this.loginUser);
    EventBus.$on('LOGIN', this.onLogin);
    EventBus.$on('LOGOUT', this.onLogout);
    EventBus.$on('GET_CONTEST_CUTOFFS', this.loadContestCutoffs);
    EventBus.$on('LOAD_USER_TICKETS', this.loadTickets);
    EventBus.$on('CLEAR_CACHED_GEOPACKET', this.clearGeopacket);
    this.$SocketController.startRoom('FSP_GAMES', this.onSocketBroadcast);
    this.checkParams();
  },

  destroyed() {
    EventBus.$off('CHANGE_PROFILE_PICTURE', this.incrementUserLogoVersion);
    EventBus.$off('LOGIN_USER', this.loginUser);
    EventBus.$off('LOGIN', this.onLogin);
    EventBus.$off('LOGOUT', this.onLogout);
    EventBus.$off('GET_CONTEST_CUTOFFS', this.loadContestCutoffs);
    EventBus.$off('LOAD_USER_TICKETS', this.loadTickets);
    EventBus.$off('CLEAR_CACHED_GEOPACKET', this.clearGeopacket);
    this.$SocketController.stopRoom('FSP_GAMES', this.onSocketBroadcast);
  },

  metaInfo: {
    title: 'OwnersBox Fantasy Sports',
    meta: [
      {name: 'description', content: 'The home of SuperFlex Daily Fantasy Sports! OwnersBox is an innovative sports-tech company disrupting the way consumers play Fantasy Sports.'},
      {name: 'og:description', content: 'The home of SuperFlex Daily Fantasy Sports! OwnersBox is an innovative sports-tech company disrupting the way consumers play Fantasy Sports.'},
    ],
  },

  methods: {
    loadTickets() {
      ObAccountApi.getUserTickets()
          .then((response) => {
            this.$store.commit('setTickets', response);
          })
          .catch((_e) => {
            // Catch error to prevent logs
          });
    },

    clearGeopacket(reason, gameType) {
      clearCachedGeopacket(reason, gameType);
    },

    onSocketBroadcast(data) {
      if (data == null) {
        return;
      }
      if (data.type === 'FSP_GAMES' && data.id && data.json) {
        this.$set(this.todaysGames, data.id, data.json);
        EventBus.$emit('LIVE_GAME_UPDATE', data.id);
      }
    },

    setupTheme() {
      const isLightMode = this.$cookie.get('ob-dark-mode') === 'false';
      this.$store.commit('setTheme', isLightMode ? 'light' : 'dark');
    },

    // Perform login, required the login response JSON
    loginUser(loginJSON) {
      if (loginJSON.userState && loginJSON.userState === 'WAITLIST') {
        this.$store.commit('setUserState', 'WAITLIST');
        this.$router.push('/waitlist');
      } else if (loginJSON.userId) {
        this.$store.commit('setUserState', loginJSON.userState);
        this.$store.commit('setNodeToken', loginJSON.st);
        this.$store.commit('setLoggedIn', loginJSON.userId);

        if (this.redirectedLink != null) {
          this.$router.push(this.redirectedLink);
        } else {
          this.$router.push(this.defaultRoute);
        }
        EventBus.$emit('LOGIN');
      }
    },

    onLogin() {
      this.connectSocket();
      this.showAllowPushDialog();
      this.setupUser();
      braze.changeUser(this.userId);
    },

    onLogout() {
      this.$store.commit('setNodeToken', '');
      clearCachedGeopackets();
    },

    connectSocket() {
      if (this.nodeServer) {
        this.$SocketController.connect(this.nodeServer, this.userId, this.nodeToken);
      }
    },

    loadTodaysGames() {
      // Reload game data every 15 minutes
      // Backup load in case of socket issues, also gets new games if day changes
      this.loadTodaysGamesData();
      const self = this;
      setInterval(function() {
        self.loadTodaysGamesData();
      }, 900000);
    },

    loadTodaysGamesData() {
      ObSportDataApi.getTodaysGames()
          .then((data) => {
            if (data.games) {
              this.$store.commit('setTodaysGames', data.games);
            }
          });
    },

    initContestCutoffs() {
      this.loadContestCutoffs();

      if (!this.cutoffExpiryTimer) {
        const self = this;
        this.cutoffExpiryTimer = setInterval(function() {
          // If cutoff expiry is past, fetch new values
          if (self.cutoffExpiry && new Date().getTime() > self.cutoffExpiry) {
            self.loadContestCutoffs();
          }
          // 2 minute timer
        }, 120000);
      }
    },

    loadContestCutoffs() {
      // Contest cutoff times are used in multiple areas and only change once a day
      // Load it globally
      ObLeagueApi.getDailyCutoffs()
          .then((data) => {
            this.$store.commit('setContestCutoffs', data);
            this.cutoffExpiry = data.expiry;
          });
    },

    setupUser() {
      if (this.isLoggedIn && this.userId) {
        this.$Analytics.trackUser(this.userId);
      }
    },

    showAllowPushDialog() {
      // Don't show for mobile devices, they should get the app
      if (this.$DeviceInfo.isMobile()) {
        return;
      }
    },

    closeAllowPush() {
      this.showAllowPush = false;
    },

    incrementUserLogoVersion() {
      let userLogoVersion = this.$cookie.get('userLogoVersion');
      if (userLogoVersion == null) {
        this.$cookie.set('userLogoVersion', 0, 3650);
      } else {
        userLogoVersion = Number(userLogoVersion) + 1;
        this.$cookie.set('userLogoVersion', userLogoVersion, 3650);
      }

      this.$store.commit('setUserLogoVersion', userLogoVersion);
    },

    checkParams() {
      const pCode = this.$route.params.partnercode;
      if (pCode != null) {
        this.$cookie.set('ob-partner', pCode, {expires: 1, domain: 'ownersbox.com'});
      }

      const btag = this.$route.query.btag;
      if (btag != null) {
        this.$cookie.set('btag', btag, {expires: 30, domain: 'ownersbox.com'});
      }

      const fpros = this.$route.query.fpros;
      if (fpros != null) {
        this.$cookie.set('fpros', fpros, {expires: 30, domain: 'ownersbox.com'});
      }

      const mbsy = this.$route.query.mbsy;
      if (mbsy != null) {
        this.$cookie.set('mbsy', mbsy, {expires: 30, domain: 'ownersbox.com'});
      }
    },
  },
};
</script>

<style lang="scss">
@import "./style/normalize.css";
@import "@/style/theme.scss";
@import '@/style/DesignSystem/theme-2.scss';
@import "@/style/globals.scss";
@import "@/style/variables.scss";
@import "@/style/fonts.scss";
@import "@/style/buttons.scss";
@import "@/assets/fonts/Nunito";

// This prevents a scrollbar from appearing after logging in with google
// After login this empty div is created with a 1px height which causes the scrollbar to appear
#g_a11y_announcement {
  height: 0 !important;
}

body, html, #app {
  margin: 0;
  padding: 0;
  height: 100%;
}
#app {
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}
body {
  background: var(--obcolor-background-page);
  color: var(--obcolor-font-primary);
  position: relative;
}
body.modal-open {
  overflow: hidden;
}

#content {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  &:not(.removedNavbar) {
    padding-top: calc(var(--ob-nav-offset) + 50px);
  }

  &:has(.noSubMenu) {
    padding-top: calc(var(--ob-nav-offset));
  }
}

.ObTooltipHeat {
  font-family: 'Nunito', sans-serif;
  font-weight: normal;
  font-size: 12px;
  pointer-events: none;
  z-index: 9999 !important;
}

.ObTooltip {
  font-family: 'Nunito', sans-serif;
  font-weight: normal;
  font-size: 12px;
  pointer-events: none;

  z-index: 9999 !important;
}

// 3-second fade out animation with 1 second extra of 0 opacity because tooltips don't update immediately
.ob-tooltip-fadeout {
  animation: fadeOutTooltip ease 4s;
  animation-iteration-count: 1;
}

@keyframes fadeOutTooltip {
  0% {
    opacity:1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity:0;
  }
  100% {
    opacity: 0;
  }
}
</style>
