import { render, staticRenderFns } from "./ContestModalView.vue?vue&type=template&id=4fce3ffa&scoped=true"
import script from "./ContestModalView.vue?vue&type=script&lang=js"
export * from "./ContestModalView.vue?vue&type=script&lang=js"
import style0 from "./ContestModalView.vue?vue&type=style&index=0&id=4fce3ffa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fce3ffa",
  null
  
)

export default component.exports