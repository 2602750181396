<template>
  <div>
    <div v-if="stepUpVisible || showLoading" class="modal-mask"></div>
    <div v-if="showLoading" class="loadingBox">
      <i class="fad fa-spinner-third fa-spin"></i>
    </div>

    <!-- IFrame for 3DS challenge -->
    <iframe v-show="stepUpVisible" id="frame3dsChallenge" name="frame3dsChallenge"
      height="600" width="500" class="challengeFrame"
      title=""
    >
    </iframe>
    <form id="challengeForm" name="stepup" method="POST"
      target="frame3dsChallenge" :action="stepUpUrl"
    >
      <input type="hidden" name="JWT" :value="stepUpToken">
    </form>

    <!-- Hidden form for 3DS verification -->
    <iframe id="frame3ds" name="frame3ds" class="hiddenFrame"
      height="10" width="10" title=""
    ></iframe>
    <form id="collectionForm" name="devicedata" method="POST"
      target="frame3ds" :action="card3dsUrl"
    >
      <input type="hidden" name="JWT" :value="card3dsToken">
    </form>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ObPaymentApi from '@/api/ObPaymentApi';

const EVENT_ERROR = 'error';
const EVENT_SUCCESS = 'success';
const DEFAULT_ERROR_MESSAGE = 'An error occurred, please contact support';

export default {

  props: {
    cardNumber: {type: String, default: null},
    cardExpiry: {type: String, default: null},
    firstName: {type: String, default: null},
    lastName: {type: String, default: null},
    postalCode: {type: String, default: null},
    depositAmount: {type: Number, default: null},
  },

  data() {
    return {
      showLoading: false,
      startLookup: false,
      card3dsToken: '',
      card3dsUrl: '',
      card3dsOrigin: '',
      card3dsSessionId: '',
      stepUpUrl: '',
      stepUpToken: '',
      stepUpVisible: false,
      dataCollectionTimeout: null,
      dataCollectionCardNum: null,
    };
  },

  watch: {
    cardNumber() {
      this.startDataCollection();
    },
  },

  created() {
    this.setup3dsEventHandler();
    EventBus.$on('3DS_VERIFY', this.cmpiLookup);
    // Check if the credit card number has already been entered
    this.startDataCollection();
  },

  destroyed() {
    this.remove3dsEventHandler();
    this.clearDataColectionTimeout();
    EventBus.$off('3DS_VERIFY', this.cmpiLookup);
  },

  methods: {
    setup3dsEventHandler() {
      window.addEventListener('message', this.handleMessageEvent, false);
    },

    remove3dsEventHandler() {
      window.removeEventListener('message', this.handleMessageEvent, false);
    },

    handleMessageEvent(event) {
      if (event.origin === this.card3dsOrigin) {
        const data = JSON.parse(event.data);
        if (data?.Payload?.ActionCode == 'SUCCESS') {
          // 3DS Data Collection
          this.card3dsSessionId = data?.Payload?.SessionId;

          // If the user has clicked the submit button, start the lookup process
          if (this.startLookup) {
            this.cmpiLookup();
          }
        } else {
          // 3DS Challenge Modal
          this.handle3dsChallengeResult(data);
        }
      }
    },

    handle3dsChallengeResult(data) {
      if (data === undefined || data.MessageType === undefined) {
        return;
      }
      switch (data.MessageType) {
        case 'stepUp.completion':
          this.stepUpVisible = false;
          this.cmpiAuthenticate(data.TransactionId);
          break;
        case 'stepUp.error':
          this.$emit(EVENT_ERROR, DEFAULT_ERROR_MESSAGE);
          break;
      }
    },

    startDataCollection() {
      // We need 13 digits of the users credit card to begin the data collection step
      const cardNum = this.getCardNumber();
      if (cardNum.length < 13) {
        this.clearDataColectionTimeout();
        return;
      }
      // Once 13 digits have been entered, delay the data-collection a few seconds in case the user changes the number
      this.clearDataColectionTimeout();
      this.dataCollectionTimeout = setTimeout(() => {
        this.getDataCollectionToken();
      }, 3000);
    },

    clearDataColectionTimeout() {
      if (this.dataCollectionTimeout) {
        clearTimeout(this.dataCollectionTimeout);
      }
    },

    getDataCollectionToken() {
      const cardNum = this.getCardNumber();
      if (cardNum.length < 13) {
        return;
      }
      const params = {
        cardNumber: cardNum,
      };
      ObPaymentApi.createDataCollectionToken(params)
          .then((response) => {
            // If card number has changed since the request was made, ignore the response
            // Wait for the next data collection call with the new card number
            if (cardNum != this.getCardNumber()) {
              return;
            }
            this.dataCollectionCardNum = cardNum;
            this.card3dsToken = response.jwt;
            this.card3dsUrl = response.url;
            this.card3dsOrigin = response.origin;
            this.$nextTick(() => {
              document.getElementById('collectionForm').submit();
            });
          })
          .catch((error) => {
            this.$emit(EVENT_ERROR, this.getErrorText(error));
          });
    },

    async cmpiLookup(paymentProvider) {
      // If we don't have a session id yet, set a flag to begin the lookup process once the data collection step is done
      // If the card number has changed since the last dataCollection call, we need to run it again with the new number
      if (!this.card3dsSessionId || this.getCardNumber() != this.dataCollectionCardNum) {
        this.startLookup = true;
        return;
      }

      // Reset the startLookup flag so the code can't process multiple times
      this.startLookup = false;
      this.showLoading = true;

      // Tokenize card (Fiserv Only)
      let sessionId = null;
      if (paymentProvider == 'fiserv') {
        sessionId = await this.getFiservSession();
        const tokenized = await this.tokenizeCard(sessionId);
        if (!tokenized) {
          this.showLoading = false;
          this.$emit(EVENT_ERROR, DEFAULT_ERROR_MESSAGE);
          return;
        }
      }

      const params = {};
      this.addBrowserParams(params);
      params.referenceId = this.card3dsSessionId;
      params.amount = this.depositAmount * 100;
      params.cardExpiry = this.cardExpiry;
      params.postalCode = this.postalCode;
      params.firstName = this.firstName;
      params.lastName = this.lastName;

      // If card is tokenized, use session id, otherwise use plain card number
      if (sessionId != null) {
        params.fiservSessionId = sessionId;
      } else {
        params.cardNumber = this.getCardNumber();
      }

      ObPaymentApi.cmpiLookup(params)
          .then((response) => {
            // StepUpUrl is required to show the challenge modal
            if (response.stepUpUrl) {
              this.stepUpToken = response.jwt;
              this.stepUpUrl = response.stepUpUrl;
              this.card3dsOrigin = response.origin;
              this.$nextTick(() => {
                document.getElementById('challengeForm').submit();
                this.stepUpVisible = true;
              });
            } else {
              // No challenge required, continue with deposit
              this.showLoading = false;
              this.$emit(EVENT_SUCCESS, response.transactionId);
            }
          })
          .catch((error) => {
            this.showLoading = false;
            this.$emit(EVENT_ERROR, this.getErrorText(error));
          });
    },

    addBrowserParams(params) {
      params.web = true;
      params.browserJavaEnabled = navigator.javaEnabled();
      params.browserLanguage = navigator.language;
      params.browserColorDepth = screen.colorDepth;
      params.browserScreenHeight = screen.height;
      params.browserScreenWidth = screen.width;
      params.browserTimeZone = new Date().getTimezoneOffset();
      params.browserUserAgent = navigator.userAgent;
      params.browserJavascriptEnabled = true;
    },

    getCardNumber() {
      return this.cardNumber.replace(/\s+/g, '');
    },

    getFiservSession() {
      return new Promise((resolve) => {
        // Get a new session
        ObPaymentApi.getFiservSession()
            .then((response) => {
              resolve(response.sessionId);
            }).catch((_error) => {
              resolve(null);
            });
      });
    },

    tokenizeCard(sessionId) {
      if (!sessionId) {
        return false;
      }
      let expMonth = null;
      let expYear = null;
      const cardNum = this.getCardNumber();

      if (this.cardExpiry && this.cardExpiry.split('/').length > 0) {
        expMonth = this.cardExpiry.split('/')[0].trim();
        expYear = this.cardExpiry.split('/')[1].trim();
      } else {
        return false;
      }

      const params = {
        Version: '1',
        SessionId: sessionId,
        CardNumber: cardNum,
        Expiration: expMonth + expYear,
        CVN: this.cardCvc,
        AVS: {
          Zip: this.postalCode,
          FirstName: this.firstName,
          LastName: this.lastName,
        },
      };

      return new Promise((resolve) => {
        ObPaymentApi.tokenizeCard(params)
            .then((response) => {
              // Check the response ErrorCode field, a value not "00" is considered an error
              if (response.ErrorCode != '00') {
                resolve(false);
              } else {
                // Successful tokenization
                resolve(true);
              }
            }).catch((_error) => {
              resolve(false);
            });
      });
    },

    getErrorText(error) {
      if (error?.response?.data) {
        return error.response.data;
      }
      return DEFAULT_ERROR_MESSAGE;
    },

    // Final step, authenticate the result of the challenge
    cmpiAuthenticate(transactionId) {
      const params = {
        transactionId: transactionId,
      };
      ObPaymentApi.cmpiAuthenticate(params)
          .then((_response) => {
            // Success response, process the deposit
            this.showLoading = false;
            this.$emit(EVENT_SUCCESS, transactionId);
          })
          .catch((error) => {
            this.showLoading = false;
            this.$emit(EVENT_ERROR, this.getErrorText(error));
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.loadingBox {
  width: 500px;
  height: 600px;
  position: fixed;
  background: white;
  color: black;
  left: calc(50% - 250px);
  top: 120px;
  text-align: center;
  padding-top: 220px;
  box-sizing: border-box;
  z-index: 9999;
  i {
    font-size: 50px;
  }
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  flex-direction: column;
  transition: opacity .3s ease;
}

.hiddenFrame {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.challengeFrame {
  position: fixed;
  top: 120px;
  left: calc(50% - 250px);
  background: white;
  z-index: 9999;
  border: 0;
}
</style>