import Api from '@/api/ObBaseApi';
import FiservApi from '@/api/FiservBaseApi';
import {getGeopacketConfig} from '@/utils/GeoComply.js';

export default {

  getClientToken() {
    return Api()
        .get('/fsp/payment/getClientToken')
        .then((response) => {
          return response.data;
        });
  },

  checkout(nonce, amount, deviceData) {
    const params = {
      amount: amount,
      paymentMethodNonce: nonce,
      deviceData: deviceData,
    };
    return Api()
        .post('/fsp/payment/checkout', params)
        .then((response) => {
          return response.data;
        });
  },

  getTransactionStatus(transactionId) {
    if (transactionId == null) {
      return null;
    }

    return Api()
        .get('/fsp/payment/transaction/status?objectId=' + transactionId)
        .then((response) => {
          return response.data;
        });
  },

  // params: fullName, address1, address2, city, country, region, postalCode, amount
  withdraw(params) {
    return Api()
        .post('/fsp/payment/cheque/withdrawal', params)
        .then((response) => {
          return response.data;
        });
  },

  async deposit(params) {
    const reason = 'Deposit';
    const config = await getGeopacketConfig(reason);
    return Api()
        .post('/fsp/payment/base/deposit', params, config)
        .then((response) => {
          return response.data;
        });
  },

  async isValidLocation() {
    const reason = 'Deposit';
    const config = await getGeopacketConfig(reason);
    return Api()
        .get('/fsp/payment/isValidLocation?errorType=json', config)
        .then((response) => {
          return response.data;
        });
  },

  getVerifyUserInfo() {
    return Api()
        .get('/fsp/verify/getUserInfo')
        .then((response) => {
          return response.data;
        });
  },

  getVerifyState() {
    return Api()
        .get('/fsp/verify/state')
        .then((response) => {
          return response.data;
        });
  },

  verifyAccount(params) {
    return Api()
        .post('/fsp/verify/everification', params)
        .then((response) => {
          return response.data;
        });
  },

  createToken() {
    return Api()
        .post('/fsp/verify/idpv/createToken')
        .then((response) => {
          return response.data;
        });
  },

  createTokenStatus(tokenId) {
    return Api()
        .get('/fsp/verify/idpv/status?token=' + tokenId)
        .then((response) => {
          return response.data;
        });
  },

  async saveLocation() {
    const reason = 'Withdraw';
    const config = await getGeopacketConfig(reason);
    return Api()
        .post('/fsp/payment/withdraw/saveLocation', {}, config)
        .then((response) => {
          return response.data;
        });
  },

  getDepositOptions() {
    return Api()
        .get('/fsp/payment/getDepositOptions')
        .then((response) => {
          return response.data;
        });
  },

  getDepositProviderInfo() {
    return Api()
        .get('/fsp/payment/getDepositProviderInfo')
        .then((response) => {
          return response.data;
        });
  },

  // ### Fiserv ###
  async fiservDeposit(params) {
    const reason = 'Deposit';
    const config = await getGeopacketConfig(reason);
    return Api()
        .post('/fsp/payment/fiserv/deposit', params, config)
        .then((response) => {
          return response.data;
        });
  },

  tokenizeCard(params) {
    return FiservApi()
        .post('/ClientTokenizeCard', params)
        .then((response) => {
          return response.data;
        });
  },

  getFiservSession() {
    return Api()
        .get('/fsp/payment/getFiservSession')
        .then((response) => {
          return response.data;
        });
  },

  submitWithdrawal(params) {
    return Api()
        .post('/fsp/payment/fiserv/submitWithdrawal', params)
        .then((response) => {
          return response.data;
        });
  },

  createFiservRecipient(params) {
    return Api()
        .post('/fsp/payment/fiserv/createRecipient', params)
        .then((response) => {
          return response.data;
        });
  },

  getFiservAchAccount() {
    return Api()
        .get('/fsp/payment/fiserv/ach/account')
        .then((response) => {
          return response.data;
        });
  },

  consumerProfile() {
    return Api()
        .post('/fsp/payment/fiserv/ach/consumer')
        .then((response) => {
          return response.data;
        });
  },

  addFiservAccount(nonce, transactionId, referenceTransactionId) {
    const params = {
      nonce: nonce,
      transactionId: transactionId,
      referenceTransactionId: referenceTransactionId,
    };
    return Api()
        .post('/fsp/payment/fiserv/ach/account', params)
        .then((response) => {
          return response.data;
        });
  },

  updateAccount(id, params) {
    return Api()
        .put(`/fsp/payment/fiserv/ach/account/${id}`, params)
        .then((response) => {
          return response.data;
        });
  },

  async depositFiserv(accountId, amount, amountInCents) {
    const params = {
      accountId: accountId,
      amount: amount,
      amountInCents: amountInCents,
    };
    const reason = 'Deposit';
    const config = await getGeopacketConfig(reason);
    return Api()
        .post('/fsp/payment/fiserv/ach/deposit', params, config)
        .then((response) => {
          return response.data;
        });
  },

  createAchToken(fdAccountId) {
    const params = {
      fdAccountId: fdAccountId,
    };
    return Api()
        .post('/fsp/payment/fiserv/ach/sessionToken', params)
        .then((response) => {
          return response.data;
        });
  },

  deleteAchAccount(id) {
    return Api()
        .delete('/fsp/payment/fiserv/ach/account/' + id)
        .then((response) => {
          return response.data;
        });
  },

  validateGiftCard(cardNumber, cardPin) {
    return Api()
        .get('/fsp/giftcard/validate?cardNumber=' + cardNumber + '&cardPin=' + cardPin)
        .then((response) => {
          return response.data;
        });
  },

  depositGiftCard(params) {
    return Api()
        .post('/fsp/giftcard/deposit', params)
        .then((response) => {
          return response.data;
        });
  },

  verifyAccountGeoComply(params) {
    return Api()
        .post('/fsp/verify/dataVerification', params)
        .then((response) => {
          return response.data;
        });
  },

  getProvider() {
    return Api()
        .get('/fsp/verify/provider')
        .then((response) => {
          return response.data;
        });
  },

  createDataCollectionToken(params) {
    return Api()
        .post('/fsp/payment/createDataCollectionToken', params)
        .then((response) => {
          return response.data;
        });
  },

  cmpiLookup(params) {
    return Api()
        .post('/fsp/payment/cmpiLookup', params)
        .then((response) => {
          return response.data;
        });
  },

  cmpiAuthenticate(params) {
    return Api()
        .post('/fsp/payment/cmpiAuthenticate', params)
        .then((response) => {
          return response.data;
        });
  },
};