<template>
  <div @click="openLeagueModal(league)"
    class="contestBoxContainer" :class="{flashContest: playerJoined}"
  >
    <div class="enteredIndicator" :class="{hasEntered: league.myEntries > 0}"></div>
    <div class="titleBox">
      <SponsorImage class="sponsorContainer" v-if="contestSponsorIcon" :sponsor="contestSponsorIcon"
        width="53px"
      />
      <img v-else :src="getContestBadge(league.sport)" :alt="league.sport">
      <div class="title">
        <h3 v-tooltip.bottom="{content: getContestName(), class: 'ObTooltip', visible: overflow}">{{ getContestName() }}</h3>
        <ContestIcons :contest="league" hide-guaranteed />
      </div>
    </div>

    <div class="countDown">
      <div v-if="league.contestStart && $moment().diff(league.contestStart) > 0" class="fieldValue">
        <h3>Started</h3>
      </div>
      <!-- Tournament Contest Time -->
      <div v-else-if="league.contestStart">
        <h3><LobbyContestCountdown :end="league.contestStart" tournament /></h3>
      </div>
      <div v-else-if="league.scheduledStartDate">
        <h3>{{ getContestStartDate(league.scheduledStartDate) }}</h3>
      </div>
      <div v-else-if="league.draftTime">
        <h3><LobbyContestCountdown :end="league.draftTime" /></h3>
      </div>
      <!-- Weekly contests time -->
      <template v-else-if="getContestCutoff(league, true)">
        <h3><LobbyContestCountdown :end="getContestCutoff(league, true)" :end2="getContestCutoff(league, false)" /></h3>
      </template>
      <div v-else>-</div>
      <div>
        <p v-if="league.ctype == 'Tournament'">Starts in <LobbyContestCountdown :end="league.contestStart" /></p>
        <p v-else>Live draft when filled</p>
      </div>
    </div>

    <ContestFillGraph :playersCurrent="league.playersCurrent"
      :playersMax="league.playersMax"
      :format="league.ctype == 'Tournament' ? 'Tournament' : 'H2H'"
      :playerJoined="playerJoined"
    />

    <div class="entry">
      <div>
        <img class="ownersbucksIcon" v-if="!league.realMoney" :src="OwnersBucks"
          alt="ownersBucks currency"
        >
        <h3 v-if="league.fee" :class="{ blueText: !league.realMoney }">{{ league.fee.removeCentsIfZero() }}</h3>
      </div>
      <p>{{ entryFeeText }}</p>
    </div>

    <div class="guaranteed">
      <div>
        <img v-if="league.ctype == 'Tournament' && league.isGuaranteed && !league.realMoney" :src="GuaranteedBlue"
          v-tooltip.bottom="{content: 'Guaranteed Prize Pool', class: 'ObTooltip'}"
          alt="Guaranteed Prize Pool Contest"
        >
        <img v-if="league.ctype == 'Tournament' && league.isGuaranteed && league.realMoney" :src="Guaranteed"
          v-tooltip.bottom="{content: 'Guaranteed Prize Pool', class: 'ObTooltip'}"
          alt="Guaranteed Prize Pool Contest"
        >
        <img v-if="!league.realMoney" :src="OwnersBucks" class="ownersbucksIcon"
          alt="OwnersBucks Contest"
        >
        <SalaryContestPrizePool :contest="league" :showTicketIcon="true"
          class="payoutText" :class="{blueText: !league.realMoney}"
        />
      </div>
    </div>

    <div @click.stop>
      <router-link :to="enterBtnLink" class="enterNow ob-btn-black">
        <template v-if="useableTicket != null">
          <TicketTooltip :src="require('@/assets/icons/ticket.png')" :ticket="useableTicket" />
        </template>
        {{ enterBtnText }}
      </router-link>
    </div>
  </div>
</template>

<script>
import SalaryContestPrizePool from '@/views/SalaryContest/SalaryContestPrizePool.vue';
import ContestFillGraph from '@/views/Lobby/ContestFillGraph.vue';
import LobbyContestCountdown from '@/views/Lobby/LobbyContestCountdown.vue';
import EventBus from '@/event-bus';
import {mapState} from 'vuex';
import Guaranteed from '@/assets/contesticons/Guaranteed.svg';
import GuaranteedBlue from '@/assets/contesticons/GuaranteedBlue.svg';
import OwnersBucks from '@/assets/icons/ownersbucks.png';
import ContestIcons from '@/components/Contests/ContestIcons.vue';
import TicketTooltip from '@/components/tooltip/tickets/TicketTooltip.vue';
import SponsorImage from '@/components/sponsors/SponsorImage';

export default {
  name: 'LobbyContestBox',

  components: {
    ContestFillGraph,
    LobbyContestCountdown,
    ContestIcons,
    TicketTooltip,
    SponsorImage,
    SalaryContestPrizePool,
  },

  props: {
    league: {type: Object, default: null},
    entered: {type: Object, default: null},
    playerJoined: {type: Boolean, default: false},
  },

  computed: {
    ...mapState(['contestCutoffs', 'rankNum', 'ticketFeeLookup']),

    useableTicket() {
      return this.$UserInfo.canUseTicket(this.league);
    },

    contestSponsorIcon() {
      if (!this.league?.sponsorAds) {
        return null;
      }
      return this.league?.sponsorAds['salarycap-header'];
    },

    overflow() {
      return this.getContestName().length > 45;
    },

    enterBtnText() {
      // Max entered, can only edit entry
      if (this.league.entriesPerUser === this.league.myEntries) {
        return 'Edit Lineup';
      }
      // Entered already, but can enter additional lineups
      if (this.league.myEntries > 0) {
        return 'Enter Again';
      }
      // Has not entered
      return 'Enter Now';
    },

    enterBtnLink() {
      // Max entered, send to contest page instead of lineup builder
      if (this.league.entriesPerUser === this.league.myEntries) {
        return '/salarycontest/leaderboard/' + this.league.id;
      }
      // Has not entered, or can enter additional lineups
      return '/lineup-builder/' + this.league.id;
    },

    entryFeeText() {
      // Max entered
      if (this.league.entriesPerUser === this.league.myEntries) {
        return 'Max Entered!';
      }
      // Can enter additional lineups, show remaining entries
      if (this.league.entriesPerUser > 1 && this.league.myEntries > 0) {
        return this.league.myEntries + '/' + this.league.entriesPerUser + ' Entries';
      }
      // Has not entered and contest allows multi-entry
      if (this.league.entriesPerUser > 1) {
        return 'Max ' + this.league.entriesPerUser + ' Entries';
      }
      // Has not entererd, and is a single entry contest
      return 'Single Entry';
    },
  },

  data() {
    return {
      Guaranteed: Guaranteed,
      OwnersBucks: OwnersBucks,
      GuaranteedBlue: GuaranteedBlue,
      feeFilterRange: [0, 9],
      entrantsFilterRange: [0, 5],
    };
  },

  methods: {
    getContestName() {
      if (!this.league) {
        return '';
      }
      if (this.league.contestName && this.league.groupContestsRemaining) {
        return this.league.contestName + ' (x' + this.league.groupContestsRemaining + ')';
      } else if (this.league.contestName) {
        return this.league.contestName;
      }
      return this.league.sport + ' ' + this.league.playersMax + ' Team';
    },

    getContestBadge(sport) {
      return require('@/assets/contestbadge/' + sport + '.png');
    },

    openLeagueModal(league) {
      EventBus.$emit('OPEN_LEAGUE_MODAL', league, this.entered[league.id], false);
    },

    getContestStartDate(startDate) {
      const sDate = this.$moment(startDate, 'YYYY-MM-DD').format('ddd MMM D');
      const today = this.$moment().format('ddd MMM D');
      if (sDate === today) {
        return 'Today';
      }
      return sDate;
    },

    getContestCutoff(league, firstCutoff) {
      if (this.contestCutoffs && league) {
        const cutoffData = this.contestCutoffs[league.sport][league.playersMax];
        if (cutoffData) {
          const cutoff = firstCutoff ? cutoffData.startCutoff : cutoffData.nextCutoff;
          if (cutoff) {
            return cutoff;
          }
        }
      }
      return 0;
    },

    isContestToday(startDate) {
      const sDate = this.$moment(startDate).format('ddd MMM D');
      const today = this.$moment().format('ddd MMM D');
      return sDate === today;
    },
  },
};
</script>

<style lang="scss" scoped>
.contestBoxContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  padding: 10px 10px;
  margin-bottom: 2px;
  background-color: var(--obcolor-background-6);
  cursor: pointer;
  transition: all 0.2s;
  gap: 32px;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  font-size: 13px !important;

  &:hover {
    background: var(--obcolor-background-4);
  }

  h2,
  h3,
  p {
    margin: 0;
  }

  h3 {
    font-size: 13px;
    line-height: 19px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    color: var(--obcolor-text-secondary);
  }

  .gameType,
  .entry {
    width: 90px;
    white-space: nowrap;
  }

  .entry > p {
    display: flex;
    align-items: center;
  }

  .entry div{
    display: flex;

    h3 {
      align-self: center;
    }

    img {
      align-self: center;
      width: 16px;
      height: 10px;
      padding: 0 5px 5px 0;
    }
  }

  .countDown {
    max-width: 100px;
    min-width: 100px;
  }

  ::v-deep {
    .graphText {
      margin-top: 1px;
      padding: 0 0 4px 0;
      font-size: 13px;
      width: 100%;
    }

    .progressBar {
      height: 4px;
      max-width: 45px;
      min-width: 45px;
      margin-left: 0;
    }

    .progressBarContainer {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      text-align: left;
    }

    .fullText {
      margin: 0;
      font-size: 12px;
      width: 28px;
      text-align: left;
      font-weight: normal;
    }

    .graphContainer {
      min-width: 90px;
      max-width: 90px;
    }

    .iconContainer {
      img {
        width: 16px;
        height: 16px;
      }
    }

    .ticketsIcon {
      margin: 0;
      width: 16px;
      padding-right: 8px;
    }

    .ticketText {
      display: none;
    }
  }

  .titleBox {
    display: flex;
    max-width: 400px;
    min-width: 400px;
    align-items: center;
    flex: 1;

    h3 {
      font-size: 13px;
      line-height: 19px;
      padding-bottom: 0;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 350px;
    }

    > img, > .sponsorContainer {
      width: 53px;
      height: 45px;
      padding-right: 20px;
      margin-left: 5px;
      margin-top: -2px;
      margin-bottom: -5px;
    }
  }

  .title img {
    width: 16px;
    height: 16px;
    padding-right: 2px;
  }

  .blueText {
    color: var(--obcolor-ob-blue);
  }

  .guaranteed {
    width: 80px;
    white-space: nowrap;

    > div {
      display: flex;

      .blueText {
        color: var(--obcolor-ob-blue);
      }

      > img {
        width: 17px;
        height: 17px;
        padding-right: 5px;
        align-self: center;
      }

      .ownersbucksIcon {
        width: 16px;
        height: 10px;
      }
    }

    p {
      padding-top: 5px;
    }
  }

  .payoutText {
    font-size: 13px;
    font-weight: bold;
    color: var(--obcolor-realmoney);
    align-self: center;
    margin-bottom: -1px;
  }

  .fa-chevron-right {
    align-self: center;
    padding-right: 15px;
    font-size: 16px;
  }
}

.enteredIndicator {
  position: absolute;
}

.hasEntered {
  $size: 24px;
  height: $size;
  width: $size;
  background: var(--obcolor-sportscolour-nba);
  transform: rotate(45deg);
  position: absolute;
  top: -($size/2);
  left: -($size/2);
}

.enterNow {
  max-width: 100px;
  min-width: 100px;
  font-size: 13px;
  white-space: nowrap;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--obcolor-background-1) !important;
  }
}

.activeArrow {
  color: var(--obcolor-sportscolour-nba);
}

.flashContest {
  color: var(--obcolor-flash-orange);
}
</style>