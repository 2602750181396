<template>
  <div v-if="isOpen && league != null">
    <Modal v-model="isOpen"
      containerStyle="height: 800px; max-height: calc(100% - 30px); width: 600px; max-width: calc(100% - 60px); padding: 10px 10px 0 10px; overflow: hidden"
      bodyStyle="margin: 0 -10px; padding: 10px; background: var(--obcolor-background-page);"
      @scrollBody="checkScrollPosition"
    >
      <!-- Player Summary -->
      <div class="ob-box summaryContainer" slot="header">
        <div style="padding: 10px 20px; 10px 30px;">
          <ContestModalSummary v-if="league != null" :league="league" />
        </div>
        <div class="navigationMenu">
          <div class="navBtn" :class="{'navSelected': selectedTab == 'details'}" @click="selectedTab = 'details'">DETAILS</div>
          <div class="navBtn" :class="{'navSelected': selectedTab == 'entrants'}" @click="selectedTab = 'entrants'">
            ENTRANTS
          </div>
          <div style="flex: 1;"></div>
          <div v-if="league.ctype == 'Tournament' && league.state === 'FILLING'">
            <button @click="openInviteModal()" class="ob-btn shareContestLink">
              <i class="fas fa-share-square"></i>
              <div>Share Contest</div>
            </button>
            <div class="referralTooltip" v-tooltip.bottom.notrigger="{ content: 'Copied to clipboard', class: 'ObTooltip ob-tooltip-fadeout', visible: showCopyMessage }"></div>
          </div>
          <div class="ob-btn navBtn" style="color: white;" @click="openReferModal()">
            <i class="fas fa-envelope"></i> Refer
          </div>
        </div>
      </div>
      <div slot="body" style="height: 100%; position: relative;">
        <SponsorImage v-if="contestSponsorBanner && selectedTab == 'details'" :sponsor="contestSponsorBanner" height="77px"
          width="569px" class="sponsorBox"
        />
        <template v-if="selectedTab == 'details'">
          <ContestModalDescription class="ob-box infoContainer" :league="league" />
          <ContestModalOverview ref="details" v-if="isRefVisible('details')" class="ob-box infoContainer"
            :league="league"
          />
          <ContestModalScoring ref="scoring" v-if="isRefVisible('scoring')" class="ob-box infoContainer"
            :league="league"
          />
          <ContestModalPayout ref="payouts" v-if="isRefVisible('payouts')" class="ob-box infoContainer"
            :league="league"
          />
          <div style="height: 1px;"></div>
        </template>
        <ContestModalTournamentPlayers v-else :league="league" />
      </div>

      <!-- Clear the close button at the bottom of the modal -->
      <div slot="footer">
        <div style="font-size: 12px; margin-top: 10px;">
          By entering a lineup in this contest you are agreeing to the OwnersBox
          <router-link class="ob-hyperlink" to="/terms" target="_blank">Terms of Service</router-link>
          and contest rules. Entry into contest is not guaranteed until lineup submission.
        </div>

        <div class="modalFooter">
          <div style="flex: 1; min-width: 0; text-overflow: ellipsis; overflow: hidden;">
            <div class="errorMessage" v-if="geolocationError">
              OwnersBox requires access to your location to join a contest.
            </div>
          </div>

          <button class="modal-default-button ob-btn-grey ob-btn-med" style="margin-right: 10px;" @click="closeModal()">
            Close
          </button>

          <!-- Salary Cap Create Lineup -->
          <template v-if="$route.name !== 'lineup-builder'">
            <button v-if="league.contestStart && $moment().diff($moment(league.contestStart)) > 0" disabled class="modal-default-button ob-btn-grey ob-btn-med">
              Contest Started
            </button>
            <router-link v-else :to="getLineupLink()" class="modal-default-button ob-btn-med">
              Build Lineup
            </router-link>
          </template>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import ContestModalSummary from './ContestModalSummary';
import ContestModalOverview from './ContestModalOverview';
import ContestModalPayout from './ContestModalPayout';
import ContestModalScoring from './ContestModalScoring';
import ContestModalTournamentPlayers from './ContestModalTournamentPlayers';
import ContestModalDescription from './ContestModalDescription';
import SponsorImage from '@/components/sponsors/SponsorImage';
import Modal from '@/components/Modal';

export default {

  components: {
    ContestModalSummary,
    ContestModalOverview,
    ContestModalPayout,
    ContestModalScoring,
    ContestModalTournamentPlayers,
    ContestModalDescription,
    Modal,
    SponsorImage,
  },

  data() {
    return {
      // Used to avoid saving draft priority (via watcher) when setting the default value
      modalInitialized: false,

      league: null,
      isOpen: false,
      refNames: [
        {ref: 'details', visible: false},
        {ref: 'scoring', visible: false},
        {ref: 'payouts', visible: false},
      ],
      refScrolledTo: '',
      selectedTab: 'details',
      sectionTopMargin: 10,

      isEntered: false,

      geolocationError: false,
      geolocationWarning: false,

      copyLinkTimeout: null,
      showCopyMessage: false,
    };
  },

  created() {
    EventBus.$on('UPDATE_UPCOMING_LEAGUES', this.setLeagueData);
    EventBus.$on('OPEN_LEAGUE_MODAL', this.openModal);
    EventBus.$on('MODAL_SCROLL_TO_COMPONENT', this.scrollToComponent);
  },

  destroyed() {
    EventBus.$off('UPDATE_UPCOMING_LEAGUES', this.setLeagueData);
    EventBus.$off('OPEN_LEAGUE_MODAL', this.openModal);
    EventBus.$off('MODAL_SCROLL_TO_COMPONENT', this.scrollToComponent);
  },

  watch: {
    isOpen(newVal, oldVal) {
      if (newVal === false) {
        this.resetData();
      }
    },

    showCopyMessage(newVal) {
      const self = this;
      if (newVal) {
        clearTimeout(this.copyLinkTimeout);
        this.copyLinkTimeout = setTimeout(function() {
          self.resetCopyLinkVar();
        }, 3000);
      }
    },

    '$route.name'(newVal) {
      if (newVal !== 'lobby') {
        this.closeModal();
      }
    },
  },

  computed: {
    contestSponsorBanner() {
      if (!this.league?.sponsorAds) {
        return null;
      }
      return this.league?.sponsorAds['salarycap-contest-card'];
    },
  },

  methods: {
    openReferModal() {
      EventBus.$emit('OPEN_REFER_MODAL');
    },

    selectTab(tabName) {
      this.selectedTab = tabName;
    },

    setLeagueData(leagues) {
      if (leagues == null || this.league == null) {
        return;
      }
      const currLeague = leagues.find((league) => league.id == this.league.id);
      if (currLeague != null) {
        if (this.league.state == 'NOT_STARTED' && currLeague.state != 'NOT_STARTED') {
          this.resetData();
        }
        this.league = currLeague;
      }
    },

    openInviteModal() {
      EventBus.$emit('SHARE_SOCIALS_FOR_ID', this.league.id, true);
    },

    resetCopyLinkVar() {
      this.showCopyMessage = false;
    },

    inLineupCreator() {
      return this.$route.name == 'lineup-builder';
    },

    getLineupLink() {
      return '/lineup-builder/' + this.league.id;
    },

    resetData() {
      this.modalInitialized = false;
      this.league = null;
      this.refNames = [
        {ref: 'details', visible: false},
        {ref: 'scoring', visible: false},
        {ref: 'payouts', visible: false},
      ];
      this.refScrolledTo = '';
      this.selectedTab = 'details';
      this.sectionTopMargin = 10;
      this.joiningLeague = false;
      this.geolocationError = false;
    },

    openModal(league, isEntered) {
      this.resetData();
      this.isOpen = true;
      this.league = league;
      this.isEntered = isEntered;
      this.setScrollVals();

      // Set as nextTick to prevent watcher from triggering
      this.$nextTick(() => {
        this.modalInitialized = true;
      });
    },

    closeModal() {
      this.resetData();
      this.isOpen = false;
    },

    isRefVisible(rName) {
      for (const refIndex in this.refNames) {
        if (this.refNames[refIndex].ref === rName) {
          return this.refNames[refIndex].visible;
        }
      }
      return false;
    },

    setScrollVals() {
      if (this.league) {
        for (const refIndex in this.refNames) {
          const rName = this.refNames[refIndex].ref;
          if (rName == 'scoring' && this.league.scoring == null) {
            this.refNames[refIndex].visible = false;
          } else if (rName == 'payouts' && (this.league.payouts == null ||
            this.league.payouts.length == 0)) {
            this.refNames[refIndex].visible = false;
          } else {
            this.refNames[refIndex].visible = true;
          }
        }
      }
      for (const refIndex in this.refNames) {
        if (this.refNames[refIndex].visible === true && this.refScrolledTo == '') {
          this.refScrolledTo = this.refNames[refIndex].ref;
        }
      }
    },

    scrollToComponent(cName, behavior) {
      if (this.selectedTab != 'details') {
        this.selectedTab = 'details';
      }
      const self = this;
      this.$nextTick(() => {
        if (self.$refs[cName]) {
          EventBus.$emit('MODAL_SCROLL_BODY', self.$refs[cName].$el.offsetTop - self.sectionTopMargin, behavior);
        }
      });
    },

    checkScrollPosition(e) {
      let currentScrolledIndex = this.refNames[0].ref;

      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 10) {
        this.refScrolledTo = this.refNames[this.refNames.length - 1].ref;
        return;
      }

      for (const rIndex in this.refNames) {
        if (this.$refs[this.refNames[rIndex].ref]) {
          const refEl = this.$refs[this.refNames[rIndex].ref].$el;
          if (e.target.scrollTop >= refEl.offsetTop - e.target.offsetTop - this.sectionTopMargin - 10 && this.refNames[rIndex].visible) {
            currentScrolledIndex = this.refNames[rIndex].ref;
          }
        }
      }

      this.refScrolledTo = currentScrolledIndex;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .componentTitle {
    font-size: 12px;
    color: var(--obcolor-font-secondary);
    padding: 8px 15px;
    background: var(--obcolor-background-4);
    width: 100%;
    font-weight: bold;
    box-sizing: border-box;
  }

  .componentContent {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 5px 10px;
    box-sizing: border-box;
    min-width: 380px;
  }

  .infoBtn {
    font-size: 12px;
    color: var(--obcolor-font-primary);
    margin-left: 5px;
  }

  .obText {
    color: var(--obcolor-ob-blue);
  }

  .moneyText {
    color: var(--obcolor-green);
  }
}

.summaryContainer {
  margin: -20px -20px 0 -20px;
  border-radius: 5px 5px 0 0;
  background: var(--obcolor-box-header);
}

.navigationMenu {
  display: flex;
  background: var(--obcolor-navi-bar);
  margin-bottom: -8px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

.navBtn {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.navBtn.navSelected {
  background: var(--obcolor-background-2);
  color: var(--obcolor-ob-blue)
}

.sponsorBox {
  margin-bottom: 6px;
}

.infoContainer {
  padding: 0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  min-width: min-content;
}

.shareContestLink {
  padding: 5px 10px;
  margin: 5px;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;

  .fa-share-square {
    margin-right: 6px;
  }
}

.modalFooter {
  padding: 5px 0 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.errorMessage {
  color: red;
  font-size: 12px;
}
</style>