/* eslint-disable no-extend-native */
import {Strings, Numbers} from '@ownersbox/common/utils';

String.prototype.longName = function() {
  return Strings.longName(this);
};

String.prototype.shortName = function() {
  return Strings.shortName(this);
};

String.prototype.lastName = function() {
  return Strings.lastName(this);
};

String.prototype.removeCentsIfZero = function() {
  return Strings.removeCentsIfZero(this);
};

Number.prototype.formatMoney = function() {
  return Numbers.formatMoney(this);
};

Number.prototype.formatDecimal = function() {
  return Numbers.formatDecimal(this);
};

Number.prototype.formatMoneyDecimals = function() {
  return Numbers.formatMoneyDecimals(this);
};

Number.prototype.addSuffix = function() {
  return Numbers.addSuffix(this);
};

Number.prototype.centsToDollars = function() {
  return Numbers.centsToDollars(this);
};

String.prototype.addSuffix = function() {
  const i = parseInt(this);
  return Numbers.addSuffix(i);
};

String.prototype.removePluralWords = function() {
  return Strings.removePluralWords(this);
};

// Changes a string such as '100000' to '100,000'
String.prototype.commaSeparatedFormat = function() {
  const i = parseInt(this);
  return Numbers.commaSeparatedFormat(i);
};

Number.prototype.dollarWithCentsFormat = function() {
  return Numbers.dollarWithCentsFormat(this);
};

// Changes a number such as 100000 to 100K
// No formatting for any numbers under 100K
Number.prototype.abbreviateLongNumber = function() {
  return Numbers.abbreviateLongNumber(this);
};