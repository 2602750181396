<template>
  <div>
    <div class="pointsContainer" :class="{ inactiveGame: getGameState().active != true, overSalary: gameOverSalaryCap() }"
      v-tooltip.bottom="{visible: getGameState().tooltip != null, content: getGameState().tooltip, class:'ObTooltip'}"
    >
      <div v-if="!hasFpts()" :class="getPointsClass()" class="obText">0</div>
      <div v-else class="obText" :class="getPointsClass()">{{ formatPts(getPlayerFpts()) }}</div>
      <div v-if="getGameState().label != null" class="projText">{{ getGameState().label }}</div>
      <div v-else class="projText">Proj {{ getPlayerProj() }}</div>
      <i v-if="gameOverSalaryCap()" class="fas fa-usd-circle moneyCircle"></i>
    </div>
    <!-- Double Header Points -->
    <div class="pointsContainer" v-if="hasDoubleHeader()" :class="{ inactiveGame: getGameState(true).active != true, overSalary: gameOverSalaryCap(true) }"
      style="margin-top: 32px; margin-bottom: -3px;"
      v-tooltip.bottom="{visible: getGameState(true).tooltip != null, content: getGameState(true).tooltip, class:'ObTooltip'}"
    >
      <div v-if="!hasFpts(true)" :class="getPointsClass(true)" class="obText">0</div>
      <div v-else class="obText" :class="getPointsClass(true)">{{ formatPts(getPlayerFpts(true)) }}</div>
      <div v-if="getGameState(true).label != null" class="projText">{{ getGameState(true).label }}</div>
      <div v-else class="projText">Proj {{ getPlayerProj(true) }}</div>
      <i v-if="gameOverSalaryCap()" class="fas fa-usd-circle moneyCircle"></i>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    entry: Object,
    gameIndex: Number,
    todaysGames: Object,
    league: Object,
    rosterCalc: Object,
  },

  methods: {
    formatPts(pts) {
      return pts > 0 ? '+' + pts : pts;
    },

    gameOverSalaryCap(isDH) {
      const game = this.getFspGame(isDH);
      if (game == null || this.entry == null ||
        !this.rosterCalc || !this.rosterCalc.overSalaryGames
      ) {
        return false;
      }

      const gameKey = this.entry.id + '_' + game.id;
      return this.rosterCalc.overSalaryGames[gameKey];
    },

    gameZeroSalary(isDH) {
      const state = this.getGameState(isDH);
      return !state.active && state.state != 'NoSalary';
    },

    showDNP(isDH) {
      const game = this.getFspGame(isDH);
      return this.gameHourAfterStart(isDH) && !game.played;
    },

    gameHourAfterStart(isDH) {
      const game = this.getFspGame(isDH);
      const now = new Date().getTime();
      const gTime = parseInt(game.timestamp);
      return now > (gTime + (3600000 * 6));
    },

    getGameState(isDH) {
      const player = this.entry;
      const game = this.getFspGame(isDH);

      if (!game || !player || !this.league) {
        return {state: 'NoGame', label: null, tooltip: null, active: false};
      }

      if (!this.league.contestStart || this.league.contestStart > game.timestamp) {
        // ContestNotStarted - Contest start ts greater than game ts
        return {state: 'ContestNotStarted', label: null, tooltip: 'Game not included in contest slate', active: false};
      } else if (!game.inRange) {
        // NOR - Got put on your team mid-game
        return {state: 'NOR', label: 'NOR', tooltip: 'Was not on your team at game start', active: false};
      } else if (this.showDNP(isDH)) {
        // DNP - Not a starter in the game (game finished)
        return {state: 'DNP', label: 'DNP', tooltip: 'Did not play', active: false};
      } else if (game.partial) {
        // DNS - Not a starter in the game (game running)
        return {state: 'DNS', label: 'DNS', tooltip: 'Did not start game', active: false};
      } else if (player.deactGames[game.id] != null) {
        // Benched - was benched when game started
        return {state: 'Benched', label: 'Benched', tooltip: null, active: false};
      } else if (this.gameOverSalaryCap(isDH)) {
        // NoSalary - Game was not allocated salary
        return {state: 'NoSalary', label: null, tooltip: 'Not enough salary', active: false};
      } else if (game.ProjPoints && game.ProjPoints.total == 0) {
        // OffDay - Not a starter in the game (and other conditions not met)
        return {state: 'OffDay', label: null, tooltip: null, active: false};
      }

      return {state: 'Active', label: null, tooltip: null, active: true};
    },

    hasFpts(dh) {
      const game = this.getFspGame(dh);
      return game != null && game.ObPoints != null && game.ObPoints.total != null;
    },

    gameIsPartial(dh) {
      const game = this.getFspGame(dh);
      return game != null ? game.partial : false;
    },

    getPointsClass(dh) {
      if (this.getPlayerFpts(dh) < 0) {
        return 'redPoints';
      }
      if (!this.getGameState(dh).active && this.getGameState(dh).state != 'NoSalary') {
        return 'greyPoints';
      }
      if (!this.hasFpts(dh)) {
        return 'whitePoints';
      }
      return '';
    },

    getPlayerFpts(dh) {
      const game = this.getFspGame(dh);
      if (game != null && game.ObPoints != null && game.ObPoints.total != null) {
        return game.ObPoints.total;
      }
      return 0;
    },

    getPlayerProj(dh) {
      const game = this.getFspGame(dh);
      if (game != null && game.ProjPoints != null && game.ProjPoints.total != null) {
        return game.ProjPoints.total;
      }
      return 0;
    },

    hasDoubleHeader() {
      if (this.entry.fspGames == null) {
        return false;
      }
      return this.entry.fspGames[this.gameIndex + 'DH'];
    },

    getFspGame(dh) {
      if (this.league.format == 'DAILY') {
        return this._getFspGameDaily(dh);
      }
      if (this.entry.fspGames == null) {
        return null;
      }
      if (dh === true) {
        return this.entry.fspGames[this.gameIndex + 'DH'];
      }
      return this.entry.fspGames[this.gameIndex];
    },

    todaysGame(dh) {
      if (this.league.format == 'DAILY') {
        return this._todaysGameDaily(dh);
      }
      const gameJSON = this.getFspGame(dh);
      if (!gameJSON) {
        return null;
      }
      return this.todaysGames[gameJSON.id];
    },

    // Daily Version of above, not to be used directly
    _getFspGameDaily(dh) {
      if (dh === true) {
        return null;
      }
      let firstGame = null;
      for (const gameIndex in this.entry.fspGames) {
        const game = this.entry.fspGames[gameIndex];
        if (game.id && game.inRange) {
          firstGame = game;
          break;
        }
      }
      return firstGame;
    },

    // Daily Version of above, not to be used directly
    _todaysGameDaily(dh) {
      if (dh === true) {
        return null;
      }
      const gameJSON = this._getFspGameDaily();
      if (!gameJSON) {
        return null;
      }
      return this.todaysGames[gameJSON.id];
    },
  },

};
</script>

<style lang="scss" scoped>
.overSalary {
  .obText, .projText {
    color: red !important;
  }

  .moneyCircle {
    color: red;
    margin-left: 4px;
    margin-top: 3px;
  }
}


.obText {
  color: var(--obcolor-ob-blue);
  font-weight: bold;
  font-size: 14px;

  margin: 3px 0;

  &.whitePoints {
    color: var(--obcolor-font-primary);
  }

  &.greyPoints {
    color: var(--obcolor-font-secondary);
  }

  &.redPoints {
    color: red;
  }
}

.projText {
  color: var(--obcolor-font-secondary);
  font-size: 12px;
  margin: 3px 0;
}

.inactiveGame {
  opacity: 0.5;
}
</style>
