<template>
  <div class="ob-page">
    <SalaryCapContestHeader v-if="contest"
      :contest="contest" :myTeams="myTeams"
      :editingTeam="null" :showLive="true" :selectedTeamId="teamId"
      :overLimits="overLimits" :underLimits="underLimits"
    />
    <SalaryLeaderboardTab v-if="tab === 'leaderboard' && contest"
      :myTeams="myTeams" :leagueData="contest" :leagueRoster="roster"
      :customNames="customNames" :rosterCalc="rosterCalculations"
      :salaryWarnings="salaryWarnings" :overLimits="overLimits" :underLimits="underLimits"
      :teamId="teamId" :dataLoaded="dataLoaded"
      :oppTeamId="oppTeamId" :oppLeagueRoster="oppRoster"
      :oppRosterCalc="oppRosterCalculations"
      :oppDataLoaded="oppDataLoaded"
    />
    <SalaryTeamSchedule v-if="tab === 'lineup'" :leagueData="contest"
      :leagueRoster="roster" :customNames="customNames" :myTeams="myTeams"
      :rosterCalc="rosterCalculations"
      :teamId="teamId"
    />
    <ObLoading v-if="!contest && tab !== 'swap'" />
    <EditLineupCSVModal />
    <MultipleLineupEditedModal />
  </div>
</template>

<script>
import ObSalaryCapApi from '@/api/ObSalaryCapApi';
import SalaryLeaderboardTab from './Leaderboard/SalaryLeaderboardTab';
import SalaryTeamSchedule from './TeamSchedule/SalaryTeamSchedule';
import ObLoading from '@/components/ObLoading';
import {mapState} from 'vuex';
import EventBus from '@/event-bus';
import SalaryCapContestHeader from '@/views/SalaryCapGame/SalaryCapContestHeader.vue';
import EditLineupCSVModal from '@/views/SalaryCapGame/EditLineupCSVModal/EditLineupCSVModal';
import MultipleLineupEditedModal from '@/views/SalaryCapGame/ConfirmModal/MultipleLineupEditedModal';

export default {

  components: {
    SalaryLeaderboardTab,
    SalaryTeamSchedule,
    ObLoading,
    SalaryCapContestHeader,
    EditLineupCSVModal,
    MultipleLineupEditedModal,
  },

  computed: {
    ...mapState(['userId', 'todaysGames']),

    myTeams() {
      if (!this.contest || !this.contest.leaderboard) {
        return [];
      }
      return this.contest.leaderboard.filter((entry) => entry.userId === this.userId);
    },
  },

  data() {
    return {
      tab: 'leaderboard',
      leagueId: null,
      teamId: null,
      oppTeamId: null,
      contest: null,
      roster: null,
      oppRoster: null,
      customNames: {},
      salaryWarnings: {},
      overLimits: {},
      underLimits: {},
      refreshInterval: null,
      dataLoaded: false,
      oppDataLoaded: false,

      // Calculated Values for currently selected roster
      // Wrapped in a JSON to calculate once and share with different components
      rosterCalculations: {
        gameLimits: {},
        projGames: {},
        playedGames: {},
        salaryByPos: {},
        gpByPos: {},
        gpWithBB: {},
        overSalaryGames: {},
        bestBallGames: {},
        salarySpent: 0,
        salaryCommitted: 0,
        salaryRemaining: 0,
      },

      oppRosterCalculations: {
        gameLimits: {},
        projGames: {},
        playedGames: {},
        salaryByPos: {},
        gpByPos: {},
        gpWithBB: {},
        overSalaryGames: {},
        bestBallGames: {},
        salarySpent: 0,
        salaryCommitted: 0,
        salaryRemaining: 0,
      },
    };
  },

  created() {
    this.setupInitTab();
    this.loadTeam();
    EventBus.$on('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$on('PLAYER_DEACTIVATED', this.setupPlayedGames);
    EventBus.$on('SALARY_CAP_SELECT_TAB', this.selectTab);
    EventBus.$on('SALARY_CAP_RELOAD', this.reloadTeam);
    EventBus.$on('SELECT_DAILY_LEADERBOARD_TEAM', this.selectDailyLeaderboardTeam);
    EventBus.$on('UNSELECT_NFL_LEADERBOARD_OPPONENT', this.resetOppTeam);
    this.startRefreshInterval();
  },

  destroyed() {
    this.$SocketController.unsubscribeFromRoom('DASHBOARD_' + this.userId);
    this.$SocketController.unsubscribeFromRoom('SALARYCONTEST_' + this.leagueId);
    this.$SocketController.unsubscribeFromRoom('FSP_SC_UPDATE_' + this.leagueId);
    EventBus.$off('SOCKET_BROADCAST', this.onSocketBroadcast);
    EventBus.$off('PLAYER_DEACTIVATED', this.setupPlayedGames);
    EventBus.$off('SALARY_CAP_SELECT_TAB', this.selectTab);
    EventBus.$off('SALARY_CAP_RELOAD', this.reloadTeam);
    EventBus.$off('SELECT_DAILY_LEADERBOARD_TEAM', this.selectDailyLeaderboardTeam);
    EventBus.$off('UNSELECT_NFL_LEADERBOARD_OPPONENT', this.resetOppTeam);
    this.stopRefreshInterval();
  },

  watch: {
    '$route.params.contestId'(to, from) {
      this.loadTeam();
    },

    '$route.params.teamId'(to, from) {
      if (to != this.teamId) {
        this.loadTeam();
      }
    },

    oppTeamId(to, from) {
      if (to != null) {
        this.loadTeam(true, true);
      } else {
        this.oppRoster = null;
        this.oppDataLoaded = false;
      }
    },

    '$route.params.view'(to, from) {
      this.tab = to;
    },
  },

  methods: {
    onSocketBroadcast(data) {
      if (data == null || !this.contest) {
        return;
      }

      if (data.type === 'FSP_DASHBOARD' || data.type === 'FSP_SC_UPDATE') {
        this.socketUpdateContest(data);
      }

      if (data.type === 'FSP_SC_REFRESH' || data.type === 'FSP_SC_UPDATE') {
        this.reloadTeam(false);
        if (this.oppTeamId != null) {
          this.reloadTeam(true);
        }
      }
    },

    socketUpdateContest(data) {
      const json = data.json;

      if (json.id === this.contest.id) {
        // Merge in teamRanks, if needed
        if (!json.teamRanks && this.contest.teamRanks) {
          json.teamRanks = this.contest.teamRanks;
        }

        // Merge in myEntries, if needed
        if (!json.myEntries && this.contest.myEntries) {
          json.myEntries = this.contest.myEntries;
        }

        // Merge existing leaderboard data, it is no longer included in socket updates
        // This data gets updated with a REST call
        json.leaderboard = this.contest.leaderboard;
        this.contest = json;

        // Reload leaderboard pages
        EventBus.$emit('SC_RELOAD_LEADERBOARD_PAGES');
      }
    },

    isPreContest() {
      if (!this.contest) {
        return false;
      }
      if (this.contest.contestStart < new Date().getTime()) {
        return false;
      }
      return this.contest.state === 'FILLING' || this.contest.state === 'FILLED';
    },

    // Refresh Interval
    startRefreshInterval() {
      const self = this;
      this.refreshInterval = setInterval(() => {
        self.refreshData();
      }, 60000);
    },

    stopRefreshInterval() {
      clearInterval(this.refreshInterval);
    },

    // Refresh data every x seconds, only if there are live games for this roster
    refreshData() {
      if (!this.contest || !this.roster) {
        return;
      }
      if (this.tab !== 'leaderboard' && this.tab !== 'lineup') {
        return;
      }
      if (this.anyGamesLive(false)) {
        this.reloadTeam(false);
      }
      if (this.oppTeamId != null && this.anyGamesLive(true)) {
        this.reloadTeam(true);
      }
    },

    anyGamesLive(isOppTeam = false) {
      const roster = isOppTeam ? this.oppRoster : this.roster;

      if (!roster || !roster.players) {
        return false;
      }
      // Determine if any players on the roster have a live game
      // This is used to determine if we should refresh the data
      for (const player of roster.players) {
        // Check all games
        for (const gameIndex in player.fspGames) {
          const gid = player.fspGames[gameIndex].id;
          const gameJSON = this.todaysGames[gid];
          if (gameJSON && gameJSON.live && gameJSON.live.status && gameJSON.live.status === 'mid-event') {
            return true;
          }
        }
      }
      return false;
    },

    setupInitTab() {
      this.tab = this.$route.params.view;
    },

    selectTab(name, entryId = null) {
      if (name != 'leaderboard') {
        this.resetOppTeam();
      }

      let routerString = '/salarycontest/' + name + '/' + this.leagueId + '/' + this.teamId;
      if (entryId != null) {
        routerString = routerString + '/' + entryId;
      }
      this.$router.push(routerString);
    },

    changeTeam(teamId) {
      this.tab = 'lineup';
      this.$router.push('/salarycontest/lineup/' + this.leagueId + '/' + teamId);
    },

    selectDailyLeaderboardTeam(team) {
      if (this.teamId == team.teamId) {
        return;
      }

      if (team.userId == this.userId) {
        this.$router.push('/salarycontest/leaderboard/' + this.leagueId + '/' + team.teamId);
      } else if (this.oppTeamId != team.teamId) {
        this.oppTeamId = team.teamId;
      } else {
        this.oppTeamId = null;
      }
    },

    resetOppTeam() {
      this.oppTeamId = null;
    },

    reloadTeam(isOppTeam = false) {
      this.loadTeam(false, isOppTeam);
    },

    loadTeam(rosterChanged = true, isOppTeam = false) {
      const contestChanged = this.leagueId !== this.$route.params.contestId;
      this.leagueId = this.$route.params.contestId;
      this.teamId = this.$route.params.teamId;

      // When refreshing for live stats, don't clear roster, don't show loading dial
      if (rosterChanged && isOppTeam) {
        this.oppRoster = null;
        this.oppDataLoaded = false;
      }
      if (rosterChanged && !isOppTeam) {
        this.roster = null;
      }

      // Only clear the contest data if contest has changed
      if (contestChanged) {
        this.contest = null;
      }

      if (!isOppTeam) {
        ObSalaryCapApi.getTeam2(this.leagueId, this.teamId)
            .then((response) => {
              this.contest = response.contest;
              this.roster = response.roster;
              this.customNames = response.customNames;
              this.salaryWarnings = response.salaryWarnings;
              this.overLimits = response.overLimits;
              this.underLimits = response.underLimits;
              this.setupPlayedGames();
              this.$SocketController.subscribeToRoom('DASHBOARD_' + this.userId);
              this.$SocketController.subscribeToRoom('SALARYCONTEST_' + this.leagueId);
              this.$SocketController.subscribeToRoom('FSP_SC_UPDATE_' + this.leagueId);
              this.dataLoaded = true;

              if (this.roster) {
                const tid = this.roster.id + '_' + this.roster.teamNum;
                if (this.teamId != tid) {
                  this.teamId = tid;
                  this.$router.replace({params: {teamId: tid}});
                }
              }
            });
      } else {
        ObSalaryCapApi.getTeam2(this.leagueId, this.oppTeamId)
            .then((response) => {
              this.contest = response.contest;
              this.oppRoster = response.roster;
              this.setupPlayedGames(true);
              this.oppDataLoaded = true;
            });
      }
    },

    // --- Roster Calculations needed for both Lineup Manager, and SalaryInfo Box ---

    setupPlayedGames(isOppTeam = false) {
      // First determine if any games do not count due to going over the salary cap
      // Includes future games with > 0 projection
      const rosterCalculations = {
        gameLimits: {},
        projGames: {},
        playedGames: {},
        salaryByPos: {},
        gpByPos: {},
        gpWithBB: {},
        overSalaryGames: {},
        bestBallGames: {},
        salarySpent: 0,
        salaryCommitted: 0,
        salaryRemaining: 0,
      };

      rosterCalculations.gameLimits = this.contest.gameLimits;
      const allGames = [];

      // Determine what games count towards your score
      // Factoring in game limits as best-ball
      const gamesByPosition = {};

      const roster = isOppTeam ? this.oppRoster : this.roster;

      if (roster) {
        for (const pIndex in roster.players) {
          const player = roster.players[pIndex];
          this.addPlayerGames(player, gamesByPosition, allGames);
        }

        for (const pIndex in roster.dropped) {
          const player = roster.dropped[pIndex];
          this.addPlayerGames(player, gamesByPosition, allGames);
        }
      }

      // Sort all played games by the timestamp to determine if any don't count due to salary
      let salaryRem = this.contest.salaryCap;
      salaryRem -= roster ? roster.swapFees : 0;
      let spent = 0;

      // Calculate actual spent + commited salary
      let salarySpent = 0;
      let salaryCommitted = 0;

      allGames.sort(function(a, b) {
        if (a.ts > b.ts) {
          return 1;
        } else if (b.ts > a.ts) {
          return -1;
        }
        return 0;
      });

      const salaryRestrictedGames = {};
      const salaryByPos = {};

      for (const game of allGames) {
        if (salaryRem - spent - game.salary < 0) {
          salaryRestrictedGames[game.key] = true;
        } else {
          spent += game.salary;
        }

        // Display Values
        if (game.played) {
          salarySpent += game.salary;
        } else {
          salaryCommitted += game.salary;
        }

        // Spent + Committed by position, to display in position boxes
        if (salaryByPos[game.pos]) {
          salaryByPos[game.pos] += game.salary;
        } else {
          salaryByPos[game.pos] = game.salary;
        }
      }

      rosterCalculations.overSalaryGames = salaryRestrictedGames;
      rosterCalculations.salaryCommitted = salaryCommitted;
      rosterCalculations.salarySpent = salarySpent;
      rosterCalculations.salaryRemaining = salaryRem - salarySpent - salaryCommitted;
      rosterCalculations.salaryByPos = salaryByPos;

      for (const pos in gamesByPosition) {
        const gp = gamesByPosition[pos];
        rosterCalculations.gpWithBB[pos] = Object.keys(gp).length;
      }

      // Filter out over salary cap games before applying best ball logic
      // Sort each position games list by fpts (apply best ball)
      const bestBallGames = this.sortAndLimitGames(gamesByPosition, rosterCalculations.gameLimits, salaryRestrictedGames);
      rosterCalculations.bestBallGames = bestBallGames;

      // Played games count by position
      const gpByPos = {};

      // Setup final list of counted games
      const playedGames = {};
      for (const pos in gamesByPosition) {
        const validGames = gamesByPosition[pos];
        gpByPos[pos] = Object.keys(validGames).length;
        for (const entry of validGames) {
          playedGames[entry.key] = true;
        }
      }

      rosterCalculations.playedGames = playedGames;
      rosterCalculations.gpByPos = gpByPos;

      // Current game played projection by draft positiom
      const projectedGames = {};
      for (const key in this.contest.gameLimits) {
        if (key !== 'total') {
          // Must pass in salaryRestrictedGames since rosterCalcs is not set until after this process completes
          projectedGames[key] = this.calculateGamesForPosition(key, salaryRestrictedGames, isOppTeam);
        }
      }
      rosterCalculations.projGames = projectedGames;

      if (isOppTeam) {
        this.oppRosterCalculations = rosterCalculations;
      } else {
        this.rosterCalculations = rosterCalculations;
      }
    },

    calculateGamesForPosition(pos, salaryRestrictedGames, isOppTeam = false) {
      const roster = isOppTeam ? this.oppRoster : this.roster;
      if (!roster) {
        return 0;
      }

      let gamesPlayedAndProjected = 0;
      const now = new Date().getTime();

      // Active Roster
      for (const pIndex in roster.players) {
        const player = roster.players[pIndex];
        if (player.draftPos === pos) {
          gamesPlayedAndProjected += this.getGpForPlayer(player, now, salaryRestrictedGames);
        }
      }

      // Dropped Players
      for (const pIndex in roster.dropped) {
        const player = roster.dropped[pIndex];
        if (player.draftPos === pos) {
          gamesPlayedAndProjected += this.getGpForPlayer(player, now, salaryRestrictedGames);
        }
      }

      return gamesPlayedAndProjected;
    },

    getGpForPlayer(player, now, salaryRestrictedGames) {
      let gp = 0;
      const games = player.fspGames;
      const deactGames = player.deactGames;
      const twoHoursAgo = now - 7200000;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const proj = gJSON.ProjPoints ? gJSON.ProjPoints.total : 0;
        const isActive = !deactGames[gJSON.id];
        if (!isActive) {
          continue;
        }
        const gameKey = player.id + '_' + gJSON.id;
        if (salaryRestrictedGames[gameKey]) {
          continue;
        }

        const gameTime = parseInt(gJSON.timestamp);
        if (gJSON.inRange && (gJSON.played === true || (gameTime > twoHoursAgo && proj > 0)) && !gJSON.partial) {
          gp++;
        }
      }
      return gp;
    },

    addPlayerGames(player, gamesByPosition, allGames) {
      const pos = player.draftPos;
      const posGamesPlayed = gamesByPosition[pos] || [];

      const games = player.fspGames;
      const deactGames = player.deactGames;
      const now = new Date().getTime();
      const twoHoursAgo = now - 7200000;

      for (const gameIndex in games) {
        const gJSON = games[gameIndex];
        const gameKey = player.id + '_' + gJSON.id;
        const proj = gJSON.ProjPoints ? gJSON.ProjPoints.total : 0;
        const gameTime = parseInt(gJSON.timestamp);
        const isActive = !deactGames[gJSON.id];
        const hasResult = gJSON.result && gJSON.result !== '';

        if (!isActive) {
          continue;
        }
        // Game is played, player has fpts
        if (gJSON.inRange && gJSON.played === true && gJSON.ObPoints && !gJSON.partial) {
          posGamesPlayed.push({key: gameKey, fpts: gJSON.ObPoints.total});
          allGames.push({key: gameKey, pos: pos, ts: gameTime, salary: player.salaryPG, played: true});
        } else if (gJSON.inRange && gameTime > now && proj > 0) {
          // Game is in the future and player has a projection
          allGames.push({key: gameKey, pos: pos, ts: gameTime, salary: player.salaryPG, played: false});
        } else if (gJSON.inRange && gameTime > twoHoursAgo && proj > 0 && !hasResult) {
          // Game has started (less than 2 hours ago) and player has a projection
          // Player has no fpts, but we assume he will play and include this game in the salary projection
          // If there is a game result, this indicates the game is over at which point we do not include this game
          allGames.push({key: gameKey, pos: pos, ts: gameTime, salary: player.salaryPG, played: false});
        }
      }

      gamesByPosition[pos] = posGamesPlayed;
    },

    sortAndLimitGames(games, gameLimit, overSalaryGames) {
      // Track the best ball filtered games
      const bestBallGames = {};

      for (const pos in games) {
        let gamesByPos = games[pos];
        let limit = gameLimit[pos];

        if (limit < 0) {
          limit = 0;
        }

        gamesByPos = gamesByPos.filter((g) => {
          return !overSalaryGames[g.key];
        });

        gamesByPos.sort(function(a, b) {
          // Sort by fpts
          if (a.fpts > b.fpts) {
            return -1;
          } else if (b.fpts > a.fpts) {
            return 1;
          }
          return 0;
        });

        // Best Ball filtered games
        if (gamesByPos.length > limit) {
          for (let i=limit; i < gamesByPos.length; i++) {
            bestBallGames[gamesByPos[i].key] = true;
          }
        }

        gamesByPos = gamesByPos.slice(0, limit);
        games[pos] = gamesByPos;
      }

      return bestBallGames;
    },

  },
};
</script>

<style lang="scss" scoped>

.ob-page {
  min-width: max-content;
  height: calc(100% - 130px);
}

</style>